<template>
  <VCoordinatesModal :title="title" :is-visible="isVisible" @success="handleSuccess" @close="handleClose"
    :initValues="initCoordinates">
    <template #bodyStart>
      <VSelect v-model="threatUnitCode"
               :options="options"
               :search-option="true"
               class="mb"
               placeholder="Select threat unit" />
    </template>
  </VCoordinatesModal>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex'
import VCoordinatesModal from '@/components/modals/components/VCoordinatesModal';
import VSelect from '@/components/ui/VSelect.vue';

export default defineComponent({
  components: { VCoordinatesModal, VSelect },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    initValues: {
      type: Object,
      default() {
        return null;
      },
    },
    title: {
      type: String,
      default: '',
    },
    close: {
      type: Function,
      default: null,
    },
    success: {
      type: Function,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    initValues(newVal) {
      if (newVal) {
        this.threatUnitCode = newVal?.unitCode
      }
    }
  },
  computed: {
    ...mapState({
      options: state => {
        return  state.threat.threatUnits
        .reduce((acc, item) => {
          if(!item.ID) return acc
          return [...acc, { value: item.code, label: item.name }]
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label))
      }
    }),
    initCoordinates() {
      return this.initValues?.coordinates
    },
    initThreatID() {
      return this.initValues?.ID
    },
  },
  methods: {
    handleSuccess(coordinates) {
      this.$emit('success', { 
        ...(this.initThreatID ? { ID: this.initThreatID } : {}), 
        threatUnitCode: this.threatUnitCode, 
        coordinates 
      })
      this.threatUnitCode = null
    },
    handleClose() {
      this.$emit('close')
      this.threatUnitCode = null
    }
  },
  data() {
    return {
      threatUnitCode: null,
    }
  }
})
</script>
<style scoped>
.mb {
  margin-bottom: 12px;
}
</style>