<script setup>
import VMenuItem from '@/components/layout/components/VMenuItem';
import {
  NewIcon,
  FolderIcon,
  SaveIcon,
  ImportIcon,
  ExportIcon,
  PenIcon,
  ThreatIcon,
  LocationIcon,
  PlainUpIcon,
  PlainDownIcon,
  ObjectsIcon,
  UnionIcon,
  LocationPinIcon,
  RouteIcon,
  ReloadIcon,
  LogoutIcon,
  SetLineIcon,
  LogoutRed,
} from '@/assets/svgs';
import useGlobalEvents from '@/hooks/useGlobalEvents';
import useMapEvents from '@/hooks/useMapEvents';
import { computed } from 'vue';
const {
  isCurrentGeoLocationSetting,
  onRevertSettingCurrentGeoLocation,
  onSaveCurrentGeoLocation,
  onSetCurrentGeoLocationByCoords,
  onSetThreatByCoords,
  isThreatByCoord,
  onSaveAsMangerClick,
  onSaveManagerClick,
  onSaveClose,

  onSaveClick,
  onOpenFileManagerClick,
  onOpenExistingClick,
  onImportClick,
  onImportThreatsClick,
  onExportClick,
  isExportEmpty,
  onCreateNewClick,
  onSaveAsClick,
  onLogoutClick,
  onImportFilesUploaded,
  importProps,
  onMissionImportClick,
  importAllMissionFromFile,
  isSaveVisible,
  isSaveAs,
  isLogoutDisabled,
  isThreatModule,
  onSetTargetPointByCoordinates
} = useGlobalEvents({ useOptimizeMode: false });
const {
  drawPolygon,
  isDrawing,
  drawLine,
  drawThreat,
  drawDangerSector,
  drawPolygonByLines,
  drawPoint,
  drawStartFinish,
  getCalculations,
  showCurrentGeoLocation,
  setVisibleCoordsModal,
  isCoordsModalVisible,
  drawCustomPointByCoords,
  hasFlightPath,
  drawTargetPoint
} = useMapEvents();

const menuHierarchy = computed(() => [
  {
    title: 'Start new mission',
    icon: NewIcon,
    click: onCreateNewClick,
  },
  {
    title: 'Open',
    icon: FolderIcon,
    children: [
      {
        title: 'Open mission manager',
        click: onOpenFileManagerClick,
        children: [],
      },
      // {
      //   title: 'Open existing mission',
      //   click: onOpenExistingClick,
      //   children: [],
      // },
      {
        title: 'Open existing mission from file',
        click: onMissionImportClick,
        children: [],
      },
    ],
  },
  {
    title: 'Save',
    isSectionBreak: true,
    icon: SaveIcon,
    children: [
      {
        title: 'Save current mission',
        click: onSaveManagerClick,
      },
      {
        title: 'Save mission as...',
        click: onSaveAsMangerClick,
      },
      {
        title: 'Save mission to file',
        click: onSaveAsClick,
      },
    ],
  },
  {
    icon: ImportIcon,
    title: 'Import from KML/KMZ',
    children: [
      {
        title: 'Import to KML',
        click: onImportClick,
      },
      ...(isThreatModule.value ? [{
          title: 'Import to Threat',
          click: onImportThreatsClick,
        }] : []),
    ]
  },
  {
    icon: ExportIcon,
    title: 'Export to KMZ',
    click: onExportClick,
    disabled: isExportEmpty.value,
    isSectionBreak: true,
  },
  {
    icon: PenIcon,
    title: 'Export mission',
    isSectionBreak: true,
    children: [
      {
        title: 'Set point by coordinates',
        click: () => setVisibleCoordsModal(true),
      },
      {
        title: 'Set point on map',
        click: () => drawPoint(false),
      },
      {
        title: 'Set area',
        click: () => drawPolygon(false),
      },
      {
        title: 'Set line',
        click: () => drawLine(false),
      },
    ],
  },
  {
    icon: LocationIcon,
    title: 'Set the location',
    children: [
      {
        title: 'Set location by geolocation',
        click: showCurrentGeoLocation,
      },
      {
        title: 'Set location by coordinates',
        click: onSetCurrentGeoLocationByCoords,
      },
    ],
  },
  {
    icon: PlainUpIcon,
    title: 'Set takeoff point',
    click: () => drawStartFinish(true),
  },
  {
    icon: PlainDownIcon,
    title: 'Set landing point',
    click: () => drawStartFinish(false),
  },
  {
    icon: ObjectsIcon,
    title: 'Set mission area',
    click: drawPolygonByLines,
  },
  {
    icon: SetLineIcon,
    title: 'Set mission path',
    click: drawLine,
  },
  {
    icon: LocationPinIcon,
    title: 'PinLocation',
    children: [
      {
        title: 'Add target point by coordinates',
        click: onSetTargetPointByCoordinates,
      },
      {
        title: 'Add target point',
        click: drawTargetPoint,
      },
    ],
    isSectionBreak: true,
  },
  {
    icon: ThreatIcon,
    title: 'Threat',
    isSectionBreak: true,
    hidden: !isThreatModule.value,
    children: [
      {
        title: 'Set location on map',
        click: drawThreat,
      },
      {
        title: 'Set location by coordinates',
        click: () => onSetThreatByCoords(true),
      },
      {
        title: 'Set danger sector',
        click: drawDangerSector,
      },
      {
        title: 'Set danger area',
        click: () => null,
      },
    ],
  },
  {
    icon: RouteIcon,
    title: 'Build optimised flight path',
    click: () => getCalculations(),
  },
  {
    icon: ReloadIcon,
    title: 'Refresh flight path',
    disabled: !hasFlightPath.value,
    click: () => getCalculations(true),
  },
]);

const logoutItemValue = computed(() => ({
  icon: !isLogoutDisabled.value ? LogoutIcon : LogoutRed,
  title: 'Generate waypoints',
  click: () => !isLogoutDisabled.value && onLogoutClick(),
  isDisabled: isLogoutDisabled,
}));
</script>
<template>
  <el-menu class="menu" menu-trigger="click" :collapse="true">
    <VMenuItem v-for="item in menuHierarchy" :key="item?.title" :data="item" />
    <VMenuItem :key="isLogoutDisabled" :class="{ 'waypoints-disabled': isLogoutDisabled }" :data="logoutItemValue" />
  </el-menu>
</template>

<style lang="scss" src="@/styles/components/layout/menu.scss" scoped></style>