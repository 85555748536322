<script setup>
import { AccordionArrow } from '@/assets/svgs';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import VInput from '@/components/ui/VInput.vue';
import { computed, ref, watch } from 'vue';
import { debounce } from 'lodash';
import { VSimpleContextMenu } from '@/components/ui';

const { data } = defineProps(['data']);

const modelCheckBox = computed({
  get: () => data.isChecked,
  set: (value) => data.onExcludeToggle(value),
});

const name = ref(data.value);
const modelName = computed({
  get: () => name.value,
  set: (val) => {
    name.value = val;
    data.onNameChange(val);
  }, //data.toggleOnNameChange(val)
});
</script>

<template>
  <VSimpleContextMenu
    :group-id="data.value + data.uuid"
    :data="data"
    :options="data.optionsList || []"
  >
    <template #content>
      <div :class="{ 'childrenItem': true }">
        <div :class="{'accordion__selected-item': data.isSelected}"></div>
        <VCheckbox v-model="modelCheckBox" />
        <div class="itemAccordion">
          <div>{{ data.title }}</div>
          <VInput v-model="modelName" :disabled="data.disableValue" class="accordionItemInput" />
        </div>
      </div>
    </template>
  </VSimpleContextMenu>
</template>

<style scoped>
.itemAccordion {
  display: grid;
  align-items: center;
  width: 240px;
  color: #dadae8;
  font-size: 12px;
  font-weight: 400;
  grid-template-columns: 100px auto;
  grid-gap: 8px;
}
.accordionItemInput {
  display: inline-block;
  height: 24px;
  width: 150px;
}
</style>