import service from '@/services/service';
import { defaultWeatherAPIQueryParams } from '@/config/weather';
import moment from 'moment';
import { parseOpenWeatherApiResult } from '@/utils/mapUtils';

const initState = {
  wind_speed_10m: null,
  wind_speed_1000m: null,
  wind_direction_10m: null,
  wind_direction_1000m: null,
  weather_data: [],
};
function initialState() {
  return {
    ...initState,
  };
}
// Getter functions
const getters = {};
// Actions
const actions = {
  async getWeather({ commit, state }, targetPoint, conditions = {}) {
    const { data } = await service.getWeather(targetPoint, {
      ...defaultWeatherAPIQueryParams,
      ...conditions,
    });
    commit('getWeatherSuccess', data);
    commit('storeWindAsGeoCoordinatesQuery', data);
    return data;
  },
};
// Mutations
const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  getWeatherSuccess(state, weatherData) {
    const { wind_direction_10m, wind_speed_10m, time } = weatherData.current;

    const formattedTime = moment(time).format('YYYY-MM-DD-HH');

    const currentHour = weatherData.hourly.time.findIndex(
      (time) => moment(time).format('YYYY-MM-DD-HH') === formattedTime
    );
    const wind_direction_1000m = weatherData.hourly.winddirection_900hPa[currentHour];
    const wind_speed_1000m = weatherData.hourly.windspeed_900hPa[currentHour];

    state.wind_direction_1000m = wind_direction_1000m;
    state.wind_speed_1000m = wind_speed_1000m;
    state.wind_direction_10m = wind_direction_10m;
    state.wind_speed_10m = wind_speed_10m;
  },
  storeWindAsGeoCoordinatesQuery(state, weatherData) {
    state.weather_data = parseOpenWeatherApiResult(weatherData);
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
