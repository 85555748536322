import {computed, nextTick, onUnmounted, ref, watch} from "vue";
import useShowEvent from "@/hooks/useShowEvent";
import {SECTIONS_IDs} from "@/config/sections";

export default function useSelectedPolygonDraw (store) {
    const { onClearShowBar, onShow: show } = useShowEvent({ onEscapeClickCustom: () => store.commit('map/setSelectedFeatureUUID', null)});
    const uuidModel = computed(() => store?.state?.map?.showPolygonID);

    const onShow = (...args) => nextTick(() => show(...args));

    const currentFeature = computed(() => (store?.state?.map?.geoJson?.features || []).find(feature => feature.uuid === uuidModel?.value && feature?.geometry?.type === 'Point') );
    const coordinates = computed(() => currentFeature.value ? currentFeature?.value?.geometry?.coordinates?.flat(3) : null);
    const isStartFinish = computed(() => ['start', 'finish'].includes(uuidModel.value));

    const startFinishCoordinates = computed(() => isStartFinish.value && uuidModel.value === 'start'
        ? store.getters['params/getStartPointCoordinates']
        : isStartFinish.value && uuidModel.value === 'finish'
            ? store.getters['params/getEndPointCoordinates']
            : null);

    watch(startFinishCoordinates, () => {
        if(isStartFinish.value
            && Array.isArray(startFinishCoordinates.value)
            && startFinishCoordinates.value.every(Number)) {
            onClearShowBar();

            onShow([{
                geometry: {
                    coordinates: startFinishCoordinates.value.map(e => e).reverse(),
                    type: 'Point'
                },
                properties: {
                    isTakeoff: uuidModel.value === 'start',
                    isLanding: uuidModel.value === 'finish'
                }
            }]);
        } else {
            onClearShowBar();
        }
    })


    watch(currentFeature, () => {
        if(currentFeature.value ) {
            onClearShowBar();
            onShow([currentFeature?.value])
        } else {
            onClearShowBar();
        }
    }, { deep: true })

    watch(uuidModel, () => {
        if(!uuidModel.value)
            onClearShowBar();
    });

    onUnmounted(() => onClearShowBar());

    return {
        onClearSelectionViewBar: onClearShowBar
    }
}