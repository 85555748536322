import router from '@/router/index.js';

const queryOrder = ['mission', 'zoom', 'lat', 'lng']

export function routerMerge({ path = '/', query }) {
  const currentQuery = router.currentRoute?.value?.query
  const mergedQuery = { ...currentQuery, ...query }
  const newQuery = queryOrder.reduce((acc, queryName) => {
    const value = mergedQuery[queryName]
    return value ? { ...acc, [queryName]: value } : acc
  }, {})
  router.push({ path, query: newQuery });
}