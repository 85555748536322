<script setup>
import { computed, onBeforeUnmount } from 'vue';
import { VInputNumber, VReloadIcon, VSelect } from '@/components/ui';
import { useStore } from 'vuex';
import { useMappingStoreParams, useProfilesSelect } from '@/utils/helpers';

const store = useStore();

const unsubscribe = store.subscribe(({ type, payload }, state) => {
  if (type === 'params/setMissionProfile' && state.params.plain && payload) {
    const currentMission = state.params.missionProfiles.find(({ ID }) => ID === payload);
    const currenPlain = state.params.plains.find(({ ID }) => ID === state.params.plain);
    if (currentMission?.type !== currenPlain?.type) {
      store.commit('params/resetPlain');
    }
  }
});

onBeforeUnmount(() => unsubscribe());

const { fieldList: plainList, fieldSelectedModel: plainSelectedModel } = useProfilesSelect(
  'plain',
  'plains',
  'params/setPlain'
);

const filteredPlainData = computed(() => {
  const currentMission = store.state.params.missionProfiles.find(
    ({ ID }) => ID === store.state.params.missionProfile
  );
  return plainList.value
    .filter(({ type }) => type === currentMission?.type)
    .map((e) => ({
      label: e.label,
      value: e.ID,
    }));
});

const { powerCount, powerUsed, powerCountDisabled, cppRouteLength } = useMappingStoreParams([
  'powerCount',
  'powerCountDisabled',
  'powerUsed',
  'cppRouteLength',
]);

const texts = {
  powerAvailable: 'Battery available [Ah]',
  powerUsed: 'Battery used [Ah]',
  length: 'Length [km] ',
};
</script>

<template>
  <div>
    <div class="flight-select">
      <VSelect v-model="plainSelectedModel" :options="filteredPlainData" class="result-field" />
      <div class="reload">
        <VReloadIcon
          :refresh="
            async () => {
              await store.dispatch('params/getPlainsList');
              store.commit('params/setPlain', store.state.params.plain);
            }
          "
        />
      </div>
    </div>
    <div class="form">
      <VInputNumber
        v-model="powerCount"
        class="form__item-number"
        :disabled="powerCountDisabled"
        :label-text="texts.powerAvailable"
      />
      <VInputNumber
        v-model="powerUsed"
        class="form__item-number"
        disabled
        :label-text="texts.powerUsed"
      />
      <VInputNumber
        v-model="cppRouteLength"
        disabled
        class="form__item-number"
        :label-text="texts.length"
      />
      <!--    <VInputNumber
       v-for="item in data"
       :key="item.value"
       v-model="item.value"
       class="form__item-number"
       :label-text="item.title"
     />-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flight-select {
  width: 100%;
  margin-bottom: 12px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  .result-field {
    width: 100%;
    height: 24px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .reload {
    border: 1px solid #7d7d91;
    border-radius: 8px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    .clickedImg {
      transform: rotate(180deg);
    }

    img {
      cursor: pointer;
      transition: 1s;
    }
  }
}

.form {
  $this: &;

  &__item-number {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    :deep(.input-num) {
      margin-top: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>