import { createI18n } from 'vue-i18n';
import ua from '../locales/ua.json';
import en from '../locales/en.json';

const i18n = createI18n({
  locale: 'ua',
  messages: {
    ua,
    en,
  },
});

export default i18n;
