<script setup>
import { VSelect, VCheckBox, VInputNumber } from '@/components/ui';
import VLicense from '@/components/params/components/VLicense';
import { ref } from 'vue';
import { useMappingStoreParams } from '@/utils/helpers';

const texts = {
  licenseCheckbox: 'Portrait',
  focalLabel: 'Focal Length [mm]:',
  GsdLabel: 'GSD [cm/px]:',
  Overlap_x: 'Forward Overclap [%]',
  Overlap_y: 'Side Overclap [%]',
  OpticalY: 'Optical Heigh [m]',
  OpticalX: 'Optical Weigh [m]',
  OverShoot: 'Overshoot [m]',
  Terracing: 'Terracing',
  RouteTF: 'Route TF',

  selectLabels: {
    lowest: 'at Lowest Elevation',
    highest: 'at Highest Elevation',
  },
};

const selectValue = ref(false);

const selectOptions = [
  {
    label: texts.selectLabels.highest,
    value: true,
  },
  {
    label: texts.selectLabels.lowest,
    value: false,
  },
];
const {
  FocalLength: forcialModel,
  Overlap_x,
  Overlap_y,
  GSD,
  FrameSizeMm_x: OpticalX,
  FrameSizeMm_y: OpticalY,
  LevelingDistance: OverShoot,
  isTerracing: terracing,
  isRouteFP: routeFP,
} = useMappingStoreParams([
  'FocalLength',
  'Overlap_x',
  'Overlap_y',
  'GSD',
  'FrameSizeMm_x',
  'FrameSizeMm_y',
  'LevelingDistance',
  'isTerracing',
  'isRouteFP',
]);
</script>

<template>
  <div class="form">
    <div class="form__section">
      <VLicense />
      <VInputNumber
        v-model="forcialModel"
        class="form__item-number"
        :label-text="texts.focalLabel"
      />
      <VInputNumber v-model="GSD" class="form__item-number" :label-text="texts.GsdLabel" />
    </div>
    <div class="form__section">
      <VSelect v-model="selectValue" :options="selectOptions" />
      <VInputNumber v-model="Overlap_x" class="form__item-number" :label-text="texts.Overlap_x" />
      <VInputNumber v-model="Overlap_y" class="form__item-number" :label-text="texts.Overlap_y" />
    </div>
    <div>
      <VSelect v-model="selectValue" :options="selectOptions" />
      <VInputNumber v-model="OpticalX" class="form__item-number" :label-text="texts.OpticalY" />
      <VInputNumber v-model="OpticalY" class="form__item-number" :label-text="texts.OpticalX" />
      <VInputNumber v-model="OverShoot" class="form__item-number" :label-text="texts.OverShoot" />
      <div class="form__section__checkbox-group">
        <VCheckBox v-model="terracing" :label="texts.Terracing" />
        <VCheckBox v-model="routeFP" :label="texts.RouteTF" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form {
  $this: &;

  &__section {
    $this: &;

    width: 100%;
    display: inline-block;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--black-700);
    &__checkbox-group {
      width: 100%;
      display: inline-block;
      margin-top: 12px;
      :deep(.el-checkbox__label) {
        color: #dadae8;
        font-size: 12px;
        font-weight: 400;
        padding-left: 11px;
      }
      :deep(.el-checkbox__inner) {
        background: var(--side-bar-bg);
      }
      & > label:last-child {
        margin-left: 16px;
      }
    }
  }
  &__item-number {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    :deep(.input-num) {
      margin-top: 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>