<script setup>
const { value, label } = defineProps(['value', 'label']);
</script>

<template>
  <el-checkbox :value="value" class="el-checkbox__custom" @input="$emit('input')">{{
    label || ''
  }}</el-checkbox>
</template>

<style lang="scss" scoped>
.el-checkbox__custom {
  --el-checkbox-input-height: 18px !important;
  --el-checkbox-input-width: 18px !important;
  --el-checkbox-font-size: 12px;
  --el-fill-color-light: #dadae8;
  --el-text-color-regular: #dadae8;
  --el-checkbox-checked-text-color: #dadae8;
  --el-color-primary: #0670eb;
  margin-right: 3px !important;
  .el-checkbox__inner {
    border: 2px solid #dadae8;
    background: #fff;
  }
  :deep(.el-checkbox__inner:after) {
    height: 8px;
    width: 4px;
    top: 1px;
    left: 5px;
    border-width: 2px;
  }
}
</style>