import { getFormattedFeatureType, getFormattedFeatureTypeExport } from '@/utils/mapUtils';
import { debounce } from 'lodash';

const toggleOnNameChange = (uuid, store) => (name) => {
  if (uuid) store.commit('map/updateGeoJsonFeatureByUUID', { properties: { name } });
};
const toggleCppChecked = (id, excludeIDS) => {
  excludeIDS.value = !excludeIDS.value.includes(id)
    ? excludeIDS.value.concat(id)
    : excludeIDS.value.filter((e) => e !== id);
};

const handleParentExcludeToggle = ({ children }, isChecked) => {
  children.forEach((item) => {
    if (item.isChecked === isChecked) item.onExcludeToggle();
  });
};

const toggleChecked = (uuid, excludedUUIDs) => {
  excludedUUIDs.value = !excludedUUIDs.value?.includes(uuid)
    ? [...excludedUUIDs.value, uuid]
    : excludedUUIDs.value.filter((e) => e !== uuid);
};

const computeChild =
  (excludedByUUIDs, store, defaultConstants = {}) =>
  (child) => ({
    uuid: child.uuid,
    isChecked: !excludedByUUIDs.value.includes(child.uuid),
    title: defaultConstants?.useCustomItemsNaming
      ? getFormattedFeatureTypeExport(child)
      : getFormattedFeatureType(child),
    value: child?.properties?.name || defaultConstants?.defaultName || 'Untitled',
    disableValue: defaultConstants?.disableValue || false,
    onExcludeToggle: () => toggleChecked(child.uuid, excludedByUUIDs),
    toggleOnNameChange: toggleOnNameChange(child.uuid),
  });

const attachOnNameChangeToChildren = (store, isImport) => (children) => {
  if (!isImport)
    return {
      ...children,
      onNameChange: debounce((name) => {
        if (!children.uuid) {
          store.commit('map/updateCppItemName', { id: children.id, name });
        }
        store.commit('map/updateGeoJsonFeatureByUUID', {
          uuid: children.uuid,
          properties: { name },
        });
      }, 300),
    };
  return {
    ...children,
    onNameChange: debounce(
      (value) =>
        store.commit('exports/changeNameOfImportedItem', { uuid: children.uuid, name: value }),
      300
    ),
  };
};

export {
  computeChild,
  handleParentExcludeToggle,
  toggleCppChecked,
  toggleOnNameChange,
  toggleChecked,
  attachOnNameChangeToChildren,
};
