import {
  GEO_JSON_IDS as IDS_CONST,
  START_POINT_NAME,
  END_POINT_NAME,
  ACTION_LINE_STYLES,
} from '@/config/map-config';
import { v4 } from 'uuid';

const ACTION_LINE_KIND_START = 'ActionStartZone';
const ACTION_LINE_KIND_FINISH = 'ActionStopZone';

export function startFinishToAccordionFormat(
  { startCoords, finishCoords },
  excludedByUUID,
  toggleChecked
) {
  return [
    {
      value: startCoords,
      title: START_POINT_NAME,
    },
    {
      value: finishCoords,
      title: END_POINT_NAME,
    },
  ]
    .filter(
      ({ value }) =>
        Array.isArray(value) && value.length === 2 && Number(value[0]) && Number(value[1])
    )
    .map(({ value: coordinates, title: label }) => ({
      uuid: label,
      isChecked: !excludedByUUID.includes(label),
      title: label,
      value: coordinates.map((e) => Number(e).toFixed(6)).join(' , '),
      originalValue: coordinates,
      onExcludeToggle: () => toggleChecked(label),
      disableValue: true,
      toggleOnNameChange: () => {
        console.warn('TakeOff/Landing is not reachable');
      },
    }));
}

export function attachStartFinishAsGeoJson(startFinishInAccordionArray) {
  return startFinishInAccordionArray
    .filter((e) => e.isChecked)
    .map(({ originalValue: coordinates, title }) => ({
      geometry: {
        type: 'Point',
        coordinates: coordinates.map((e) => e).reverse(),
      },
      id: IDS_CONST.default,
      properties: {
        name: title,
        type: title.toLowerCase(),
      },
    }));
}

export const buildActionLineZone = ({
  geometry: { coordinates: pathCoordinates },
  properties: { PointProps: pointProps },
}) => {
  return pointProps.reduce(
    (acc, { Kind, ...props }, index) => {
      const isStart = Kind === ACTION_LINE_KIND_START;
      if (!isStart) return acc;
      const endPointIndex = pointProps.findIndex(
        ({ Kind }, searchIndex) => Kind === ACTION_LINE_KIND_FINISH && searchIndex > index
      );
      const startPointCoordinates = pathCoordinates[index];
      const endPointCoordinates = pathCoordinates[endPointIndex];

      if (!startPointCoordinates || !endPointCoordinates) return acc;

      const coordinates = [startPointCoordinates, endPointCoordinates];

      acc.features = acc.features.concat({
        geometry: {
          coordinates,
          type: 'LineString',
        },
        type: 'Feature',
        id: IDS_CONST.action_lines,
        uuid: coordinates.flat(2).join(';'),
        properties: {
          name: `Action #${pointProps[index]?.ActionLineNum + 1}`,
          PointProps: pointProps,
        },
        style: ACTION_LINE_STYLES,
      });
      return acc;
    },
    {
      type: 'FeaturesCollection',
      id: IDS_CONST.action_lines,
      features: [],
      properties: ACTION_LINE_STYLES,
      style: ACTION_LINE_STYLES,
    }
  );
};
