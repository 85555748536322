// toDo: crate and move to global config;
const DOWN_SCALE_BY_DEFAULT = [400, 300];

export default function downScaler(imgBase64, [width, height] = DOWN_SCALE_BY_DEFAULT) {
  const canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  return new Promise((res) => {
    const image = new Image();
    image.onload = function () {
      ctx.drawImage(image, 0, 0, width, height);
      const resizedImg = canvas.toDataURL();
      res(resizedImg);
    };
    image.src = imgBase64;
  });
}
