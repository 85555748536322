<template>
  <el-menu-item
    v-if="!data?.children || !data?.children?.length"
    :index="data?.title"
    @click.stop="(e) => data?.click?.(e)"
  >
    <template #title>
      <span @click.stop="(e) => data?.click?.(e)">{{ data?.title }}</span>
      <div :class="data.isItemBreak ? 'divider' : ''"></div>
    </template>
  </el-menu-item>
  <el-sub-menu
    v-else
    :index="data?.title"
    popper-offset="0"
    expand-open-icon="iconn"
    popper-class="poperHeaderMenu"
  >
    <template #title>
      <img 
        v-if="data?.isReadOnly?.value" 
        class="is-read-only-icon" 
        :src="Lock" 
        alt="is read only" 
        title="Is read only"
      />
      <span>{{ data?.title }}</span>
      <div :class="data.isItemBreak ? 'divider' : ''"></div>
      <img v-if="data?.header" :src="DownIcon" alt="down icon" />
    </template>
    <el-menu-item-group>
      <span v-for="item in data?.children" :key="item.title">
        <VHeaderMenuItem :key="data?.title" :data="item" />
      </span>
    </el-menu-item-group>
  </el-sub-menu>
</template>
<script>
import { DownIcon, RightArrowBtn, Lock } from '@/assets/svgs';

export default {
  name: 'VHeaderMenuItem',
  props: {
    data: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  data() {
    return { RightArrowBtn, Lock };
  },
  computed: {
    DownIcon() {
      return DownIcon;
    },
  },
};
</script>
<style src="@/styles/components/layout/components/menu-item.scss" lang="scss" scoped></style>