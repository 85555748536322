<script setup>
import { computed, defineEmits, ref, watch } from 'vue';
import { AccordionArrow } from '@/assets/svgs';
import VAccordionChildren from '@/components/ui/VAccordionChildren';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import { VSimpleContextMenu } from '@/components/ui';
import VAccordion from "@/components/ui/VAccordion";
import {set} from "lodash";

const { open: openDefault, item, onOpenSection: onOpen, isNewCheckboxes } = defineProps(['open', 'item', 'onOpenSection', 'isNewCheckboxes']);
const emit = defineEmits(['open']);

const isOpen = computed({
  get: () => openDefault,
  set: () => {
    if(onOpen) {
      console.log(openDefault)
      onOpen(!openDefault);
    }
    emit('open')
  },
});

const checkBoxModel = computed({
  get: () => item.isChecked,
  set: (value) => {
    if (item.onExcludeToggle) {
      item.onExcludeToggle(value);
    }
  },
});

const isSomeChildrenIsChecked = (tree) => {
  return Array.isArray(tree?.children) && tree.children.some(e => e.isChecked || isSomeChildrenIsChecked(e))
}
const isSomeChecked = computed(() => {
  if(!isNewCheckboxes)
    return item.isChecked
  return isSomeChildrenIsChecked(item);
})

</script>

<template>
  <div v-if="item.children?.length" :key="item.isChecked + isOpen + isSomeChecked" class="accordion">
    <VSimpleContextMenu :data="data" :options="item.optionsList || []">
      <template #content>
        <div :class="{'accordion__headerOpen accordion__header': isOpen, 'accordion__header': !isOpen, 'is-checked_some': isSomeChecked}">
          <div class="arrow" @click="isOpen = !isOpen">
            <img :src="AccordionArrow" alt="arrow" />
          </div>
          <VCheckbox v-model="checkBoxModel" />
          <div class="title">{{ item.term }}</div>
        </div>
      </template>
    </VSimpleContextMenu>
    <div v-if="isOpen" class="content">
      <span
        v-for="children in item.children"
        :key="isSomeChecked + children.isChecked + children.uuid + children.isSelected + children.title + children.disableValue"
      >
        <VAccordion :isNewCheckboxes="isNewCheckboxes" class="childrenItem" v-if="children?.children?.length > 0" :data="[children]" />
        <VAccordionChildren v-else :data="children" />
      </span>
    </div>
  </div>
</template>

<style lang="scss" >
.accordion {
  display: flex;
  flex-direction: column;
  &__selected-item {
    background: var(--black-700);
    min-width: calc(100% + 200px);
    position: absolute;
    height: 100%;
    opacity: 0.2;
    border-radius: 8px;
    left: -22px;
  }
  .list-group-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-content: space-between;
    justify-content: flex-start;
    flex-wrap: nowrap;
    cursor: pointer;
  }
  .list-group {
    transition: 1s;
  }
  //.list-group:hover {
  //  border-bottom: 1px solid var(--black-700);
  //}
  :deep(.el-checkbox) {
    height: auto;
    --el-checkbox-input-height: 18px;
    --el-checkbox-input-width: 18px;
  }
  :deep(.el-checkbox__inner:after) {
    height: 9px;
    left: 6px;
  }
  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #7d7d91;
    width: 100%;
    .arrow {
      background: #7d7d91;
      margin-right: 8px;
      cursor: pointer;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        transition: 0.2s;
      }
    }
    .title {
      color: #dadae8;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
    }
  }
  &__headerOpen {
    border-bottom: 0;
    img {
      transform: rotate(90deg);
    }
  }
  .content {
    padding: 0 56px;
    position: relative;
    &:after {
      content: '';
      width: 1px;
      background-image: linear-gradient(
        0deg,
        #7d7d91,
        #7d7d91 75%,
        transparent 75%,
        transparent 100%
      );
      background-size: 1px 2px;
      display: block;
      position: absolute;
      left: 34px;
      top: -8px;
      bottom: 13px;
    }
    .childrenItem {
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative;

      &:after {
        content: '';
        height: 1px;
        background-image: linear-gradient(
          90deg,
          #7d7d91,
          #7d7d91 75%,
          transparent 75%,
          transparent 100%
        );
        background-size: 2px 1px;
        display: block;
        position: absolute;
        top: 17px;
        width: 20px;
        left: -20px;
      }
    }
  }
}
</style>