<template>
  <div>
    <label v-if="labelText" :for="name">{{ labelText }}</label>
    <el-input
      :class="clearable && modelValue ? 'v-inputActive' : 'v-input'"
      :name="name"
      :model-value="modelValue"
      :parser="parser"
      :formatter="formatter"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="(value) => $emit('update:modelValue', value)"
    >
      <template #suffix>
        <img
          v-if="clearable && modelValue"
          class="suffixClearIcon"
          :src="ClearIcon"
          alt="clear"
          @click="() => $emit('update:modelValue', '')"
        />
      </template>
    </el-input>
  </div>
</template>

<script>
import { ClearIcon } from '@/assets/svgs';
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    labelText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    parser: {
      type: Function,
      default: undefined,
    },
    formatter: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    ClearIcon() {
      return ClearIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  --el-fill-color-blank: #2d2c2c;
  --el-component-size: 24px;
  --el-font-size-base: 12px;
  --el-border-color: #7d7d91;
  --el-text-color-regular: #dadae8;
}

.v-inputActive {
  --el-fill-color-blank: #3d3a3a;
  --el-component-size: 24px;
  --el-font-size-base: 12px;
  --el-border-color: #ffffff;
  --el-text-color-regular: #dadae8;
  :deep(.el-input__wrapper){
    padding-right: 2px;
  }
  .suffixClearIcon {
    cursor: pointer;
  }
}
</style>