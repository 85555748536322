<template>
  <div class="content__item">
    <VCheckbox v-model="checkboxModel" />
    <div class="itemImport">
      {{ childItem.title }}
      <VInput
        v-if="typeof childItem.value === 'string'"
        class="importItemInput"
        :value="childItem.value"
      />
    </div>
    {{ childItem.lastTitle }}
  </div>
</template>

<script>
import VInput from '@/components/ui/VInput.vue';
import VCheckbox from '@/components/ui/VCheckbox.vue';

export default {
  name: 'VImportItem',
  components: { VCheckbox, VInput },
  props: {
    childItem: {
      value: {
        type: String,
        default: 'Untitled',
      },
      isChecked: {
        type: Boolean,
      },
      onExcludeToggle: {
        type: Function,
      },
    },
  },
  computed: {
    checkboxModel: {
      get() {
        return this.childItem.isChecked;
      },
      set() {
        return this.childItem.onExcludeToggle();
      },
    },
  },
};
</script>

<style scoped>
.itemImport {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
}
.importItemInput {
  margin: 0 8px;
  width: 120px;
  height: 24px;
}
</style>