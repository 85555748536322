<script setup>
const directionEnum = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};
const { direction, withoutHeader } = defineProps(['direction', 'withoutHeader']);
</script>
<template>
  <div :class="{ 'side-bar': true, vertical: direction === directionEnum.vertical }">
    <div v-if="!withoutHeader" class="header">
      <slot name="header"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style lang="scss" src="@/styles/components/ui/sidebar.scss" scoped></style>