<template>
  <el-pagination
    class="v-pagination"
    background
    layout="prev, pager, next"
    :total="totalCount"
    :page-size="pageSize"
    @current-change="(val) => $emit('change', val)"
  />
</template>

<script>
export default {
  props: {
    totalCount: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-pagination {
  --el-color-primary: #0670eb;
  --el-fill-color: #666666;
  --el-pagination-button-width: 20px;
  --el-pagination-button-height: 20px;
  --el-text-color-primary: #fff;
  --el-disabled-bg-color: #444444;
  --el-pagination-hover-color: #fff;
  --el-pagination-font-size: 12px;
}
</style>