import { debounce, get, set } from 'lodash';

export const parseHierarchyAndGroupFolders = (hierarchy) => {
    const bySourceFile = (hierarchy || []).reduce((acc, item) => {
        const isSubDirectory = `${item.term}`.split('/').length === 2;
        if (isSubDirectory) {
            const [fileName, folderName] = `${item.term}`.split('/').map((e) => `${e}`.trim());

            Object.assign(acc, {
                [fileName]: {
                    ...(acc[fileName] || {}),
                    [folderName]: {
                        ...item,
                        term: folderName,
                    },
                },
            });
            return acc;
        }
    }, {});

    return Object.keys(bySourceFile || {}).map((fileName) => ({
        fileName,
        children: Object.values(bySourceFile[fileName]),
    }));
}
export default function buildImportHierarchy({ importedInfoLayer }) {
  const groupListKeys = (importedInfoLayer?.features || []).reduce(
    (acc, { properties, ...feature }) => {
      const {
        group: { uuid, title },
        isHidden,
        name,
      } = properties;
      const {
        geometry: { type },
      } = feature;

      const currentGroup = get(acc, uuid, {
        children: [],
        title,
        id: uuid,
        group_uuid: uuid,
        isChecked: true,
      });
      if (isHidden) set(currentGroup, 'isChecked', !isHidden);

      set(
        currentGroup,
        'children',
        currentGroup.children.concat({
          isChecked: !isHidden,
          title: type.toUpperCase(),
          value: name || 'Untitled',
          uuid: feature.uuid,
          rawData: Object.assign({}, feature, { properties }),
        })
      );

      set(acc, uuid, currentGroup);

      return acc;
    },
    {}
  );
  return Object.values(groupListKeys);
}
