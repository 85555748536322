import { SECTIONS_IDs as SECTION_ENUMS, SECTIONS_DETAILS, DEFAULT_ACTIVE } from '@/config/sections'

const initState = {
    activeSection: DEFAULT_ACTIVE
};

// Getter functions
const getters = {
    getSectionsList(...args) {
        return Object.entries(SECTIONS_DETAILS)
            .reduce((acc, [id, computeFunction]) => {
              const result = Object.assign({ id }, computeFunction(...args))
              return result.hidden ? acc : [...acc, result]
            }, [])
    },
};
// Actions
const actions = {};
// Mutations
const mutations = {
    reset(state) {
        state.activeSection = DEFAULT_ACTIVE;
    },
    openSection(state, SECTION_ID) {
        if(!SECTION_ENUMS[SECTION_ID]) {
            throw new Error('Section is not supported!');
        }
        state.activeSection = SECTION_ID;
    }
};
export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
