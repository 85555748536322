<template>
  <RootMap :geo-json="geoJsonComputed || []" @mapMounted="onMapMounted" />
  <!--  ToDO: Here Control panel   -->
</template>

<script>
import RootMap from '@/lib/map/Map';
import { mapState, useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { GEO_JSON_IDS as GEO_IDS } from '@/config/map-config';
import { get } from 'lodash';
import useLoiters from '@/hooks/useLoiters';
import * as config from '@/config/map-config';

export default {
  name: 'FlightPlannerMap',
  components: {
    RootMap,
  },
  setup() {
    const store = useStore();
    const map = ref(null);

    const geoJsonComputed = computed(() => {
      const geoJson = store.state.map.geoJson;
      const cpp_geoJson = store.state.map.cpp_geoJson;

      return [geoJson, cpp_geoJson].flat(2).filter((e) => e);
    });
    const showPolygonsIDs = computed(() => store.state.map.showPolygonIds);

    const isPathHidden = computed(() => !store.state.map.showPolygonIds.includes(GEO_IDS.path));
    const { refreshLoiters, clearLoiters } = useLoiters(geoJsonComputed, map, isPathHidden);

    const importedItems = computed(() => store.getters['map/getHierarchy']);
    const startLanding = computed(() =>
      [store.state.params.latitude_start, store.state.params.longitude_start]
        .concat([store.state.params.latitude_end, store.state.params.longitude_end])
        .join(',')
    );

    const onDrawModeToggled = (drawMode) => {
      store.commit('map/setIsDrawModeEnabled', drawMode);
      console.log('DrawMode ', drawMode)
      if(drawMode) clearLoiters()
      else refreshLoiters(geoJsonComputed.value)
    }
    const onMapMounted = (value) => {
      // Using Global Map events to control loiters;
      Object.values(config.MAP_EVENTS.TOGGLES).forEach((event) => {
        value.on(event, ({ enabled }) => onDrawModeToggled(enabled));
      });
      Object.values(config.MAP_EVENTS.ACTIONS).forEach((event) => {
        value.on(event, () => {
          console.info('Detected map changes, marking mission as draft....');
          store.commit('markAsDraft');
        });
      });
      map.value = value;
      window.map = value;

    };

    return {
      geoJsonComputed,
      onMapMounted,
      showPolygonsIDs,
      importedItems,
      startLanding,
    };
  },
};
</script>
<style></style>