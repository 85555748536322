<template>
  <VModal
    :is-visible="isVisible"
    :title="title"
    size="small"
    :title-icon="LocationIcon"
    close-text="Close"
    ok-text="Apply"
    @close="$emit('close', close)"
    @success="onSuccessClick()"
  >
    <template #body>
      <div class="container">
        <slot name="bodyStart"></slot>
        <VSelect
          v-model="currentInputType"
          :options="selectOptions"
          aria-placeholder="Degrees/minutes/seconds"
        />
        <div v-if="currentInputType === ENUM_INPUTS.DMS">
          <div class="part">
            <div class="title">Latitude (DMS):</div>
            <div class="items">
              <el-input-number
                v-model="dataDMS.lat.deg"
                :min="0"
                :max="90"
                title=""
                class="input-num dataDD"
                :controls="false"
              />
              <div>°</div>
              <el-input-number
                v-model="dataDMS.lat.min"
                :min="0"
                :max="60"
                title=""
                class="input-num dataDD"
                :controls="false"
              />
              <div>’</div>
              <el-input-number
                v-model="dataDMS.lat.sec"
                :min="0"
                :max="60"
                title=""
                class="input-num dataDD dataSec"
                :controls="false"
                :precision="4"
              />
              <div>”</div>
              <VSelect
                v-model="dataDMS.lat.direction"
                :options="[
                  { label: 'S', value: 'S' },
                  { label: 'N', value: 'N' },
                ]"
              />
            </div>
          </div>
          <div class="part">
            <div class="title">Longitude (DMS):</div>
            <div class="items">
              <el-input-number
                v-model="dataDMS.lon.deg"
                :min="0"
                :max="180"
                title=""
                class="input-num dataDD"
                :controls="false"
              />
              <div>°</div>
              <el-input-number
                v-model="dataDMS.lon.min"
                :min="0"
                :max="60"
                title=""
                class="input-num dataDD"
                :controls="false"
              />
              <div>’</div>
              <el-input-number
                v-model="dataDMS.lon.sec"
                :min="0"
                :max="60"
                title=""
                class="input-num dataDD dataSec"
                :controls="false"
                :precision="4"
              />
              <div>”</div>
              <VSelect
                v-model="dataDMS.lon.direction"
                :options="[
                  { label: 'E', value: 'E' },
                  { label: 'W', value: 'W' },
                ]"
              />
            </div>
          </div>
        </div>
        <div v-else-if="currentInputType === ENUM_INPUTS.DD">
          <div class="part dd-part">
            <div class="items">
              <div class="title">Latitude (DD):</div>
              <div class="field">
                <el-input-number
                  v-model="dataDD.lat"
                  :min="-90"
                  :max="90"
                  title=""
                  :precision="6"
                  class="input-num dataDD"
                  :controls="false"
                />
                <div>°</div>
              </div>
            </div>
            <div class="items">
              <div class="title">Longitude (DD):</div>
              <div class="field">
                <el-input-number
                  v-model="dataDD.lon"
                  :min="-180"
                  :max="180"
                  :precision="6"
                  title=""
                  class="input-num dataDD"
                  :controls="false"
                />
                <div>°</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="currentInputType === ENUM_INPUTS.DDM">
          <div class="part">
            <div class="title">Latitude (DDM):</div>
            <div class="items">
              <el-input-number
                v-model="dataDDM.lat.deg"
                :min="0"
                :max="90"
                title=""
                class="input-num dataDD"
                :controls="false"
              />
              <div>°</div>
              <el-input-number
                v-model="dataDDM.lat.decimalMin"
                :min="0"
                :max="60"
                :precision="4"
                title=""
                class="input-num dataDD"
                :controls="false"
              />
              <div>’</div>
              <VSelect
                v-model="dataDDM.lat.direction"
                :options="[
                  { label: 'S', value: 'S' },
                  { label: 'N', value: 'N' },
                ]"
              />
            </div>
          </div>
          <div class="part">
            <div class="title">Longitude (DDM):</div>
            <div class="items">
              <el-input-number
                v-model="dataDDM.lon.deg"
                title=""
                :min="0"
                :max="180"
                class="input-num dataDD"
                :controls="false"
              />
              <div>°</div>
              <el-input-number
                v-model="dataDDM.lon.decimalMin"
                title=""
                :min="0"
                :max="60"
                :precision="4"
                class="input-num dataDD"
                :controls="false"
              />
              <div>’</div>
              <VSelect
                v-model="dataDDM.lon.direction"
                :options="[
                  { label: 'E', value: 'E' },
                  { label: 'W', value: 'W' },
                ]"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </VModal>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import { mapGetters } from 'vuex';
import { VModal } from '@/components/ui';
import { LocationIcon } from '@/assets/svgs';
import VSelect from '@/components/ui/VSelect.vue';
import { convertDDToDMS, convertDMSToDD, convertDDToDDM, convertDDMToDD } from '@/utils/mapUtils';
import _ from 'lodash';
// import VInput from '@/components/ui/VInput.vue';
// import VInputNumber from '@/components/ui/VInputNumber.vue';

const ENUM_INPUTS = {
  DD: 'Decimal degrees',
  DMS: 'Degrees/minutes/seconds',
  DDM: 'Degrees/decimal minutes',
};

export default defineComponent({
  components: { VSelect, VModal },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    initValues: {
      type: Array,
      default() {
        return null;
      },
    },
    title: {
      type: String,
      default: '',
    },
    close: {
      type: Function,
      default: null,
    },
    success: {
      type: Function,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const dataDMS = reactive({
      lat: {
        deg: null,
        min: null,
        sec: null,
        direction: 'N',
      },
      lon: {
        deg: null,
        min: null,
        sec: null,
        direction: 'E',
      },
    });
    const dataDDM = reactive({
      lat: {
        deg: null,
        decimalMin: null,
        direction: 'N',
      },
      lon: {
        deg: null,
        decimalMin: null,
        direction: 'E',
      },
    });
    const dataDD = reactive({
      lat: null,
      lon: null,
    });

    return { dataDMS, dataDDM, dataDD, ENUM_INPUTS, currentInputType: ENUM_INPUTS.DD };
  },
  computed: {
    ...mapGetters({ getCustomPointCoordinates: 'map/getCustomPointCoordinates' }),
    selectOptions() {
      return Object.keys(ENUM_INPUTS).map((key) => ({
        value: ENUM_INPUTS[key],
        title: ENUM_INPUTS[key],
      }));
    },
    computedDataDD() {
      return _.cloneDeep(this.dataDD);
    },
    computedDataDMS() {
      return _.cloneDeep(this.dataDMS);
    },
    computedDataDDM() {
      return _.cloneDeep(this.dataDDM);
    },
    LocationIcon() {
      return LocationIcon;
    },
    isSectionValid() {
      return this.currentInputType === this.ENUM_INPUTS.DD
        ? Number(this.dataDD.lat) && Number(this.dataDD.lon)
        : true;
    },
  },
  watch: {
    computedDataDD: {
      handler(newVal, oldValue) {
        if (_.isEqual(newVal, oldValue)) return;
        this.dataDMS = convertDDToDMS(newVal);
        this.dataDDM = convertDDToDDM(newVal);
      },
      deep: true,
    },
    computedDataDMS: {
      handler(newVal, oldValue) {
        if (_.isEqual(newVal, oldValue)) return;
        this.dataDD = convertDMSToDD(newVal);
        this.dataDDM = convertDDToDDM(this.dataDD);
      },
      deep: true,
    },
    computedDataDDM: {
      handler(newVal, oldValue) {
        if (_.isEqual(newVal, oldValue)) return;
        this.dataDD = convertDDMToDD(newVal);
        this.dataDMS = convertDDToDMS(this.dataDD);
      },
      deep: true,
    },

    isVisible(newVal) {
      if (newVal) {
        if (this.initValues) {
          const [lat, lon] = this.initValues;
          this.dataDD = { lat, lon };
        } else {
          const [lon, lat] = this.getCustomPointCoordinates;
          this.dataDD = { lat, lon };
        }
      }
    },
  },
  methods: {
    formatNumber(value) {
      console.log(value);
      return value.replace(/[^\d.-]+/g, '');
    },
    onSuccessClick() {
      const coordinates = this.dataDD;
      if (!!coordinates && Number(coordinates.lat) && Number(coordinates.lon))
        this.$emit('success', coordinates);
    },
  },
});
</script>

<style lang="scss" src="../../../styles/components/ui/inputNumber.scss" scoped></style>
<style lang="scss" scoped>
.container {
  height: auto;
  overflow: auto;
  margin: 12px 0;

  .part {
    display: flex;
    flex-direction: column;
    margin: 12px 0;

    .title {
      font-weight: 400;
      font-size: 12px;
      color: #dadae8;
      line-height: 14px;
      margin-bottom: 4px;
    }

    .items {
      color: #fff;
      display: flex;
      flex-direction: row;
      gap: 4px;

      :deep(.v-select) {
        width: 49px !important;
      }

      :deep(.el-select__wrapper) {
        padding: 0 6px;
      }
    }
  }

  .dd-part {
    flex-direction: row;

    .items {
      flex-direction: column;
      margin-right: 15px;

      .field {
        display: flex;
        align-items: stretch;

        .input {
          width: 90px;
          margin-right: 5px;
        }
      }
    }
  }

  .dataDD {
    width: 90px;
    margin-top: 0px;
  }

  .dataSec {
    :deep(.el-input) {
      width: 60px;
    }
  }

  .input-num.el-input-number {
    :deep(.el-input) {
      .el-input__wrapper {
        padding: 0;
      }

      .el-input__inner {
        color: #fff;
      }
    }
  }
}
</style>