<template>
  <span :class="{'accordion-root-container': true, 'accordion-new-checkboxes': isNewCheckboxes }">
    <span
      v-for="(item, index) in data"
      :key="(item.children || []).map((e) => e.isChecked).join(',') + item.isChecked + item.onOpenSection + opened.has(index)"
    >
      <VAccordionItem :key="(item?.children || []).map(e => e.isChecked)"
                      :item="item"
                      :isNewCheckboxes="isNewCheckboxes"
                      :open="opened.has(index)"
                      :onOpenSection="item.onOpenSection"
                      @open="toggleOpen(index)" />
    </span>
  </span>
</template>
<script>
import { AccordionArrow } from '@/assets/svgs';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import VInput from '@/components/ui/VInput.vue';
import VAccordionItem from '@/components/ui/VAccordionItem';
export default {
  components: { VAccordionItem },
  name: 'VAccordion',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    isNewCheckboxes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { AccordionArrow, opened: new Set([]) };
  },
  mounted() {
    ((Array.isArray(this.data) && this.data) || []).forEach(({ open }, index) => {
      if (open && !this.opened.has(index)) {
        this.opened.add(index);
      } else if (!open && this.opened.has(index)) this.opened.delete(index);
    });
  },
  methods: {
    toggleOpen(value) {
      if (this.opened.has(value)) this.opened.delete(value);
      else this.opened.add(value);
    },
  }
};
</script>

<style lang="scss">
  .accordion-new-checkboxes {
    .el-checkbox__input:not(.is-checked) {
      .el-checkbox__inner {
        border-color: var(--black-700);
        background-color: unset;
      }
    }
    .is-checked_some {
      .el-checkbox__input:not(.is-checked) {
        .el-checkbox__inner {
          background-color: var(--el-checkbox-checked-bg-color);
          border-color: var(--el-checkbox-checked-input-border-color);
        }
        .el-checkbox__inner:after {
          content: '-';
          position: relative;
          border: none;
        }
      }
    }
  }
</style>