import { computed, watch } from 'vue';
import { useStore } from 'vuex';

export default function useUnloadDraftDialog(store) {
  const isDraft = computed(() => store.state.isDraft);
  watch(isDraft, (value) => {
    if (value) {
      const titleElement = document.querySelector('head > title');
      titleElement.textContent = titleElement.textContent.endsWith('*')
        ? titleElement.textContent
        : `${titleElement.textContent} *`;
    } else {
      const titleElement = document.querySelector('head > title');
      titleElement.textContent = titleElement.textContent.replace(/\*/g, '');
    }
  });
  window.addEventListener('beforeunload', function (event) {
    if (isDraft.value) {
      event.preventDefault();
      event.returnValue = 'The page contains unsaved data, are you sure with leaving?';
      return 'The page contains unsaved data, are you sure with leaving?';
    }
  });
}
