<template>
  <div>
    <label :for="name">{{ labelText }}</label>
    <el-select
      v-bind="$attrs"
      :name="name"
      :model-value="modelValue"
      :disabled="disabled"
      :class="{ 'v-select': true, ['select-size-' + size]: !!size }"
      :style="
        size === 'xxl'
          ? 'height: 40px; width: 100%'
          : size === 'large'
            ? 'width: 240px'
            : size === 'xs'
              ? 'width: 152px' // TODO: move all to style file regarding to current namespace
              : size === 'm'
                ? 'width: 112px'
                : 'width: 100%'
      "
      popper-class="el-popCustom"
      @change="(value) => $emit('update:modelValue', value)"
    >
      <template v-if="isSearch" #header>
        <VInput v-model="searchValue" placeholder="Search..." />
      </template>
      <el-option
        v-for="item in optionsList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
import VInput from "@/components/ui/VInput";
export default {
  components: {VInput},
  props: {
    name: {
      type: String,
      default: '',
    },
    labelText: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
    searchOption: {
      type: Object,
      default: null
    },
    /***
     * @size<string>: Enum: ['xs', 'large', 'inline', 'xl']
     */
    size: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSearch() {
      return !!this.searchOption
    },
    isCustomHandler() {
      return !!this.searchOption.handler;
    },
    optionsList() {
      if(!this.isSearch || this.isCustomHandler)
        return this.options;
      return this.filteredOptions;
    }
  },
  data: () => ({
    searchValue: '',
    filteredOptions: []
  }),
  watch: {
    searchValue: {
      handler(searchString) {
        if(this.isCustomHandler)
          return this.searchOption.handler(searchString)
        if(!searchString)
          return this.filteredOptions = this.options;

        this.filteredOptions = this.options.filter(({ label }) => new RegExp(searchString, 'i').test(label))
      },
      immediate: true
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" src="../../styles/components/ui/select.scss" scoped></style>
