<template>
    <el-menu-item
      v-if="(!data?.children || !(data?.children?.length > 1)) && !data.hidden"
      :index="data?.title"
      :disabled="data.disabled"
      @click="handleClick"
    >
      <el-tooltip
        trigger="hover"
        :disabled="!data?.icon"
        placement="right"
        :content="data.title"
        offset="25"
      >
        <div :class="data.isSectionBreak ? 'itemBreak item' : 'item'">
          <div class="btnContainerNotItems">
            <img v-if="data?.icon" :src="data?.icon" :alt="data?.title" />
            <span @click="handleClick">{{ data?.title }}</span>
          </div>
          <span v-if="data.isSectionBreak" class="sectionBreak"></span>
        </div>
      </el-tooltip>
    </el-menu-item>
    <el-sub-menu v-else-if="data?.children?.length > 1 && !data.hidden" :index="data?.title" popper-offset="14" popper-class="poperLeftMenu">
      <template #title>
        <div class="btnContainer">
          <img v-if="data?.icon" :src="data?.icon" :alt="data?.title" />
          <img v-if="data?.icon" class="arrowBtn" :src="RightArrowBtn" alt="right arrow" />
          <span>{{ data?.title }}</span>
        </div>
        <span v-if="data?.isSectionBreak" class="sectionBreak"></span>
      </template>
      <el-menu-item-group>
        <span v-for="item in data?.children" :key="item.title">
          <VMenuItem :key="data?.title" :data="item" />
        </span>
      </el-menu-item-group>
    </el-sub-menu>
</template>
<script>
import { RightArrowBtn } from '@/assets/svgs';
import useMapEvents from '@/hooks/useMapEvents';
const { clearPrevMapEvents } = useMapEvents();

export default {
  name: 'VMenuItem',
  props: {
    data: {
      type: Object,
    },
    iSubMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { RightArrowBtn };
  },
  methods: {
    handleClick(e) {
      e?.stopPropagation?.();
      //TODO: think about better place for clean up work
      L.PM.Utils.findLayers(window.map).forEach((layer) => layer?.pm?.disable?.());
      clearPrevMapEvents();
      this.data?.click?.(e);
    },
  },
};
</script>
<style src="@/styles/components/layout/components/menu-item.scss" lang="scss" scoped></style>