import L from 'leaflet';

const _animateZoom = L.Marker.prototype._animateZoom;
L.Marker.prototype._animateZoom = function (...opt) {
  if (!this._map) return;
  _animateZoom.call(this, ...opt);
};

L.marker = function (latlng, options) {
  return new L.Marker(latlng, options);
};
