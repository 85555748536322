/* eslint-disable */

exports.buildWaypoints = async (json, params) => generateMavlinkWaypoints(json, params);

const jsep = require('jsep');
const _ = require('lodash');
const { point: toPoint } = require('@turf/helpers');
const { default: bearing } = require('@turf/bearing');
const { default: distance } = require('@turf/distance');

const { default: store } = require('@/store');

const FEATURE_PATH = 'Path';
const FEATURE_AUX = 'Aux';
const FEATURE_MESSAGES = 'Messages';

// Precision for latitude/longitude values
const PRECISION = 6;

const MAV_FRAME_GLOBAL = 0; // Global (WGS84) coordinate frame + altitude relative to mean sea level (MSL).
const MAV_FRAME_GLOBAL_RELATIVE_ALT = 3; // Global (WGS84) coordinate frame + altitude relative to the home position.

const PARACHUTE_ACTION = {
  PARACHUTE_DISABLE: 0, // Disable auto-release of parachute (i.e. release triggered by crash detectors).
  PARACHUTE_ENABLE: 1, // Enable auto-release of parachute.
  PARACHUTE_RELEASE: 2, // Release parachute and kill motors
};

const SPEED_TYPE = {
  SPEED_TYPE_AIRSPEED: 0,
  SPEED_TYPE_GROUNDSPEED: 1,
  SPEED_TYPE_CLIMB_SPEED: 2,
  SPEED_TYPE_DESCENT_SPEED: 3,
};

const WaypointKind = {
  Takeoff: 'TAKEOFF',
  BeforeMission: 'BEFORE_MISSION',
  MidMission: 'MID_MISSION',
  AfterMission: 'AFTER_MISSION',
  ZoneBegin: 'ZONE_BEGIN',
  ZoneEnd: 'ZONE_END',
  RouteBegin: 'ROUTE_BEGIN',
  RouteEnd: 'ROUTE_END',
  FPointBegin: 'FPOINT_BEGIN',
  FPointEnd: 'FPOINT_END',
  PreOvershootZone: 'PRE_OVERSHOOT_ZONE',
  AftOvershootZone: 'AFT_OVERSHOOT_ZONE',
  PreOvershootRoute: 'PRE_OVERSHOOT_ROUTE',
  AftOvershootRoute: 'AFT_OVERSHOOT_ROUTE',
  ActionStartZone: 'ACTION_START_ZONE',
  ActionStopZone: 'ACTION_STOP_ZONE',
  ActionStartRoute: 'ACTION_START_ROUTE',
  ActionStopRoute: 'ACTION_STOP_ROUTE',
  OverExtraZone: 'OVER_EXTRA_ZONE',
  OverExtraRoute: 'OVER_EXTRA_ROUTE',
  Loiter: 'LOITER',
  Flare: 'FLARE',
  ParachuteOpen: 'PARACHUTE_OPEN',
  BellyLeveling: 'BELLY_LEVELING',
  BellyLand: 'BELLY_LAND',
};

const MAV_CMD = {
  NAV_LOITER_TURNS: { code: 'MAV_CMD_NAV_LOITER_TURNS', id: 18 },
  NAV_LOITER_TIME: { code: 'MAV_CMD_NAV_LOITER_TIME', id: 19 },
  NAV_SPLINE_WAYPOINT: { code: 'MAV_CMD_NAV_SPLINE_WAYPOINT', id: 82 },
  NAV_GUIDED_ENABLE: { code: 'MAV_CMD_NAV_GUIDED_ENABLE', id: 92, skipCoordinates: true },
  NAV_DELAY: { code: 'MAV_CMD_NAV_DELAY', id: 93, skipCoordinates: true },
  NAV_PAYLOAD_PLACE: { code: 'MAV_CMD_NAV_PAYLOAD_PLACE', id: 94 },
  DO_JUMP: { code: 'MAV_CMD_DO_JUMP', id: 177, skipCoordinates: true },
  JUMP_TAG: { code: 'MAV_CMD_JUMP_TAG', id: 600, skipCoordinates: true },
  DO_JUMP_TAG: { code: 'MAV_CMD_DO_JUMP_TAG', id: 601, skipCoordinates: true },
  MISSION_START: { code: 'MAV_CMD_MISSION_START', id: 300, skipCoordinates: true },
  COMPONENT_ARM_DISARM: { code: 'MAV_CMD_COMPONENT_ARM_DISARM', id: 400, skipCoordinates: true },
  CONDITION_DISTANCE: { code: 'MAV_CMD_CONDITION_DISTANCE', id: 114, skipCoordinates: true },
  CONDITION_YAW: { code: 'MAV_CMD_CONDITION_YAW', id: 115, skipCoordinates: true },
  DO_AUX_FUNCTION: { code: 'DO_AUX_FUNCTION', id: 218, skipCoordinates: true }, // NOT FOUND in spec
  DO_SET_HOME: { code: 'MAV_CMD_DO_SET_HOME', id: 179 },
  DO_SET_RELAY: { code: 'MAV_CMD_DO_SET_RELAY', id: 181, skipCoordinates: true },
  DO_REPEAT_SERVO: { code: 'MAV_CMD_DO_REPEAT_SERVO', id: 184, skipCoordinates: true },
  DO_REPEAT_RELAY: { code: 'MAV_CMD_DO_REPEAT_RELAY', id: 182, skipCoordinates: true },
  DO_DIGICAM_CONFIGURE: { code: 'MAV_CMD_DO_DIGICAM_CONFIGURE', id: 202, skipCoordinates: true },
  DO_DIGICAM_CONTROL: { code: 'MAV_CMD_DO_DIGICAM_CONTROL', id: 203, skipCoordinates: true },
  DO_SET_ROI: { code: 'MAV_CMD_DO_SET_ROI', id: 201, skipCoordinates: true }, // DEPRECATED: replaced by MAV_CMD_DO_SET_ROI*
  DO_MOUNT_CONTROL: { code: 'MAV_CMD_DO_MOUNT_CONTROL', id: 205, skipCoordinates: true }, //DEPRECATED: replaced by MAV_CMD_DO_GIMBAL_MANAGER_PITCHYAW
  DO_GIMBAL_MANAGER_PITCHYAW: { code: 'MAV_CMD_DO_GIMBAL_MANAGER_PITCHYAW', id: 1000, skipCoordinates: true },
  DO_GRIPPER: { code: 'MAV_CMD_DO_GRIPPER', id: 211, skipCoordinates: true },
  DO_GUIDED_LIMITS: { code: 'MAV_CMD_DO_GUIDED_LIMITS', id: 222, skipCoordinates: true },
  DO_SET_RESUME_REPEAT_DIST: { code: 'DO_SET_RESUME_REPEAT_DIST', id: 215, skipCoordinates: true }, // NOT FOUND in spec
  DO_FENCE_ENABLE: { code: 'MAV_CMD_DO_FENCE_ENABLE', id: 207, skipCoordinates: true },
  DO_WINCH: { code: 'MAV_CMD_DO_WINCH', id: 42600, skipCoordinates: true },
  STORAGE_FORMAT: { code: 'MAV_CMD_STORAGE_FORMAT', id: 526, skipCoordinates: true },

  NAV_WAYPOINT: { code: 'MAV_CMD_NAV_WAYPOINT', id: 16 }, // Navigate to waypoint
  NAV_LOITER_UNLIM: { code: 'MAV_CMD_NAV_LOITER_UNLIM', id: 17 },
  NAV_RETURN_TO_LAUNCH: { code: 'MAV_CMD_NAV_RETURN_TO_LAUNCH', id: 20, skipCoordinates: true },
  NAV_LAND: { code: 'MAV_CMD_NAV_LAND', id: 21 }, // Land at location
  NAV_TAKEOFF: { code: 'MAV_CMD_NAV_TAKEOFF', id: 22 }, // Takeoff from ground / hand
  NAV_LOITER_TO_ALT: { code: 'MAV_CMD_NAV_LOITER_TO_ALT', id: 31 }, // Begin loiter at the specified Latitude and Longitude
  CONDITION_DELAY: { code: 'MAV_CMD_CONDITION_DELAY', id: 112, skipCoordinates: true, absoluteFrame: true }, // Delay mission state machine
  DO_CHANGE_SPEED: { code: 'MAV_CMD_DO_CHANGE_SPEED', id: 178, skipCoordinates: true, absoluteFrame: true  }, // Change speed and/or throttle set points. The value persists until it is overridden or there is a mode change
  DO_SET_SERVO: { code: 'MAV_CMD_DO_SET_SERVO', id: 183, skipCoordinates: true, absoluteFrame: true  }, // Set a servo to a desired PWM value
  DO_SET_CAM_TRIGG_DIST: { code: 'MAV_CMD_DO_SET_CAM_TRIGG_DIST', id: 206, skipCoordinates: true, absoluteFrame: true  }, // Control digital camera
  DO_PARACHUTE: { code: 'MAV_CMD_DO_PARACHUTE', id: 208, absoluteFrame: true }, // Mission item/command to release a parachute or enable/disable auto release
};

async function generateMavlinkWaypoints(json, params) {
  console.log('WP1', json)
  console.log('WP2', params)
  const waypointsFeature = json?.features?.find((feature) => feature?.id === FEATURE_PATH);
  const waypoints = waypointsFeature?.geometry?.coordinates || [];
  const pointProps = waypointsFeature?.properties?.PointProps || [];

  const messagesFeature = json?.features?.find((feature) => feature?.id === FEATURE_MESSAGES);
  const messagePoints = messagesFeature?.geometry?.coordinates || [];
  const messages = messagesFeature?.properties?.Messages || [];

    //TODO: aux is deprecated. verify and remove
  const aux = json?.features?.find((feature) => feature?.id === FEATURE_AUX)?.geometry?.coordinates || [];

  let cmdIndex = 0;
  let lastLatitude = 0;
  let lastLongitude = 0;
  let lastAltitude = 0;
  let lastElevation = 0;
  let lastAirspeed = 0;
  let isParachuteLanding = false;
  let takeoffPoint = [0, 0, 0];
  let takeoffPointElevation = 0;
  const res = [];

  const addComment = (text) => {
    res.push({ commandCode: `-- ${text} --` });
  };

  const addCommand = (
    command,
    param1 = 0,
    param2 = 0,
    param3 = 0,
    param4 = 0,
    point = [0, 0, 0],
    props = {}
  ) => {
    let [longitude, latitude, altitude] = point;

    const current = cmdIndex === 0 ? 1 : 0; // First waypoint is 'current'
    const frame = cmdIndex === 0 || command.absoluteFrame ? MAV_FRAME_GLOBAL : MAV_FRAME_GLOBAL_RELATIVE_ALT;
    const autoContinue = command === MAV_CMD.NAV_LAND ? 0 : 1; // Auto-continue to next waypoint
    let lat = latitude;
    let lon = longitude;
    let elevation = props?.GroundAltitudeAMSL;
    let alt = cmdIndex === 0 ? elevation : altitude;
    if (command === MAV_CMD.NAV_TAKEOFF) {
      param1 = params.takeoffPitch;
      alt = params.takeoffAltMin; //TODO: H_AGL_Takeoff
    }
    res.push({
      index: cmdIndex,
      current,
      frame,
      commandID: command.id,
      commandCode: command.code,
      param1,
      param2,
      param3,
      param4,
      latitude: lat.toFixed(PRECISION),
      longitude: lon.toFixed(PRECISION),
      altitude: Math.round(alt),
      autoContinue,
      missionCost: props?.MissionCost 
        ? Number((props?.MissionCost / params.batteryNominalVoltage).toFixed(1)) 
        : '',
      missionLength: props?.MissionLength ? (props.MissionLength / 1000).toFixed(2) : '',
      missionTime: props?.MissionTime
        ? new Date(props.MissionTime * 1000).toISOString().slice(11, 19)
        : '',
      airspeed: props?.AirSpeed?.toFixed(2),
      driftAngle: props?.DriftAngle?.toFixed(2),
      windSpeed: props?.WindSpeed?.toFixed(2),
      groundSpeed: props?.GroundSpeed?.toFixed(2),
      elevation: elevation,
      loiterRadius: Math.round(props?.LoiterRadius),
      distanceFromTakeoff: !command.skipCoordinates ? props.distanceFromTakeoff.toFixed(1) : '',
      bearingToNext: !command.skipCoordinates && props.nextPoint ? props.bearingToNext.toFixed(2) : '',
      gradientToNext: !command.skipCoordinates && props.nextPoint ? props.gradientToNext.toFixed(2) : '',
      distanceToNext: !command.skipCoordinates && props.nextPoint ? props.distanceToNext.toFixed(1) : '',
      altAGL: !command.skipCoordinates ? Math.round(props.altAGL) : '',
      altAMSL: !command.skipCoordinates ? Math.round(props.altAMSL) : '',
    });
    cmdIndex++;
    if (command === MAV_CMD.NAV_WAYPOINT) {
      lastLatitude = lat;
      lastLongitude = lon;
      lastAltitude = alt;
      lastElevation = elevation;
    }
  };

  const checkAddLoiterAltitude = ({ latitude, longitude }, props) => {
    if (props.LoiterAltitude) {
      const centerIdx = props.Center;
      const [centerLongitude, centerLatitude] = aux[centerIdx] || [longitude, latitude];//TODO: VERIFY!! remove deprecated aux, always use lat+lon
      addCommand(
        MAV_CMD.NAV_LOITER_TO_ALT,
        0,
        props.LoiterRadius,
        0,
        0,
        [centerLongitude, centerLatitude, props.LoiterAltitude],
        props
      );
      return true;
    }
    return false;
  };

  const getLiteralValue = (literal, waypoint) => {
    switch (literal) {
      case 'Height AGL (m)': return waypoint.altAGL;
      case 'Height AMSL (m)': return waypoint.altAMSL;
      case 'Latitude': return waypoint.point[0];
      case 'Longitude': return waypoint.point[1];
      case 'Elevation (m)': return waypoint.GroundAltitudeAMSL;
      case 'DistToNextPoint (m)': return waypoint.distanceToNext;
      case 'Airspeed (m/s)': return waypoint.AirSpeed;
      case 'Ground Speed (m/s)': return waypoint.GroundSpeed;
      case 'Ground Course (deg)': return waypoint.bearingToNext;
      case 'Aircraft Wind Angle (deg)': return waypoint.DriftAngle;
      case 'DescAscGradient (%)': return waypoint.gradientToNext;
      case 'Wind speed (m/s)': return waypoint.WindSpeed;
      //case 'Wind Course (m/s)':
      //case 'Camera TriggDistance (m/s)':
      //case 'Camera TriggTime (s)':
      //case 'Camera PWM Neutral':
      //case 'Camera PWM Channel':
      case 'Parachute PWM Channel': return params.parachuteChannel;
      default:
        throw new Error(`Unsupported literal '${literal}'`);
    }
  };
  const evaluateBinaryOperator = (operator, left, right) => {
    switch (operator) {
      case '+': return left + right;
      case '-': return left - right;
      case '*': return left * right;
      case '/': return left / right;
      case '%': return left % right;
      case '==': return left == right;
      case '===': return left === right;
      case '!=': return left != right;
      case '!==': return left !== right;
      case '>': return left > right;
      case '>=': return left >= right;
      case '<': return left < right;
      case '<=': return left <= right;
      case '&&': return left && right; // logical and
      case '||': return left || right; // logical or
      case '&': return left & right; // bitwise and
      case '|' : return left | right; // bitwise or
      case '^': return left ^ right; // bitwise xor
      default:
        throw new Error(`Unsupported operator '${operator}'`);
    }
  };
  const evaluateUnaryOperator = (operator, argument) => {
    switch (operator) {
      case '+': return +argument;
      case '-': return -argument;
      case '!': return !argument;
    }
  };
  const call = (func, args) => {
    if (func.type !== 'Identifier') throw new Error(`Unsupported call expression type '${func.type}'`)

    switch (func.name.toLowerCase()) {
      case 'round': {
        if (args[1]) {
          return Number(args[0].toFixed(args[1]));
        } else {
          return Math.round(args[0]);
        }
      }
      default:
        throw new Error(`Unsupported function '${func.name}'`);
    }
  };
  const evaluate = (expr, waypoint) => {
    switch (expr.type) {
      case 'Literal':
        if (
          (expr.raw.startsWith('"') && expr.raw.endsWith('"')) ||
          (expr.raw.startsWith("'") && expr.raw.endsWith("'"))
        ) {
          return Number(getLiteralValue(expr.value, waypoint));
        } else return Number(expr.value);
      case 'BinaryExpression':
      case 'LogicalExpression':
        return evaluateBinaryOperator(expr.operator, evaluate(expr.left, waypoint), evaluate(expr.right, waypoint));
      case 'UnaryExpression':
        return evaluateUnaryOperator(expr.operator, evaluate(expr.argument, waypoint));
      case 'ConditionalExpression':
        return evaluate(expr.test, waypoint) ? evaluate(expr.consequent, waypoint) : evaluate(expr.alternate, waypoint);
      case 'CallExpression':
        return call(expr.callee, expr.arguments.map(x=>evaluate(x, waypoint)));
      default:
        console.warn(`Unsupported expression type: ${expr.type}`);
        return 0;
    }
  };
  const getParam = (script, num, waypoint) => {
    try {
      const param = script.params.find((x) => x.number === num);
      if (!param) return 0;

      const expr = jsep(param.value);
      return evaluate(expr, waypoint);
    } catch (e) {
      store.commit('debug/addLog', {
        message: `Error parsing script ${script.caption}[parameter ${num}]:`,
        details: e,
        level: 'error',
      });
      throw new Error(`Error parsing script ${script.caption}[parameter ${num}]: ${e.message}`);
    }
  };
  const tryAddScript = (scripts, position, waypoint) => {
    const kind = WaypointKind[waypoint.Kind];
    if (!kind) return;

    const run = scripts.filter((scr) => scr.waypoint === kind && scr.position === position);
    run.forEach((scr) => {
      if (scr.repetition === 'FIRST' && !waypoint.isFirstOfKind) return;
      if (scr.repetition === 'LAST' && !waypoint.isLastOfKind) return;
      if (scr.additionalParams === 'DOWN_WIND' && waypoint.WindSpeed < 0) return;
      if (scr.additionalParams === 'TO_WIND' && waypoint.WindSpeed > 0) return;

      const cmd = MAV_CMD[scr.command];
      if (!cmd) return;

      addCommand(
        cmd,
        getParam(scr, 1, waypoint),
        getParam(scr, 2, waypoint),
        getParam(scr, 3, waypoint),
        getParam(scr, 4, waypoint),
        [getParam(scr, 5, waypoint), getParam(scr, 6, waypoint), getParam(scr, 7, waypoint)],
        waypoint
      );
    });
  };

  const { conn: connection } = window;
  const missionID = params.missionProfile;
  let scripts = await connection.Repository('fp_mission_script')
    .attrs(['ID', 'caption', 'position', 'repetition', 'waypoint', 'command', 'additionalParams'])
    .where('missionID', '=', missionID)
    .selectAsObject();
  let scriptParams = await connection.Repository('fp_script_param')
    .attrs('scriptID', 'number', 'value')
    .where('scriptID.missionID', '=', missionID)
    .selectAsObject();
  scriptParams = _.groupBy(scriptParams, 'scriptID');
  scripts = scripts.map((x) => ({ ...x, params: scriptParams[x.ID] || [] }));

  console.log('Mission scripts', scripts);

  // const targetPoints = []//TODO get from context
  // let tpScripts = await connection.Repository('fp_point_script')
  //   .attrs(['ID', 'pointID', 'caption', 'position', 'repetition', 'command', 'additionalParams'])
  //   .where('pointID', 'in', targetPoints)
  //   .selectAsObject()
  // let tpSciptParams = await connection.Repository('fp_point_script_param')
  //   .attrs('scriptID', 'number', 'value')
  //   .where('scriptID.pointID', 'in', targetPoints)
  //   .selectAsObject()
  // tpScriptParams = _.groupBy(tpSciptParams, 'scriptID');
  // tpScripts = tpScripts.map((x) => ({ ...x, params: tpScriptParams[x.ID] || [] }));
  //
  // console.log('Target point scripts', tpScripts)

  // mark first and last occurrences of every waypointKind
  {
    let firstInstanceOfKind = {};
    let lastInstanceOfKind = {};
    waypoints.forEach((point, index) => {
      const props = pointProps[index];

      if (!firstInstanceOfKind[props.Kind]) {
        firstInstanceOfKind[props.Kind] = true;
        props.isFirstOfKind = true;
      }

      lastInstanceOfKind[props.Kind] = props;
    });
    Object.values(lastInstanceOfKind).forEach((x) => {
      x.isLastOfKind = true;
    });
  }

  waypoints.forEach((point, index) => {
    let [longitude, latitude, altitude] = point;
    const props = pointProps[index];
    props.point = point;
    props.nextWaypoint = pointProps[index + 1];
    props.nextPoint = waypoints[index + 1];

    // evaluate current params
    {
      if (index === 0) {
        takeoffPoint = point;
        takeoffPointElevation = props.GroundAltitudeAMSL;
      }
      const currenPoint = toPoint(point);
      const alt = point[2];
      props.distanceFromTakeoff = distance(toPoint(takeoffPoint), currenPoint);
      props.altAGL = alt + takeoffPointElevation - props.GroundAltitudeAMSL;
      props.altAMSL = alt + takeoffPointElevation;
      if (props.nextPoint) {
        const nextPoint = toPoint(props.nextPoint);
        props.bearingToNext = bearing(currenPoint, nextPoint);
        props.gradientToNext = (props.nextPoint[2] - alt) / (distance(currenPoint, nextPoint) * 1000) * 100;
        props.distanceToNext = distance(currenPoint, nextPoint) * 1000;
      }
    }

    const zone = props.RoiNum !== null ? `[zone ${props.RoiNum + 1}]` : '';
    const line = props.ActionLineNum !== null ? `[line ${props.ActionLineNum + 1}]` : '';
    addComment(`${props.Kind} ${zone} ${line}`);

    if (index === 0 && props.Kind === 'Takeoff') {
      addCommand(MAV_CMD.NAV_WAYPOINT, 0, 0, 0, 0, point, props); // Set HOME
    }
    tryAddScript(scripts, 'BEFORE', props)
    switch (props.Kind) {
      case 'Takeoff':
        addCommand(MAV_CMD.NAV_TAKEOFF, 0, 0, 0, 0, point, props);
        addCommand(MAV_CMD.DO_CHANGE_SPEED, SPEED_TYPE.SPEED_TYPE_AIRSPEED, props.AirSpeed);
        lastAirspeed = props.AirSpeed;
        checkAddLoiterAltitude({ latitude, longitude }, props);
        break;
      case 'PreOvershootZone':
      case 'AftOvershootZone':
      case 'PreOvershootRoute':
      case 'AftOvershootRoute':
      case 'OverExtraZone':
      case 'OverExtraRoute':
      case 'ZoneBegin':
      case 'ZoneEnd':
      case 'RouteBegin':
      case 'RouteEnd':
      case 'BeforeMission':
      case 'MidRoute':
      case 'AfterMission':
      case 'Flare':
      case 'BellyLeveling':
      case 'ActionStartZone':
      case 'ActionStartRoute':
      case 'ActionStopZone':
      case 'ActionStopRoute':
        addCommand(MAV_CMD.NAV_WAYPOINT, 0, 0, 0, 0, point, props);
        break;
      case 'MidMission':
        if (!checkAddLoiterAltitude({ latitude, longitude }, props)) {
          addCommand(MAV_CMD.NAV_WAYPOINT, 0, 0, 0, 0, point, props);
        }
        break;
      case 'Loiter':
        checkAddLoiterAltitude({ latitude, longitude }, props);
        break;
      case 'ParachuteOpen':
        addCommand(MAV_CMD.NAV_WAYPOINT, 0, 0, 0, 0, point, props);
        addCommand(MAV_CMD.DO_CHANGE_SPEED, SPEED_TYPE.SPEED_TYPE_AIRSPEED, 0, 1, 0);
        addCommand(MAV_CMD.CONDITION_DELAY, 1);
        addCommand(MAV_CMD.DO_SET_SERVO, params.parachuteChannel, params.parachuteOpenPwm);
        lastAirspeed = 0;
        isParachuteLanding = true;
        break;
      case 'BellyLand':
        addCommand(MAV_CMD.NAV_LAND, 0, 0, 0, 0, point, props);
        break;
      case 'NoLanding':
        if (props.LoiterAltitude) {
          const centerIdx = props.Center;
          const [centerLongitude, centerLatitude] = aux[centerIdx] || [longitude, latitude];
          addCommand(
            MAV_CMD.NAV_LOITER_UNLIM,
            0,
            0,
            props.LoiterRadius,
            0,
            [centerLongitude, centerLatitude, props.LoiterAltitude],
            props
          );
        } else {
          addCommand(MAV_CMD.NAV_RETURN_TO_LAUNCH);
        }
        lastAirspeed = props.AirSpeed;
        break;
    }
    tryAddScript(scripts, 'AFTER', props)

    if (props.AirSpeed !== lastAirspeed) {
      // addCommand(MAV_CMD.DO_CHANGE_SPEED, SPEED_TYPE.SPEED_TYPE_AIRSPEED, props.AirSpeed);
      lastAirspeed = props.AirSpeed;
    }

    delete props.nextWaypoint
    delete props.point
    delete props.nextPoint
  });

  messages.forEach((message) => {
    const point = message.LocationIndex != null ? messagePoints[message.LocationIndex] : null;
    store.commit('debug/addLog', {
      message: message.Message,
      //details: '',
      level: message.Severity.toLowerCase(),
      coordinates: point
    });

  });
  return res;
}
