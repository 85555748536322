<template>
  <teleport v-if="isVisible" to="body">
    <div class="modalMask">
      <div class="modalWrapper">
        <div
          class="modalContainer"
          :style="
            size === 'xlarge'
              ? 'width: 1150px'
              : size === 'mlarge'
                ? 'width: 420px'
                : size === 'large'
                  ? 'width: 328px'
                  : size === 'xl'
                    ? 'width: 820px'
                    : size === 'small'
                      ? 'width: 240px'
                      : 'width: 240px'
          "
        >
          <div v-if="title" class="modalHeader">
            <img v-if="titleIcon" :src="titleIcon" alt="icon" />
            {{ title }}
          </div>

          <div class="modalBody" :style="size === 'xlarge' ? 'padding: 0' : 'padding: 0 8px'">
            <slot name="body"> Content </slot>
          </div>

          <div v-if="visibleBtns" class="modalFooter">
            <button v-if="visibleCloseBtn" class="modalFooter__cancelBtn" @click="$emit('close')">
              {{ closeText }}
            </button>
            <button v-if="visibleSaveBtn" class="modalFooter__okBtn" @click="$emit('success')">
              {{ okText }}
            </button>
          </div>
          <div v-if="$slots.footer" class="modalFooter">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    titleIcon: {
      type: Element,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    closeText: {
      type: String,
      default: 'Close',
    },
    okText: {
      type: String,
      default: 'Ok',
    },
    size: {
      type: String,
      default: 'small',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    visibleBtns: {
      type: Boolean,
      default: true,
    },
    visibleCloseBtn: {
      type: Boolean,
      default: true,
    },
    visibleSaveBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['keydown:escape'],

  beforeMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e) {
      if (e.code === 'Escape') {
        this.$emit('keydown:escape');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modalMask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  .modalWrapper {
    display: table-cell;
    vertical-align: middle;

    .modalContainer {
      margin: 0 auto;
      background-color: #363636;
      border-radius: 8px;
      font-family: Helvetica, Arial, sans-serif;
    }

    .modalHeader {
      padding: 8px;
      color: #dadae8;
      font-size: 12px;
      font-weight: 700;
      background: #2d2c2c;
      line-height: 14px;
      display: flex;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      img {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }

    .modalBody {
      padding: 0 8px;
    }

    .modalFooter {
      border-top: 1px solid #7d7d91;
      padding: 8px;
      display: flex;
      justify-content: flex-end;

      &__cancelBtn {
        padding: 9px 16px;
        color: #dadae8;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        background: transparent;
        border: none;
        outline: none;
        text-align: center;
        cursor: pointer;
      }

      &__okBtn {
        padding: 9px 16px;
        color: #dadae8;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        text-align: center;
        background: #0670eb;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 4px;
      }
    }
  }
}
</style>