<script setup>
import { useProfilesSelect } from '@/utils/helpers';
import { VReloadIcon, VSelect } from '@/components/ui';
import { useStore } from 'vuex';
const { fieldData, fieldSelectedModel } = useProfilesSelect(
  'missionProfile',
  'missionProfiles',
  'params/setMissionProfile'
);
const store = useStore();
</script>
<template>
  <div class="mission-type-select">
    <VSelect v-model="fieldSelectedModel" class="select" :options="fieldData" />
    <div class="reload">
      <VReloadIcon :refresh="() => store.dispatch('params/getMissionProfileList')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mission-type-select {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  .select {
    width: 88%;
  }
  .reload {
    border: 1px solid #7d7d91;
    border-radius: 8px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    .clickedImg {
      transform: rotate(180deg);
    }

    img {
      cursor: pointer;
      transition: 1s;
    }
  }
  .is-disabled {
    cursor: not-allowed;
  }
}
</style>