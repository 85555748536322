import service from '@/services/service';
import { exportMissionAs, exportMissionAsMany } from '@/lib/mission-exporter';
import { get } from 'lodash';
import {ClientError} from "@/components/error-handler/components/error-handler";

const warningRemoveCurrent =
  'You are trying to remove current mission, Do you still want to continue?';

const initState = {
  missions: [],
  isLoading: false,
  currentSelected: localStorage.getItem('lastMission') || null,
  isModalVisible: false,
  isEditable: true,
  parentSelected: null,
  isSaveVisible: false,
  isClickedNewMission: false,
  isSaveAs: false,
  selectedMissionItemId: null,

  combinerMissionIDs: [],
};
function initialState() {
  return {
    ...initState,
  };
}
// Getter functions
const getters = {
  getMissions(state) {
    return state.missions;
  },
  combinerVisible(state) {
    return state.combinerMissionIDs && !!state.combinerMissionIDs.length;
  },
};
// Actions
const actions = {
  async setCombinerMissionIDs({ state, commit, dispatch }, IDs) {
    commit('startLoad');
    try {
      await Promise.all(
        (IDs || []).map(async (missionID) => dispatch('getMissionByID', missionID))
      );
      commit('setCombinerMissionsSuccess', IDs);
    } catch (e) {
      commit('finishLoad');
    }
  },
  async getMissions(
    { state, commit },
    { sortBy, filterByName = null, searchCondition, paginationOptions }
  ) {
    commit('startLoad');
    try {
      const missions = await service.getMissions({
        sortBy,
        filterByName,
        searchCondition,
        paginationOptions,
      });
      commit('getMissionsSuccess', missions);
    } catch (e) {
      throw new ClientError('Error', 'Impossible to load the mission(s)');
    } finally {
      commit('finishLoad');
    }
  },
  async removeMissionByIDs({ state, commit }, ids) {
    commit('startLoad');
    try {
      const isMissionIdsMatchedWithCurrentSelected = ids.find(
        (id) => Number(id) === Number(state.currentSelected)
      );
      if (isMissionIdsMatchedWithCurrentSelected && !window.confirm(warningRemoveCurrent)) return;

      for (const id of ids) {
        await service.deleteMissions(id);
        commit('removeMissionByIDSuccess', id);
      }
      if (isMissionIdsMatchedWithCurrentSelected) window.location.href = '/';
    } catch (e) {
      throw new ClientError('Error', `Missions: ${ids.join(',')} have not been removed!`);
    } finally {
      commit('finishLoad');
    }
  },
  async removeMissionByID({ state, commit }, id) {
    commit('startLoad');
    try {
      const isMissionIdsMatchedWithCurrentSelected = Number(id) === Number(state.currentSelected);
      // if (isMissionIdsMatchedWithCurrentSelected && !window.confirm(warningRemoveCurrent)) return;
      await service.deleteMissions(id);
      commit('removeMissionByIDSuccess', id);
      if (isMissionIdsMatchedWithCurrentSelected) window.location.href = '/';
    } catch (e) {
      throw new ClientError('Error', `Missions: ${id} have not been removed!`)
    } finally {
      commit('finishLoad');
    }
  },
  async buildAndDownloadMissionsMany({ state, commit }, ids) {
    commit('startLoad');
    try {
      const missionsExportParams = [];

      for (const id of ids) {
        const mission = await service.getMissionIfExist(id);
        const { geoJson, cpp_geoJson: cppFileInfo, additionalGeometry, ...params } = mission;

        const cpp_geoJson = await service.getCppData(id, cppFileInfo);
        missionsExportParams.push({
          params,
          map: {
            cpp_geoJson,
            geoJson,
            additionalGeometry,
          },
          fileName: `${params.name || 'Untitled'}.json`,
        });
      }
      await exportMissionAsMany(missionsExportParams);
    } catch (e) {
      throw new ClientError('Error', `Error during the building missions bundle`);
    } finally {
      commit('finishLoad');
    }
  },
  async buildAndDownloadMission({ state, commit }, id) {
    commit('startLoad');
    try {
      const mission = await service.getMissionIfExist(id);
      const { geoJson, cpp_geoJson: cppFileInfo, additionalGeometry, ...params } = mission;

      const cpp_geoJson = await service.getCppData(id, cppFileInfo);

      await exportMissionAs(
        params,
        {
          cpp_geoJson,
          geoJson,
          additionalGeometry,
        },
        `${params.name || 'Untitled'}.json`
      );
    } catch (e) {
      throw new ClientError('Error', `Error during the building mission with ID: ${id}, details: ${e}`);
    } finally {
      commit('finishLoad');
    }
  },
  async getMissionByID({ state, commit }, id) {
    commit('startLoad');
    try {
      if (
        get(
          state.missions.dataItems?.find((e) => e.ID === id),
          'isFull',
          false
        ) ||
        !id
      )
        return commit('setSelectedMissionItemId', id);
      const missionData = await service.getMissionIfExist(Number(id));
      commit('missionUpdateByIdSuccess', { id, payload: { ...missionData, isFull: true } });
      commit('setSelectedMissionItemId', id);
    } catch (e) {
      commit('finishLoad');
      throw new ClientError('Error', e);
    }
  },
  async copyMissionsMany({ state, commit }, ids) {
    commit('startLoad');
    try {
      const missionCopies = [];
      for (const id of ids) {
        const mission = await service.getMissionIfExist(id);
        const { geoJson, cpp_geoJson: cppFileInfo, additionalGeometry, ...params } = mission;
        const cpp_geoJson = cppFileInfo && (await service.getCppData(id, cppFileInfo));
        const data = {
          cpp_geoJson,
          geoJson,
          additionalGeometry,
          ...params,
          name: `(clone)${mission.name}`,
        };
        delete data.ID;

        const response = await service.saveData(data);
        if (response && response.ID) {
          missionCopies.push({
            ID: response.ID,
            name: data.name,
            ...data,
          });
        }
      }
      commit('createCopySuccess', missionCopies);
    } catch (e) {
      throw new ClientError('Error', `Error during the fetching missions, details: `, e);
    } finally {
      commit('finishLoad');
    }
  },

  async toggleFavorite({ state, commit, dispatch }, id) {
    try {
      commit('startLoad');
      const selectedMission = state.missions.dataItems.find((e) => e.ID === id);
      const isFavorite = selectedMission.isFavorite;
      const mission = await service.updateMissionByID(id, {
        isFavorite: !isFavorite ? 1 : 0,
        mi_modifyDate: selectedMission.mi_modifyDate,
      });

      commit('missionUpdateByIdSuccess', {
        id,
        payload: { ...mission, isFavorite: !!mission.isFavorite },
      });
    } catch (e) {
      commit('finishLoad');
      throw new ClientError('Error', 'Something went wrong during the changing favorite state', e);
    }
  },

  async updateMissionDetailsByID({ state, commit }, { id, payload }) {
    commit('startLoad');
    try {
      // ToDO: Extend such logic when additional fields will be ready;
      const { description, name, mi_modifyDate } = state.missions.dataItems.find(
        (e) => e.ID === id
      );
      const updateWithData = {
        name: payload.name || name,
        description: payload.description || description,
        mi_modifyDate,
      };
      const { mi_modifyDate: updatedModifyDate } = await service.updateMissionByID(
        id,
        updateWithData
      );
      commit('missionUpdateByIdSuccess', {
        id,
        payload: { ...updateWithData, mi_modifyDate: updatedModifyDate },
      });
    } catch (e) {
      commit('finishLoad');
      throw new ClientError('Error', 'Unexpected error during the mission update, details: ', e);
    }
  },
};
// Mutations
const mutations = {
  setCombinerMissionsSuccess(state, IDs) {
    state.combinerMissionIDs = Array.isArray(IDs) && IDs.length ? IDs : [];
    state.isLoading = false;
    state.isModalVisible = false;
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setSelectedMissionItemId(state, id = null) {
    state.selectedMissionItemId = id;
    state.isLoading = false;
  },
  toggleSaveMissionAs(state, isSaveAs) {
    state.isSaveAs = isSaveAs;
  },
  toggleSaveMission(state, isSaveMission) {
    state.isSaveVisible = isSaveMission;
  },
  toggleClickedNewMission(state, isClickedNewMission) {
    state.isClickedNewMission = isClickedNewMission;
  },
  getMissionsSuccess(state, missions) {
    state.missions = missions;
    state.isLoading = false;
  },
  removeMissionByIDSuccess(state, missionId) {
    if (Number(state.currentSelected) === Number(missionId)) {
      state.currentSelected = null;
      localStorage.setItem('lastMission', null);
    }
    state.missions.dataItems = state.missions.dataItems.filter((e) => e.ID !== Number(missionId));
    state.isLoading = false;
  },
  createCopySuccess(state, missions) {
    state.missions.dataItems = (missions || []).concat(state.missions.dataItems);
    state.isLoading = false;
  },
  startLoad(state) {
    state.isLoading = true;
    // state.selectedMissionItemId = null;
  },
  finishLoad(state) {
    state.isLoading = false;
  },
  openModal(state, isEditable = true) {
    state.isModalVisible = true;
    state.isEditable = isEditable;
  },
  closeModal(state) {
    state.isModalVisible = false;
  },
  setCurrentMission(state, missionId) {
    if (!missionId) localStorage.setItem('lastMission', null);
    state.currentSelected = missionId;
  },

  // ToDO: use for creation full file system
  setParent(state, parentId) {
    state.parentSelected = parentId;
  },
  missionUpdateByIdSuccess(state, { id, payload }) {
    state.missions.dataItems = state.missions.dataItems?.map((mission) =>
      mission.ID === id ? { ...mission, ...payload } : mission
    );
    state.isLoading = false;
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
