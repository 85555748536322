<script setup>
import { ReloadResult } from '@/assets/svgs';
import { ref } from 'vue';

const { refresh } = defineProps(['refresh']);

const isClickedReload = ref('');
const handleRefresh = async () => {
  if (isClickedReload.value === '') {
    isClickedReload.value = 'clickedImg';
  } else {
    isClickedReload.value = '';
  }
  refresh();
};
</script>

<template>
  <img :src="ReloadResult" :class="isClickedReload" alt="reload" @click="handleRefresh" />
</template>

<style lang="scss" scoped>
.clickedImg {
  transform: rotate(180deg);
}

img {
  cursor: pointer;
  transition: 1s;
}
</style>