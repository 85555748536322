<template>
  <div v-if="isVisible" class="container">
    <div>
      <div class="container__title">
        <span>Mission Name</span>
        <span>{{ data.name }}</span>
      </div>

      <img v-if="data.previewUrl" :src="data.previewUrl" alt="img" />

      <img v-if="!data.previewUrl" class="notFound" :src="NotImage" alt="not found" />

      <div class="container__title">
        <span>File Modified Time</span>
        <span>{{ moment(data.mi_modifyDate).locale('en-gb').format(TABLE_DATE_FORMAT) }}</span>
      </div>

      <div class="container__title">
        <span>File Created Time</span>
        <span>{{ moment(data.mi_createDate).locale('en-gb').format(TABLE_DATE_FORMAT) }}</span>
      </div>
      <div class="inputs">
        <div class="inputs__title">Description</div>
        <VTextarea
          v-model="form.description"
          :rows="10"
          :disabled="isReadOnly"
          @update:modelValue="updateForm"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VTextarea from '@/components/ui/VTextarea.vue';
import { NotImage } from '@/assets/svgs';
import { debounce } from 'lodash';
import moment from 'moment';

const TABLE_DATE_FORMAT = 'DD.MM.YYYY, LTS';
export default {
  components: { VTextarea },
  props: ['data', 'isVisible', 'close', 'isReadOnly'],
  emits: ['update'],
  data: () => ({
      NotImage,
      moment,
      TABLE_DATE_FORMAT,
      form: {},
  }),
  watch: {
    data: {
      immediate: true,
      handler: function (newVal) {
        Object.keys(newVal).forEach((key) => {
          this.form[key] = newVal[key];
        });
      },
    },
  },
  methods: {
    updateForm: debounce(function () {
      const form = {
        ...this.form,
        description: this.form.description === '' ? ' ' : this.form.description,
      };
      this.$emit('update', { id: this.data.ID, payload: form });
    }, 500),
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 32px 20px;
  width: 340px;
  height: 100%;
  justify-content: space-between;

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    color: #dadae8;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;

    span:first-child {
      margin-bottom: 15px;
    }
    span:last-child {
      margin-left: 10px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  img {
    width: 100%;
    height: 180px;
    margin-bottom: 10px;
    display: block;
  }

  .notFound {
    width: 100%;
    height: 100px;
    margin-bottom: 25px;
    display: block;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    &__title {
      margin-bottom: 15px;
      color: #dadae8;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
  }
}
</style>