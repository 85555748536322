import { v4} from "uuid";

const initState = {
    currentTargetPointCoordinates: null,
    isTargetPointByCoordinateVisible: false,
};

// Getter functions
const getters = {
    targetPointByMapDrawModalVisible(state) {
        return Array.isArray(state.currentTargetPointCoordinates) && state.currentTargetPointCoordinates.every(e => Number(e));
    },
};
// Actions
const actions = {
    saveTargetPoint({ state, commit, rootGetters: getters }, payload) {
        const coordinates = payload.coordinates || state.currentTargetPointCoordinates;
        const pointType = payload.pointType;

        //TODO: refactor, do not use length+1
        const missionItemsLength = (getters['map/getMissionItems']?.features || []).length;
        const originalPosition = missionItemsLength + 1;

        commit('map/saveTargetPoint', {
            type: 'Feature',
            geometry: {
                coordinates,
                type: 'Point'
            },
            uuid: v4(),
            properties: {
               isTargetPoint: true,
                originalPosition,
               pointType
            }
        }, { root: true });
        commit('resetTargetPoint');
    }
};
// Mutations
const mutations = {
    setTargetPointByCoordinatesVisible(state, isVisible) {
      state.isTargetPointByCoordinateVisible = isVisible;
    },
    setTargetPointCoordinates(state, coordinates) {
      state.currentTargetPointCoordinates = coordinates;
    },
    resetTargetPoint(state) {
        state.currentTargetPointCoordinates = null;
        state.isTargetPointByCoordinateVisible = false;
    }
};
export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
