import {
    ChartIcon,
    DebugInfoIcon,
    DebugWarningIcon,
    DebugErrorIcon,
    NoteIcon,
    PenIcon,
    PlainIcon,
    UnionIcon,
    ThreatIcon,
    WeatherIcon,
} from '@/assets/svgs';
import {errorLevels} from "@/store/debug";

const SECTIONS_IDs = {
    MISSION: 'MISSION',
    USER_ITEMS: 'USER_ITEMS',
    KML: 'KML',
    TREAT: 'TREAT',
    GRAPH: 'GRAPH',
    FLIGHT_INFO: 'FLIGHT_INFO',
    WEATHER: 'WEATHER',
    WARNING: 'WARNING'
}
const SECTIONS_DETAILS = {
    [SECTIONS_IDs.MISSION]: () => ({
        title: 'Mission',
        icon: NoteIcon,
        componentAsync: () => import('@/components/tabs/VPolygonsList.vue')
    }),
    [SECTIONS_IDs.USER_ITEMS]: () => ({
        title: 'User items',
        icon: PenIcon,
        componentAsync: () => import('@/components/tabs/VUserItemsList.vue')
    }),
    [SECTIONS_IDs.KML]: () => ({
        title: 'KML',
        icon: UnionIcon,
        componentAsync: () => import('@/components/tabs/VKMLTab.vue')
    }),
    [SECTIONS_IDs.TREAT]: (state, _, rootState, rootGetter) => ({
        title: 'Threat',
        icon: ThreatIcon,
        hidden: !rootState.isThreatModule,
        componentAsync: () => import('@/components/tabs/VThreatTab.vue'),
    }),
    [SECTIONS_IDs.GRAPH]: () => ({
        title: 'Graph',
        icon: ChartIcon,
        componentAsync: () => import('@/components/tabs/VGraphTab.vue')
    }),
    [SECTIONS_IDs.FLIGHT_INFO]: () => ({
        title: 'Info',
        icon: PlainIcon,
        componentAsync: () => import('@/components/tabs/VFlightTab.vue')
    }),
    [SECTIONS_IDs.WEATHER]: () => ({
        title: 'Weather',
        icon: WeatherIcon,
        componentAsync: () => import('@/components/tabs/VWeatherTab.vue')
    }),
    [SECTIONS_IDs.WARNING]: (state, _, rootState, rootGetter) => ({
        title: `Warnings ${rootState.debug.errors.length || ''}`,
        icon: rootGetter['debug/getErrorsGroupedByLevel'][errorLevels.error].length > 0
            ? DebugErrorIcon
            : !rootGetter['debug/getErrorsGroupedByLevel'][errorLevels.error].length
                && rootGetter['debug/getErrorsGroupedByLevel'][errorLevels.info].length > 0
                ? DebugWarningIcon
                : DebugInfoIcon,
        componentAsync: () => import('@/components/tabs/VWarrningTab.vue')
    }),
};
const DEFAULT_ACTIVE = SECTIONS_IDs.MISSION;

export   {
    SECTIONS_IDs,
    SECTIONS_DETAILS,
    DEFAULT_ACTIVE
}