<template>
  <div class="root_container">
    <slot></slot>
  </div>
</template>

<script>
import errorHandler from "@/components/error-handler/components/error-handler";
export default {
  errorCaptured(...errorHandlerArgs) {
    errorHandler(...errorHandlerArgs);
    return false;
  },
};
</script>