import L from 'leaflet';

const _animateZoom = L.GridLayer.prototype._animateZoom;
L.GridLayer.prototype._animateZoom = function (...opt) {
  if (!this._map) return;
  _animateZoom.call(this, ...opt);
};

const _resetView = L.GridLayer.prototype._resetView;
L.GridLayer.prototype._resetView = function (...opt) {
  if (!this._map) return;
  _resetView.call(this, ...opt);
};

L.gridLayer = function (latlng, options) {
  return new L.GridLayer(latlng, options);
};
