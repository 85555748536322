import { useStore } from 'vuex';
import { computed, nextTick, ref, watch } from 'vue';
import { debounce, get } from 'lodash';

// GlobalVue Helpers;

// Use for writing params to JSON mission items; (debounced) -> root_state = state.params; key - value in json; default_or_init - value in state.params[default_or_init]
export function useMappingForSelectedGeoJSONItem(key, default_or_init, customStore = null) {
  const store = useStore();
  const value = ref(null);
  const selectedFeature = computed(() => store.getters['map/getSelectedFeature']);
  const selectedFeatureProperties = computed(() =>
    get(selectedFeature.value, `properties.${key}`, (customStore?.value || store.state.params)[default_or_init])
  );

  watch(
    selectedFeatureProperties,
    (new_value) => {
      value.value = new_value;
    },
    { immediate: true }
  );

  return computed({
    get: () => value.value,
    set: (newValue) => {
      value.value = newValue;
      store.commit('markAsDraft');
      store.commit('map/updateSelectedGeoJsonItemProperties', {
        key,
        value: newValue,
      });
    },
  });
}

/***
 *
 * @param mapKey <Array | String>
 * @returns {*|WritableComputedRef<*>}
 */
export function useMappingStoreParams(mapKey) {
  const store = useStore();

  return Array.isArray(mapKey)
    ? mapKey.reduce((acc, key) => {
        return {
          ...acc,
          [key]: computed({
            get: () => {
              return store.state.params[key];
            },
            set: (value) => {
              store.commit('params/setFormValue', {
                key,
                value,
              });
            },
          }),
        };
      }, {})
    : computed({
        get: () => {
          return store.state.params[mapKey];
        },
        set: (value) => {
          store.commit('params/setFormValue', {
            key: mapKey,
            value,
          });
        },
      });
}

export function useMappingStoreMissionParams(mapKey) {
  const store = useStore();

  const computedProps = mapKey.reduce((acc, { key }) => {
    return {
      ...acc,
      [key]: computed({
        get: () => {
          return store.state.params[key];
        },
        set: (value) => {
          store.commit('params/setFormValue', {
            key,
            value,
          });
        },
      }),
    };
  }, {});

  Object.keys(computedProps).forEach((prop) => {
    const propData = mapKey.find(({ key }) => key === prop);
    watch(computedProps[prop], (newValue, prevVal) => {
      if (_.isEqual(newValue, prevVal)) return;
      store.commit('map/updateMissionFeaturesProperties', {
        key: propData.propName,
        value: newValue,
        type: propData.type,
      });
    });
  });

  return computedProps;
}

export function useProfilesSelect(
  fieldName,
  sourceListName,
  storeSetActionName,
  allowNullByDefault = true
) {
  const store = useStore();
  const list = computed(() => store.state.params[sourceListName]);

  const fieldSelectedModel = computed({
    get: () => {
      return store.state.params[fieldName];
    },
    set: (val) => {
      store.commit('markAsDraft');
      store.commit(storeSetActionName, val);
    },
  });

  const fieldData = computed(() => {
    return list.value.map((e) => ({
      label: e.label,
      value: e.ID,
    }));
  });

  watch(fieldData, () => {
    if (allowNullByDefault) return;

    if (!fieldSelectedModel.value && fieldData.value.length)
      return nextTick(() => (fieldSelectedModel.value = fieldData.value[0].value));
  });

  return {
    fieldData,
    fieldList: list,
    fieldSelectedModel,
  };
}

const missionNamePattern = /[\\/:*?"<>|]/gi;
export const processMissionName = (name = '') => {
  return name?.replaceAll?.(missionNamePattern, '');
};
