import {computed, watch} from "vue";
import {SECTIONS_IDs} from "@/config/sections";

export default function useSelectedPolygonTabBinder (store) {
    const uuidModel = computed(() => store?.state?.map?.selectedPolygon);
    const currentFeature = computed(() => (store?.state?.map?.geoJson?.features || []).find(feature => feature.uuid === uuidModel?.value));
    const isStartFinish = computed(() => ['start', 'finish'].includes(uuidModel.value));
    const activeTab = computed(() => store?.state?.sections?.activeTab);
    const currentFeatureRelatedToTab = computed(() => !currentFeature.value?.properties?.isExcluded || isStartFinish.value ? SECTIONS_IDs.MISSION : SECTIONS_IDs.USER_ITEMS);
    const onShow = () => {
        if((isStartFinish.value || currentFeature.value)  && currentFeatureRelatedToTab?.value !== activeTab.value)
            store?.commit('sections/openSection', currentFeatureRelatedToTab.value)
    }
    watch(currentFeatureRelatedToTab, onShow);
    watch(uuidModel, onShow)
    watch(currentFeature, onShow)
}