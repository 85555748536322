<template>
  <table>
    <thead>
      <tr>
        <th
          v-for="(column, index) in columns"
          :key="index"
          :name="`header_${column.key}`"
          :header="column"
        >
          {{ column }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(entity, index) in entities" :key="`entity.${index}`" @click="onFocus(entity)">
        <td v-if="entity[0] == null" :colspan="columns.length" class="comment">{{ entity[1] }}</td>
        <td v-for="(column, index) in columns" v-else :key="`column.${index}`">
          {{ entity[index] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import L from 'leaflet';
import { get } from 'lodash';

export default {
  name: 'DataTable',
  props: {
    columns: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    entities: {
      type: Array,
      required: true,
    },
  },
  unmounted() {
    this.clearLayerData();
  },
  methods: {
    clearLayerData() {
      if (this.layerGroup) {
        this.layerGroup.removeFrom(window.map);
        this.layerGroup.clearLayers();
        this.lastCoords = [];
      }
    },
    onFocus([order, kin, lat, lon]) {
      if (this.type !== 'Weather') {
        const coords = [lat, lon];

        if (
          !coords.every((e) => Number(e)) ||
          coords.join(',') === (this.lastCoords || []).join(',')
        )
          return this.clearLayerData();

        if (this.layerGroup) this.clearLayerData();

        this.layerGroup = L.layerGroup([]);

        const marker = L.marker(coords);
        this.layerGroup.addLayer(marker);
        const bounds = L.geoJSON(this.layerGroup.toGeoJSON()).getBounds();
        window.map.fitBounds(bounds);
        this.layerGroup.addTo(window.map);
        this.lastCoords = coords;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  thead {
    position: sticky;
    top: 0;
    tr {
      background: #2d2c2c;
      th {
        color: #dadae8;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        position: relative;
        padding: 9px 8px;
        &:last-child:before {
          content: none;
        }
        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 7px;
          width: 1px;
          height: 18px;
          background: #7d7d91;
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
    }
    tr:hover {
      background: #6d6d70;
    }
  }
  tbody {
    td,
    th {
      color: #dadae8;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      text-align: left;
      padding: 5px 8px;
    }
    td.comment {
      color: green;
      text-align: center;
    }
  }
}
</style>