<template>
  <VModal
    :is-visible="isVisible"
    :title="isSaveAs ? 'Save mission as' : 'Saving mission'"
    :title-icon="SaveIcon"
    :ok-text="isSaveAs ? 'Save' : 'Yes'"
    :close-text="isSaveAs ? 'Cancel' : 'No'"
    @close="handleClose"
    @success="saveMission"
    @keydown:escape="handlePressEscape"
  >
    <template #body>
      <VInput v-if="isSaveAs" v-model="missionName" class="text" :formatter="formatter" />
      <div v-else class="text">Do you want to save the current mission?</div>
    </template>
  </VModal>
</template>

<script>
import { defineComponent } from 'vue';
import { VModal } from '@/components/ui';
import { SaveIcon } from '@/assets/svgs';
import VInput from '@/components/ui/VInput';
import { processMissionName } from '@/utils/helpers';

export default defineComponent({
  components: { VInput, VModal },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    close: {
      type: Function,
      default: null,
    },
    success: {
      type: Function,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    isSaveAs: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    missionName: '',
  }),
  computed: {
    SaveIcon() {
      return SaveIcon;
    },
  },
  watch: {
    '$store.state.params.name'(name) {
      this.missionName = name || '';
    },
  },
  methods: {
    parser(value) {
      return value;
    },
    formatter(value) {
      return processMissionName(value);
    },
    saveMission() {
      if (window?.saveMissionResolver) {
        return window?.saveMissionResolver?.(true);
      }
      const missionName = processMissionName(this.missionName)?.trim();
      if (this.isSaveAs && !!missionName) {
        this.$emit('save', missionName);
      } else if (!this.isSaveAs) {
        this.$emit('save');
      }
    },
    handlePressEscape() {
      this.$emit('close');
      window?.saveMissionResolver?.();
    },
    handleClose() {
      this.$emit('close');
      window?.saveMissionResolver?.(false);
    },
  },
});
</script>

<style lang="scss" scoped>
.text {
  color: #dadae8;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 12px 0 16px 0;
}
</style>