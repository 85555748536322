import { saveAs } from 'save-as';
import JSZip from 'jszip';

export async function exportMissionAs(params, map) {
  const fileNameBase = params?.name || 'mission';
  const data = Object.assign({}, params, map);
  try {
    const zip = new JSZip();
    const json = JSON.stringify(data);
    zip.file(`${fileNameBase}.json`, json);
    const content = await zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: { level: 9 },
    });
    saveAs(content, `${fileNameBase}.mission`);
  } catch (e) {
    console.error('Unexpected error during the exports, details: ', e);
  }
}

export async function exportMissionAsMany(jsonFiles = [], rootDirFileName = 'missionsBundle.zip') {
  const zip = new JSZip();
  jsonFiles.forEach(({ params, map, fileName }, index) => {
    const data = Object.assign({}, params, map);
    try {
      const json = JSON.stringify(data);
      zip.file(`${index + 1}_${fileName}`, json);
    } catch (e) {
      console.error('Unexpected error during the exports, details: ', e);
    }
  });
  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, rootDirFileName);
}

export async function importMission(missionBlob) {
  try {
    const zip = new JSZip();
    let content = await zip.loadAsync(missionBlob);
    content = await content.file(Object.keys(content.files)[0]).async('string');
    return JSON.parse(content);
  } catch (e) {
    console.error('Unexpected error during the import details: ', e);
  }
}
