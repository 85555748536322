import service from '@/services/service';
import { convertHeightsRespToChartData, getGeoJsonMeasurements } from '@/utils/mapUtils';
import { GEO_JSON_IDS as IDS_CONST } from '@/config/map-config';

const initState = {
  heights: [],
  heightConstValue: 0,
};
function initialState() {
  return {
    ...initState,
  };
}
// Getter functions
const getters = {
  getHeights(state) {
    return state.heights;
  },
};
// Actions
const actions = {
  async getHeights({ state, commit }, { path, step }) {
    const feature = path?.features && path?.features[0];
    const coords = feature?.geometry?.coordinates || [];

    const heights = await service.getHeights(coords, step);
    const heightsConstant = (await service.getHeightConstant(coords[0])) ?? 0;
    commit('initHeightsWithValues', heights);
    commit('initConstHeightValue', heightsConstant);
  },
};
// Mutations
const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  initHeightsWithValues(state, data = []) {
    state.heights = data;
  },
  initConstHeightValue(state, data = 0) {
    state.heightConstValue = data;
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
