import {
  DEFAULT_POLYGON_STYLES,
  FOOTPRINT_STYLES,
    DEFAULT_CUSTOM_POLYGONS_STYLES,
  GEO_JSON_IDS as IDS_CONST,
  PATH_STYLES,
} from '@/config/map-config';

export const styleNamespace = {
  title: 'title',
  description: 'description',
  markerSize: 'marker-size',
  markerColor: 'marker-color',
  stroke: 'stroke',
  strokeOpacity: 'stroke-opacity',
  strokeWidth: 'stroke-width',
  fillOpacity: 'fill-opacity',
  opacity: 'stroke-opacity',
  color: 'stroke',
  name: 'name',
  icon: 'iconUrl',
};

export const stylesConfig = {
  [IDS_CONST.path]: PATH_STYLES,
  [IDS_CONST.multi_footprint]: FOOTPRINT_STYLES,
  [IDS_CONST.custom]:  DEFAULT_POLYGON_STYLES,
  [IDS_CONST.default]:DEFAULT_CUSTOM_POLYGONS_STYLES,
};

export const defaultGeoJsonItemDescription = 'Generated by "Flight Creator"@2024';
