<template>
  <div class="tabs">
    <div v-for="tab in values" :key="tab.title" class="tab" @click="selectTab(tab)">
      <VTab
        :key="tab.id"
        :icon="tab.icon"
        :is-selected="activeTab === tab.id"
        :title="tab.title"
      />
    </div>
  </div>
</template>

<script>
import VTab from '@/components/ui/VTab.vue';

export default {
  name: 'VTabs',
  components: { VTab },
  props: {
    values: {
      type: Array,
      default: null,
    },
    activeTab: {
      type: String,
      default: '',
    },
  },
  methods: {
    selectTab(tab) {
      this.$emit('change', tab.id);
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>