<script setup>
import RootMap from '@/components/FlightPlannerMap';
import {computed, onBeforeMount, onErrorCaptured, onMounted, onUnmounted, watch} from 'vue';
import service from '@/services/service';
import { useStore } from 'vuex';
import VLayout from '@/components/layout';
const store = useStore();
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import useUnloadDraftDialog from '@/hooks/useUnloadDraftDialog';
import errorHandler from "@/components/error-handler/components/error-handler";
/*const buffFunction = L.Marker.prototype._animateZoom;
toDo: https://stackoverflow.com/questions/73542576/leaflet-error-when-zooming-after-closing-popup
L.Marker.prototype._animateZoom = function (...args) {
  if (!this._map) {
    return
  }
  buffFunction.call(this, [e])
}*/
const keyupHandler = (evt) => {
  if (evt.key === 'Escape') {
    store.commit('map/showItem', null);
  }
};
const router = useRouter();
const route = useRoute();

const initMission = async (mission) => {
  await store.dispatch('initStore', mission);
  store.commit('missions/setCurrentMission', Number(mission));
};

console.warn = () => ({});
watch(
  () => route.query.mission,
  (newMission, oldMission) => {
    if (window.conn && newMission !== oldMission && newMission !== 'new') {
      initMission(newMission);
    }
  }
);

// eslint-disable-next-line
let unwatch = () => {}

onMounted(async () => {
  document.addEventListener('keyup', keyupHandler);
  onErrorCaptured(errorHandler);

  unwatch = store.watch(
    (state) => state.params.plain,
    async (newVal) => store.dispatch('threat/getThreatsByUav', newVal)
  )

  await router.isReady();

  // Processing input params;
  const mission = route.query['mission'];

  // if (mission) {
  //   router.push({ path: '/', query: { mission } });
  // }

  // ========= Fetching initial data here ===========
  // Making connection with UB back-end part; =======
  await service.connect();

  // Setting server version
  store.commit('setServerVersion', window.conn?.appConfig?.appVersion);

  store.commit('setThreatModule', Boolean(window.conn.domain.models['threat']));

  // Initializing store & preparing data to pushing into a store
  await initMission(mission);


  // Getting basic cameras list & plains list;
  await store.dispatch('params/fetchProfiles');

  await store.dispatch('threat/getThreats');

  // Draft events;
  useUnloadDraftDialog(store);
});

onUnmounted(() => {
  unwatch()
  document.removeEventListener('keyup', keyupHandler);
});
</script>
<template>
  <div class="root_container">
    <VLayout />
    <!--    <RootMap />-->
  </div>
</template>
<style lang="scss" src="./styles/common.scss"></style>
