import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { KmzBuilder } from '@/lib/kmz-builder';
import { GEO_JSON_IDS as IDS_CONST } from '@/config/map-config';
import useFileList from '@/hooks/useFileList';
import {SECTIONS_IDs} from "@/config/sections";

// ToDO: REFACTORRRR!!!!!!!!!!!!!!!!!!!!!!!!
export function useImport(kmzFileContent, importType) {
  const store = useStore();
  const importedGeoJSONs = ref([]);

  const blackListUUID = ref([]);
  const blackListIDs = ref([]);
  const currentFileName = ref('Untitled.kml');

  watch(kmzFileContent, async () => {
    if (!kmzFileContent.value) {
      store.commit('exports/resetImports');
      return store.commit('params/setImportProcess', false)
    }
    const geoJSONsCollections = (
      (await Promise.all(kmzFileContent.value.map((file) => KmzBuilder.kmzToGeoJSONs(file)))) || []
    ).flat(2);
    
    if(importType.value === 'THREATS') {
      return store.dispatch('threat/processImportedThreats', geoJSONsCollections)
    }
    importedGeoJSONs.value = [
      geoJSONsCollections.reduce(
        (acc, item) => {
          acc.features = acc.features.concat(
            item.features.map((e) => ({
              ...e,
              properties: {
                ...(e?.properties || {}),
                sourceFile: `${e?.properties?.sourceFile} / ${e?.properties?.folderId}`,
              },
            }))
          );
          return acc;
        },
        { ...geoJSONsCollections[0], features: [] }
      ),
    ];

    currentFileName.value = kmzFileContent.value.name;
    importKMZ(false);
  });

  const { rows, list } = useFileList(['rows', 'list'], {
    blackListConfiguration: {
      uuidModel: blackListUUID,
      idModel: blackListIDs,
    },
    isImport: true,
    removeExcluded: false,
  });

  function importKMZ(withSaving = false) {
    let data = withSaving
      ? ([store.state.exports.importsData._default] || [])
          .filter((e) => e)
          ?.filter((e) => !blackListIDs?.value?.includes(e.id))
          ?.map((e) => ({
            ...e,
            features: e.features?.filter(
              (e) => !blackListIDs.value?.includes(e.id) && !blackListUUID.value?.includes(e.uuid)
            ),
          }))
      : importedGeoJSONs.value;
    const geoJSONs = data || [];

    if (withSaving && data.length)
      (kmzFileContent.value || []).forEach(({ name }) => {
        const features = data.reduce((acc, item) => acc.concat(item.features), []);
        const featuresRelatedToCurrentFile = features?.filter(
          (e) => `${`${e?.properties?.sourceFile}`.split('/')[0]}`.trim() === name
        );
        if (featuresRelatedToCurrentFile.length) {
          store.commit('map/setImportedLayer', {
            type: 'FeaturesCollection',
            fileName: name,
            features: featuresRelatedToCurrentFile,
          });
        }
      });

    const geoJSON = geoJSONs.find((e) => e.id === IDS_CONST.default);

    if (geoJSON) {
      if (!withSaving) store.commit('exports/setImportsDefault', geoJSON);
    }
    if (withSaving) {
      importedGeoJSONs.value = [];
      store.commit('sections/openSection', SECTIONS_IDs.KML);
      return store.commit('exports/resetImports');
    }
  }

  return {
    rows,
    list,
    importKMZ,
  };
}
