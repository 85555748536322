<template>
  <div>
    <label v-if="labelText" :for="name">{{ labelText }}</label>
    <el-input
      class="v-textarea"
      :name="name"
      :model-value="modelValue"
      :parser="parser"
      type="textarea"
      :rows="rows"
      :formatter="formatter"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="(value) => $emit('update:modelValue', value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    rows: {
      type: String,
      default: '4',
    },
    labelText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    parser: {
      type: Function,
      default: undefined,
    },
    formatter: {
      type: Function,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-textarea {
  --el-fill-color-blank: #2d2c2c;
  --el-component-size: 24px;
  --el-font-size-base: 12px;
  --el-border-color: #7d7d91;
  --el-text-color-regular: #dadae8;
}
</style>