<template>
  <VModal
      :is-visible="isVisible"
      title="Set target point"
      size="small"
      :title-icon="LocationIcon"
      close-text="Close"
      ok-text="Apply"
      @close="onCloseClick()"
      @success="onSuccessClick()"
  >
    <template #body>
      <VSelect
          v-model="pointType"
          :options="options"
          class="target-point-select"
          placeholder="Select target point type"
          :search-option="true"
      />
    </template>
  </VModal>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex'
import { VModal } from '@/components/ui';
import VSelect from '@/components/ui/VSelect.vue';
import {LocationIcon} from  '@/assets/svgs';
export default defineComponent({
  components: { VSelect, VModal },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },

  },
  computed: {
    ...mapState({
      options: state => state.params.pointsProfiles.map(profile => ({ value: profile.ID, label: profile.label }))
    })
  },
  methods: {
    onSuccessClick() {
      if(this.pointType) {
        this.$store.dispatch('targetPoints/saveTargetPoint', { pointType: this.pointType });
      }
    },
    onCloseClick() {
      this.$store.commit('targetPoints/resetTargetPoint');
    }
  },
  data() {
    return {
      LocationIcon,
      pointType: null
    }
  }
})
</script>
<style scoped>
.target-point-select {
  margin-top: 15px;
  margin-bottom: 20px;
}
</style>