import '@geoman-io/leaflet-geoman-free/';

const { _handleSnapLayerRemoval: _handleSnapLayerRemovalMarker } = L.PM.Edit.Marker.prototype;
L.PM.Edit.Marker = L.PM.Edit.Marker.extend({
  _handleSnapLayerRemoval(e) {
    if (!this._snapList) return;

    _handleSnapLayerRemovalMarker.call(this, e);
  },
});
