import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { GEO_JSON_IDS as IDS } from '../../config/map-config';
import { get, set, clone } from 'lodash';
import { getFormattedFeatureType } from '@/utils/mapUtils';
const TAKEOFF_FOR_MISSION_KEY_NAME = 'useTakeoffFromMission';
const LANDING_FOR_MISSION_KEY_NAME = 'useLandingFromMission';
/**
 *
 * @param selectedMissionIds: ComputedRef<[]>;
 * @returns {{computedDataList: ComputedRef<unknown[]>, onImportSelected: onImportSelected, groupedData: (*&{children: *, term: *})[][], isVisible: boolean, missionsList: ComputedRef<*[]>}}
 */
export default function useMissionBundle(selectedMissionIds) {
  const store = useStore();
  const missionsList = computed(() =>
    store.state.missions.missions.dataItems?.filter((e) =>
      (selectedMissionIds?.value || []).includes(e.ID)
    )
  );
  const filter = ref({});
  const items = ref([]);

  // Mutation logics block;
  const toggleNameChange = (mission_id, feature_uuid) => (name) => {
    const missionIndex = items.value.findIndex((e) => e.ID === mission_id);
    const featureIndex = items.value[missionIndex]?.geoJson?.features?.findIndex(
      (e) => e.uuid === feature_uuid
    );
    const clonedItems = clone(items.value[missionIndex]);
    set(clonedItems, `geoJson.features.${featureIndex}.properties.name`, name);
    items.value = items.value.map((_, i) => (i === missionIndex ? clonedItems : _));
  };
  const attachCheckboxModel = (feature, mission_id) => {
    return Object.assign(feature, {
      isChecked: get(filter.value, `${mission_id}.${feature.uuid}`, false),
      onExcludeToggle: () =>
        set(
          filter.value,
          `${mission_id}.${feature.uuid}`,
          !get(filter.value, `${mission_id}.${feature.uuid}`, false)
        ),
      value: get(feature, 'properties.name', 'Untitled'),
      title: getFormattedFeatureType(feature),
      onNameChange: toggleNameChange(mission_id, feature.uuid),
    });
  };
  const attachGroupCheckboxModel = (item) => {
    set(
      item,
      'customItems.isChecked',
      item.customItems.value.every((e) => e.isChecked)
    );
    const toggleGroup = (group, isExclude) =>
      group.value.map((e) => set(filter.value, `${group.mission_id}.${e.uuid}`, isExclude));

    item.customItems.onExcludeToggle = () =>
      toggleGroup(item.customItems, !item.customItems.isChecked);

    const startPoint = item?.startPoint;
    const endPoint = item?.endPoint;
    const startPointExist = startPoint?.value && startPoint?.value.every((e) => Number(e));
    const endPointExist = endPoint?.value && endPoint?.value.every((e) => Number(e));

    const startChecked =
      startPointExist && filter.value[TAKEOFF_FOR_MISSION_KEY_NAME] === startPoint?.mission_id;
    const endChecked =
      endPointExist && filter.value[LANDING_FOR_MISSION_KEY_NAME] === endPoint?.mission_id;

    set(
      item,
      'mainItems.isChecked',
      item.mainItems.value.every((e) => e.isChecked) && startChecked && endChecked
    );

    item.mainItems.onExcludeToggle = () => {
      toggleGroup(item.mainItems, !item.mainItems.isChecked);
      if (startPointExist)
        set(
          filter.value,
          TAKEOFF_FOR_MISSION_KEY_NAME,
          !startChecked ? startPoint?.mission_id : null
        );
      if (endPointExist)
        set(filter.value, LANDING_FOR_MISSION_KEY_NAME, !endChecked ? endPoint?.mission_id : null);
    };
    return item;
  };
  const groupAll = (missionComputed) => {
    const formattedObject = {};
    const keys = Object.keys(missionComputed);
    for (const key of keys) {
      if (['startPoint', 'endPoint'].includes(key) && missionComputed[key].value.every((e) => e)) {
        formattedObject.mainItems.value.push({
          ...missionComputed[key],
          value: missionComputed[key].value.map((e) => Number(e).toFixed(4)).join(' , '),
          type: key,
          disableValue: true,
          properties: {
            coordinates: missionComputed[key].value,
          },
        });
      } else formattedObject[key] = missionComputed[key];
    }

    return Object.keys(formattedObject)
      .map((key) => ({
        ...missionComputed[key],
        term: missionComputed[key].title,
        open: true,
        children: missionComputed[key].value,
      }))
      .filter((e) => e.children && e.children.every((e) => e));
  };

  // Data compute logics block;
  const buildItems = computed(() => {
    return (
      items.value
        // Stage #1 Group children data;
        ?.map(
          ({
            ID,
            geoJson,
            name,
            longitude_start,
            latitude_start,
            latitude_end,
            longitude_end,
            previewUrl,
          }) => ({
            customItems: {
              title: `User Items`,
              mission_id: ID,
              previewUrl,
              mission_name: name,
              open: true,
              value: !geoJson
                ? []
                : geoJson.features
                    .filter((e) => e?.properties?.isExcluded)
                    .map((feature) => attachCheckboxModel(feature, ID)),
            },
            mainItems: {
              title: `Mission Items`,
              mission_id: ID,
              previewUrl,
              mission_name: name,
              open: true,
              value: !geoJson
                ? []
                : geoJson.features
                    .filter((e) => !e?.properties?.isExcluded)
                    .map((feature) => attachCheckboxModel(feature, ID)),
            },
            startPoint: {
              title: 'Take Off',
              mission_id: ID,
              previewUrl,
              mission_name: name,
              value: [latitude_start, longitude_start],
              isChecked: get(filter.value, TAKEOFF_FOR_MISSION_KEY_NAME, null) === ID,
              onExcludeToggle: () =>
                set(
                  filter.value,
                  TAKEOFF_FOR_MISSION_KEY_NAME,
                  get(filter.value, TAKEOFF_FOR_MISSION_KEY_NAME, null) === ID ? null : ID
                ),
            },
            endPoint: {
              title: 'Landing',
              mission_id: ID,
              previewUrl,
              mission_name: name,
              value: [latitude_end, longitude_end],
              isChecked: get(filter.value, LANDING_FOR_MISSION_KEY_NAME, null) === ID,
              onExcludeToggle: () =>
                set(
                  filter.value,
                  LANDING_FOR_MISSION_KEY_NAME,
                  get(filter.value, LANDING_FOR_MISSION_KEY_NAME, null) === ID ? null : ID
                ),
            },
          })
        )
        // Stage #2 Group root data;
        .map(attachGroupCheckboxModel)
    );
  });
  const groupedData = computed(() =>
    buildItems.value
      .map(groupAll)
      .flat(1)
      .filter((e) => e?.children?.length)
  );

  // Make subscription to update;
  watch(
    missionsList,
    () =>
      (items.value = missionsList.value?.map(({ geoJson, ...otherProps }) => ({
        ...otherProps,
        geoJson: JSON.parse(geoJson || 'null'),
      })))
  );

  // Import all mission components regarding to filters;
  const onImportSelected = () => {
    const takeoffSourceMission = items.value?.find(
      (e) => e.ID === filter.value[TAKEOFF_FOR_MISSION_KEY_NAME]
    );
    const landingSourceMission = items.value?.find(
      (e) => e.ID === filter.value[LANDING_FOR_MISSION_KEY_NAME]
    );

    const geoJsonFeatures = items.value
      ?.map(({ geoJson, ID }) => {
        if (!geoJson) return [];
        else
          return geoJson?.features?.filter(({ uuid }) => get(filter.value, `${ID}.${uuid}`, null));
      })
      .flat(1);

    store.commit('map/addCombinedGeoItems', geoJsonFeatures);
    if (!takeoffSourceMission && !landingSourceMission && !geoJsonFeatures.length)
      return alert('Impossible to import! No one items selected!');

    if (takeoffSourceMission) {
      const { latitude_start, longitude_start } = takeoffSourceMission;
      store.commit('params/setStart', [latitude_start, longitude_start]);
    }
    if (landingSourceMission) {
      const { latitude_end, longitude_end } = landingSourceMission;
      store.commit('params/setFinish', [latitude_end, longitude_end]);
    }

    items.value = [];
    return true;
  };

  /***
   *  @missionList: <Array>: Original array of selected missions;
   *  @computeDataList: <Array>: Computed Data without Grouping;
   *  @groupedData: <Array> Computed Data ready to use in Accordion component;
   *  @isVisible: <Boolean> Value showing in Modal Visible;
   *  @onImportSelected: <Function> use all option and do import;
   */
  return {
    missionsList,
    computedDataList: buildItems,
    groupedData,
    isVisible: !!missionsList.value?.length,
    onImportSelected,
  };
}
