<script setup>
import VPhotogrametryParams from '@/components/params/components/VPhotogrametryParams';
import VSimpleFlightParams from '@/components/params/components/VSimpleFlightParams';

import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const isSimpleFlight = computed(() => true); //store.state.params.isSimpleFlight);
</script>

<template>
  <div>
    <VPhotogrametryParams v-if="!isSimpleFlight" />
    <VSimpleFlightParams v-else />
  </div>
</template>

<style lang="scss" scoped></style>