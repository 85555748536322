<script setup>
import VImportModal from '@/components/modals/import/components/VImportModal';
import { get } from 'lodash';

import { computed, watch } from 'vue';
const { onImportFilesUploaded, importData } = defineProps(['onImportFilesUploaded', 'importData']);

const list = computed(() => {
  return importData?.list?.value;
});
</script>

<template>
  <span>
    <VImportModal
      :is-visible="list.length"
      :data="list"
      @success="importData.onSuccess()"
      @close="importData.onClose()"
    />
    <input
      id="importFightMission"
      style="display: none"
      multiple
      type="file"
      accept=".kmz,.kml"
      @change="onImportFilesUploaded"
    />
    <input
      id="importThreats"
      style="display: none"
      multiple
      type="file"
      accept=".kmz,.kml"
      @change="(e) => onImportFilesUploaded(e, 'THREATS')"
    />
    <input
      id="importFightMissionFromJSON"
      style="display: none"
      type="file"
      accept=".mission"
      @change="$emit('missionImportAll', $event)"
    />
  </span>
</template>

<style scoped></style>