import { useStore } from 'vuex';
import useExport from '@/hooks/useExport';
import { KmzBuilder } from '@/lib/kmz-builder';
import { computed, nextTick, ref, watch } from 'vue';
import { useImport } from '@/hooks/useImport';
import useMissionExporter from '@/hooks/useMissionExporter';
import saveAs from 'save-as/src/lib';
import { takeSnapOfMap } from '@/utils/mapUtils';
import { errorLevels } from '@/store/debug';

//ToDO::
/** @useOptimizeMode (Boolean): exclude additional data compute in case when that`s does not required;
 * **/
export default function useGlobalEvents({ useOptimizeMode } = { useOptimizeMode: false }) {
  const store = useStore();

  const fileImported = ref();
  const importType = ref();
  const { importKMZ, rows: importKMLsList } = useImport(fileImported, importType);
  const { setVisibleExportModal, onExportClickSuccess, ...data } = useExport(
    useOptimizeMode ? [] : ['rows', 'list', 'setVisibleExportModal'],
    true
  );

  const isExportEmpty = computed(() => !data?.list?.value?.length);

  const isThreatModule = computed(() => {
    return store.state.isThreatModule;
  });

  const { onDataImport: importAllMissionFromFile, onDataExport: exportAllMissionToFile } =
    useMissionExporter();

  const isMissionAlreadyExisting = computed(() => store.state.params.ID);
  const isSaveVisible = computed(() => store.state.missions.isSaveVisible);
  const isSaveAs = computed(() => store.state.missions.isSaveAs);
  const isClickedNewMission = computed(() => store.state.missions.isClickedNewMission);

  const onImportClick = () => {
    const importElement = document.getElementById('importFightMission');
    importElement.click();
  };

  const onImportThreatsClick = () => {
    const importElement = document.getElementById('importThreats');
    importElement.click();
  };

  const onImportFilesUploaded = (
    {
      target: {
        files: [...kmz],
      },
    },
    type
  ) => {
    importType.value = type;
    fileImported.value = kmz;
    document.getElementById('importFightMission').value = '';
    store.commit('params/setImportProcess', true);
  };
  const onExportClick = async () => {
    setVisibleExportModal(true);
  };

  const onSaveClick = async (missionName) => {
    // Focussing map on mission bounds;
    // if (window.lastTrackedBounds) window.map.fitBounds(window.lastTrackedBounds);

    // Starting load the mission;
    store.commit('setFetching', true);

    // Getting preview of mission;
    const previewUrl = await takeSnapOfMap(window.map);

    // Saving mission with additional fields;
    await store.dispatch('save', { missionName, previewUrl });

    store.commit('missions/toggleSaveMission', false);
    store.commit('missions/toggleSaveMissionAs', false);
  };

  const onSaveAsClick = async () => {
    await exportAllMissionToFile();
  };

  const onMissionImportClick = () => {
    setTimeout(() => document.getElementById('importFightMissionFromJSON').click(), 1000);
  };

  const importMissionDataFromFiles = ({ target }) => {
    const jsonFile = target.files[0];
    target.value = '';
    importAllMissionFromFile(jsonFile);
  };

  const draftMarkDialogBeforeAction = async (cb) => {
    const isDraft = store.state.isDraft;

    if (!isDraft) return cb();

    try {
      store.commit('missions/toggleSaveMissionAs', false);
      store.commit('missions/toggleSaveMission', true);
      const response = await new Promise((res) => {
        window.saveMissionResolver = res;
      });
      window.saveMissionResolver = null;
      const existingMission = Boolean(store.state.params.ID);
      if (response) {
        // save here
        store.commit('setResetOnSave', true);
        window.customEventAfterSave = cb;
        if (existingMission) {
          await onSaveClick();
        } else {
          onSaveAsMangerClick();
        }
      } else if (response === false) {
        cb();
      } else {
        //do nothing
      }
    } catch (error) {
    } finally {
      window.saveMissionResolver = null;
    }
  };
  const onCreateNewClick = async () => {
    await draftMarkDialogBeforeAction(() => store.dispatch('resetAll'));
  };

  const onOpenExistingClick = () => {
    store.commit('missions/openModal', false);
  };

  const onOpenFileManagerClick = () => {
    store.commit('missions/openModal', true);
  };
  const onLogoutClick = () => {
    const getLine = (x) =>
      `${x.index}\t${x.current}\t${x.frame}\t${x.commandID}\t${x.param1}\t${x.param2}\t${x.param3}\t${x.param4}\t${x.latitude}\t${x.longitude}\t${x.altitude}\t${x.autoContinue}`;
    const FILE_HEADER = 'QGC WPL 110';
    const waypoints = store.state.params.waypoints.filter((x) => x.index != null);
    const waypointLines = waypoints.map(getLine);
    const res = [FILE_HEADER, ...waypointLines].join('\n');
    saveAs(
      new Blob([res], { type: 'text/plain' }),
      `${store.state.params.name || 'mission'}.waypoints`
    );
  };

  const isCurrentGeoLocationSetting = computed(
    () => store.state.currentGeolocation.isSettingCurrentGeoLocation
  );

  const isThreatByCoord = computed(() => store.state.threat.isThreatByCoord);
  const editingThreat = computed(() => store.state.threat.editingThreat);

  const currentThreatCoordinates = computed(() => store.state.threat.currentThreatCoordinates);

  const currentDangerSectorCoordinates = computed(
    () => store.state.threat.currentDangerSectorCoordinates
  );

  const importProperties = computed(() => ({
    list: importKMLsList,
    onClose: () => {
      fileImported.value = null;
      importType.value = null;
    },
    onSuccess: () => {
      importKMZ(true);
      fileImported.value = null;
      importType.value = null;
    },
  }));

  const onSetCurrentGeoLocationByCoords = () =>
    store.commit('currentGeolocation/setCurrentGeoLocationByCoords');

  const onSetThreatByCoords = (visible) => {
    store.commit('threat/setThreatByCoords', visible);
  };

  const setCurrentThreatCoordinates = (visible) => {
    store.commit('threat/setCurrentThreatCoordinates', visible);
  };

  const setCurrentDangerSectorCoordinates = (visible) => {
    store.commit('threat/setCurrentThreatDangerSectorCoordinates', visible);
  };

  const onSaveCurrentGeoLocation = (event) =>
    event && store.dispatch('currentGeolocation/setGeoLocation', [event.lat, event.lon]);

  const onSaveThreatLocation = (event) =>
    event && store.dispatch('threat/setThreadByCoordinates', event);

  const onRevertSettingCurrentGeoLocation = () =>
    store.commit('currentGeolocation/stopSettingCurrentGeoLocationByCoords');

  const onSetTakeOffByCurrentGeoLocation = () =>
    store.dispatch('currentGeolocation/useGeoLocationAsStartEnd', true);

  const onSetLandingByCurrentGeoLocation = () =>
    store.dispatch('currentGeolocation/useGeoLocationAsStartEnd', false);

  const onSaveAsMangerClick = () => {
    store.commit('missions/toggleSaveMission', true);
    store.commit('missions/toggleSaveMissionAs', true);
  };

  const onSaveManagerClick = (isFastSave = false) => {
    if (!isMissionAlreadyExisting.value) {
      return onSaveAsMangerClick();
    }
    if (isFastSave) {
      return onSaveClick(null);
    }
    store.commit('missions/toggleSaveMission', true);
    store.commit('missions/toggleSaveMissionAs', false);
  };

  const onSaveClose = () => {
    if (isClickedNewMission.value) {
      localStorage.removeItem('lastMission');
      localStorage.setItem('lastZoomValue', JSON.stringify(window.map.getZoom()));
      localStorage.setItem(
        'lastCoordinatesViewValue',
        JSON.stringify(window.map.getBounds().getCenter())
      );
      window.location = '/';
    }
    store.commit('missions/toggleSaveMission', false);
    store.commit('missions/toggleSaveMissionAs', false);
  };

  const isLogoutDisabled = computed(() =>
    store.state.debug.errors.find((e) => e.level === errorLevels.error)
  );

  const onSetTargetPointByCoordinates = () =>
    store.commit('targetPoints/setTargetPointByCoordinatesVisible', true);

  return {
    onCreateNewClick,
    onExportClick,
    onImportClick,
    onImportThreatsClick,
    onSaveClick,
    onOpenExistingClick,
    onOpenFileManagerClick,
    onLogoutClick,
    onImportFilesUploaded,
    onSaveAsClick,
    onMissionImportClick,
    importAllMissionFromFile: importMissionDataFromFiles,
    importProps: importProperties,
    isSaveVisible,
    isSaveAs,
    isCurrentGeoLocationSetting,
    isThreatByCoord,
    editingThreat,
    currentThreatCoordinates,
    currentDangerSectorCoordinates,
    onSetCurrentGeoLocationByCoords,
    onSetThreatByCoords,
    setCurrentThreatCoordinates,
    setCurrentDangerSectorCoordinates,
    onSetTakeOffByCurrentGeoLocation,
    onSetLandingByCurrentGeoLocation,
    onSaveCurrentGeoLocation,
    onRevertSettingCurrentGeoLocation,
    onSaveAsMangerClick,
    onSaveManagerClick,
    onSaveClose,
    onExportClickSuccess,
    isLogoutDisabled,
    isExportEmpty,
    draftMarkDialogBeforeAction,
    onSaveThreatLocation,
    isThreatModule,
    onSetTargetPointByCoordinates,
  };
}
