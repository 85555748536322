import {
    convertGeoJsonDataToFlightPlanerRequestType,
    getFormattedProperties,
    processInputCalculatorGeoJSON,
    weatherToCPPRequestType
} from "@/utils/mapUtils";

const isWeatherCustomSameAsForecast = (customToCheck, fetchedForecast) => {
    return customToCheck
        .map((item) => {
            const keys = Object.keys(item);
            const value = item.value;
            const elToInspect = fetchedForecast.find((e) => e.value === value);
            return keys.filter((key) => item[key] === elToInspect[key]).length === keys.length;
        })
        .every((e) => e);
};
const buildWeatherToSave = (state) => {
    const forecastCustom = [
        {
            value: 10,
            wind_speed: state.params.airSpeed,
            wind_direction: state.params.airVector,
        },
        {
            value: 1000,
            wind_speed: state.params.airSpeed1000,
            wind_direction: state.params.airVector1000,
        },
    ];
    const forecastFetched = state.weather.weather_data;

    const isCustomNotChanged =
        !!state.weather.weather_data.length &&
        isWeatherCustomSameAsForecast(forecastCustom, forecastFetched);

    return isCustomNotChanged
        ? state.weather.weather_data
        : [
            {
                value: 10,
                wind_speed: state.params.airSpeed,
                wind_direction: state.params.airVector,
            },
            {
                value: 1000,
                wind_speed: state.params.airSpeed1000,
                wind_direction: state.params.airVector1000,
            },
        ];
}

const createCalculationRequestData = (data, state, forecastData, withUserSettings,tagetPointData) => {
    const startCoords =  [data.longitude_start, data.latitude_start].map((e) => Number(e));
    const endCoords = [data.longitude_end, data.latitude_end].map((e) => Number(e));
    const forecastRequestTypeData = weatherToCPPRequestType(startCoords, forecastData);
    const defaultGeoJson = processInputCalculatorGeoJSON(state.map.geoJson, withUserSettings);

    const vectorCollection = withUserSettings ? state.map.additionalGeometry : null;
    const geoJSON = [defaultGeoJson, vectorCollection]
            .flat(2)
            .filter((e) => e),
        startPoint = startCoords,
        endPoint = endCoords,
        properties = getFormattedProperties(state.params, startPoint);

    return convertGeoJsonDataToFlightPlanerRequestType({
        geoJSON,
        properties,
        startPoint,
        endPoint,
        forecast: forecastRequestTypeData,
        withUserSettings: true,
        tagetPointData,
    });
}
class PrefetchValidator {
    constructor(onError, state) {
        this.onError = onError;
        this.isValid = true;
        this.state = state;
    }
    addRule(condition, text) {
        const isInvalid = condition(this.state.params);
        if(isInvalid) {
            this.isValid = false;
            this.onError(text)
        }
    }
}
export {
    buildWeatherToSave,
    PrefetchValidator,
    createCalculationRequestData
}