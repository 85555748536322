import { createApp } from 'vue';
import App from './AppWrapped.vue';
import Element from 'element-plus';
import Store from './store';
import router from './router';
import i18n from './plugins/i18n';
import VueSimpleContextMenu from 'vue-simple-context-menu';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

const app = createApp(App);

app.component('VueSimpleContextMenu', VueSimpleContextMenu);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(i18n);
app.use(Store);
app.use(router);
app.use(Element);

app.mount('#app');
