import { get } from 'lodash';
import { GEO_JSON_IDS as GEO_IDS } from '@/config/map-config';
import { ref, watch } from 'vue';

export default function useLoiters(geoJsonComputed, map, isPathHidden) {
  const loiterRadius = ref([]);
  const clearLoiters = () => {
    loiterRadius.value.forEach((item) => {
      item.remove();
      item = null;
    });
    loiterRadius.value = loiterRadius.value.filter((e) => e);
  };
  const showTakeoffRadiusIfRequired = (values) => {
    clearLoiters();

    if (isPathHidden.value) return;

    const useLoiterRadius = get(
      values.find((e) => e.id === GEO_IDS.path),
      'properties.loiters',
      []
    );
    const pathStyles = get(
      values.find((e) => e.id === GEO_IDS.path),
      'style',
      {}
    );

    if (useLoiterRadius.length && !!map.value) {
      useLoiterRadius.forEach(({ coordinates: [lng, lat], LoiterRadius }) => {
        const value = L.circle([lat, lng], { radius: LoiterRadius, ...pathStyles });
        value.addTo(window.map);

        loiterRadius.value.push(value);
      });
    }
  };

  watch(geoJsonComputed, showTakeoffRadiusIfRequired);
  watch(map, () => showTakeoffRadiusIfRequired(geoJsonComputed.value));
  watch(isPathHidden, () => showTakeoffRadiusIfRequired(geoJsonComputed.value));

  return {
    clearLoiters,
    currentLoiters: loiterRadius,
    refreshLoiters: (cpp_data_array) =>
      showTakeoffRadiusIfRequired(cpp_data_array || geoJsonComputed.value),
  };
}
