<template>
  <VModal
    :is-visible="isVisible"
    size="xlarge"
    ok-text="Close"
    :visible-btns="isEditable"
    :visible-close-btn="false"
    @keydown:escape="resetAndCloseModal"
    @success="resetAndCloseModal"
  >
    <template #body>
      <div class="modalFiles">
        <VModal
          :is-visible="isVisibleRemoveModal"
          title="Delete mission"
          :title-icon="SaveIcon"
          close-text="Yes"
          ok-text="No"
          @close="
            () => {
              if (multipleSelection.length) {
                onDeleteManyClick();
                multipleSelection = [];
              } else {
                onDelete(selectedId);
              }
              isVisibleRemoveModal = false;
            }
          "
          @success="() => (isVisibleRemoveModal = false)"
        >
          <template #body>
            <div class="titleRemoveMission">Are you sure you want to delete?</div>
          </template>
        </VModal>
        <div
          class="modalFiles__leftPart"
          :style="!!rowSelected ? 'width: calc(100% - 320px)' : 'width: 100%'"
        >
          <div class="headerFiles">
            <div class="headerFiles__left">Mission manager</div>
            <div class="headerFiles__right">
              <VInput
                v-model="filterByName"
                :placeholder="'Search...'"
                :clearable="true"
                @input="
                  () => {
                    multipleSelection = [];
                  }
                "
              />
            </div>
          </div>
          <div class="navContainer">
            <div class="navContainer__left">
              <el-tooltip
                effect="dark"
                content="Import mission component"
                show-after="500"
                placement="right"
              >
                <img
                  :class="{ disabled: isActionDisabled }"
                  :src="multipleSelection.length ? UploadIconActive : UploadIcon"
                  alt="upload icon"
                  @click="onOpenMissionsCombiner"
                />
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="Save mission to file"
                show-after="500"
                placement="right"
              >
                <img
                  :class="{ disabled: isActionDisabled }"
                  :src="multipleSelection.length ? UploadImportActive : UploadImport"
                  alt="import icon"
                  @click="onDownloadManyClick"
                />
              </el-tooltip>
              <el-tooltip effect="dark" content="Mission clone" show-after="500" placement="right">
                <img
                  v-if="isEditable"
                  :class="{ disabled: isActionDisabled }"
                  :src="multipleSelection.length ? CopyIconActive : CopyIcon"
                  alt="copy icon"
                  @click="onCopyManyClick"
                />
              </el-tooltip>
              <el-tooltip
                effect="dark"
                content="Favorite missions"
                show-after="500"
                placement="right"
              >
                <img
                  v-if="isEditable"
                  :src="isFavoriteToggle ? FavoriteFillIcon : FavoriteIcon"
                  alt="Favorite icon"
                  @click="
                    () => {
                      multipleSelection = [];
                      isFavoriteToggle = !isFavoriteToggle;
                      withFavorite = isFavoriteToggle;
                    }
                  "
                />
              </el-tooltip>

              <!--              <img-->
              <!--                v-if="isEditable"-->
              <!--                :class="{ disabled: isActionDisabled }"-->
              <!--                :src="ImportIcon"-->
              <!--                alt="combineMissionsIcon"-->
              <!--                @click="onCombineManyClick"-->
              <!--              />-->

              <el-tooltip effect="dark" content="Mission delete" show-after="500" placement="right">
                <img
                  v-if="isEditable"
                  :class="{ disabled: isActionDisabled }"
                  style="width: 24px"
                  :src="multipleSelection.length ? RemoveIconActive : RemoveIcon"
                  alt="copy icon"
                  @click="multipleSelection.length && (isVisibleRemoveModal = true)"
                />
              </el-tooltip>
            </div>
            <el-table
              ref="multipleTableRef"
              class-name="tableContainerFindMission"
              :data="tableData"
              :highlight-current-row="!multipleSelection.length"
              style="width: calc(100% - 65px); height: calc(100% - 30px)"
              fit
              row-key="uniqTableKey"
              lazy
              @selection-change="handleSelectionChange($event)"
              @row-dblclick="openAndCloseModal($event.ID)"
              @row-click="!multipleSelection.length && (rowSelected = $event.ID)"
              @sort-change="onSortChange($event)"
            >
              <el-table-column type="selection" width="30" />
              <!--  FixMe: :sort-method="() => 1" - mb element3 table have better way to prevent default sorting behavior; -->
              <el-table-column prop="name" label="Mission Name" property="name" sortable="custom" :sort-method="() => 1" width="220">
                <template #default="scope">
                  <VSimpleContextMenu
                    :data="tableData"
                    :options="tableOptionsList(scope.row.ID, isEditable, scope)"
                  >
                    <template #content>
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.name"
                        show-after="500"
                        placement="top-start"
                      >
                        <div class="tableName">{{ scope.row.name }}</div>
                      </el-tooltip>
                    </template>
                  </VSimpleContextMenu>
                </template>
              </el-table-column>
              <el-table-column
                prop="mi_modifyDate"
                sortable
                property="modifiedTime"
                label="File Modified Time"
              >
                <template #default="scope">
                  <VSimpleContextMenu :data="tableData" :options="tableOptionsList(scope.row.ID)">
                    <template #content>
                      {{ moment(scope.row.mi_modifyDate).locale('en-gb').format(TABLE_DATE_FORMAT) }}
                    </template>
                  </VSimpleContextMenu>
                </template>
              </el-table-column>
              <el-table-column
                property="createdTime"
                label="File Created Time"
                prop="mi_createDate"
                sortable
                show-overflow-tooltip
              >
                <template #default="scope">
                  <VSimpleContextMenu :data="tableData" :options="tableOptionsList(scope.row.ID)">
                    <template #content>
                      {{ moment(scope.row.mi_createDate).locale('en-gb').format(TABLE_DATE_FORMAT) }}
                    </template>
                  </VSimpleContextMenu>
                </template>
              </el-table-column>
              <el-table-column property="favorite" label="Favorite" width="90">
                <template #default="scope">
                  <VSimpleContextMenu :data="tableData" :options="tableOptionsList(scope.row.ID)">
                    <template #content>
                      <img
                        :key="tableData.map((e) => e.isFavorite).join(',')"
                        class="favorite"
                        :src="scope.row.isFavorite ? FavoriteFillIcon : FavoriteIcon"
                        alt="favorite"
                        @click="onFavoriteToggle(scope.row.ID)"
                      />
                    </template>
                  </VSimpleContextMenu>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="totalCount >= pageSize" class="pagination">
              <VPagination
                :total-count="totalCount"
                :page-size="pageSize"
                @change="
                  (val) => {
                    paginationOptions = {
                      currentPage: val >= 1 ? (val - 1) * pageSize : 0,
                      limit: pageSize,
                    };
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="modalFiles__rightPart">
          <VMissionPreview
            v-if="!!rowSelected && !multipleSelection.length"
            :data="selectedMission"
            :is-read-only="!isEditable"
            :is-visible="!!rowSelected"
            @close="rowSelected = null"
            @update="onMissionUpdateById($event)"
          />
          <div v-if="!rowSelected && !multipleSelection.length" class="notDocumentContainer">
            <img :src="FindDocument" alt="find document" />
          </div>

          <div v-if="multipleSelection.length" class="multipleSelectionContainer">
            <div>
              {{
                multipleSelection.length === 1
                  ? `1 item selected`
                  : `${multipleSelection.length} items selected`
              }}
            </div>
            <img :src="Files" alt="files" />
          </div>
        </div>
      </div>
    </template>
  </VModal>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { ElTable } from 'element-plus';
import {
  CopyIcon,
  UploadIcon,
  UploadIconActive,
  ClearIcon,
  RemoveIcon,
  FavoriteIcon,
  UploadImport,
  UploadImportActive,
  CopyIconActive,
  RemoveIconActive,
  MenuDotsIcon,
  FavoriteFillIcon,
  FindDocument,
  Files,
  SaveIcon,
  ImportIcon,
} from '@/assets/svgs';
import VModal from '@/components/ui/VModal.vue';
import VInput from '@/components/ui/VInput.vue';
import VMissionPreview from '@/components/modals/mission-manager/components/VMissionPreview';
import useMissionHierarchy from '@/hooks/useMissionHierarchy';
import { useStore } from 'vuex';
import moment from 'moment';
import { VSimpleContextMenu } from '@/components/ui';
import useGlobalEvents from '@/hooks/useGlobalEvents';
import VPagination from '@/components/ui/VPagination.vue';

const store = useStore();
const multipleTableRef = ref();
const pageSize = ref(50);
const multipleSelection = ref([]);
const isVisibleRemoveModal = ref(false);
const selectedId = ref('');
const isFavoriteToggle = ref(false);

const element = computed(() => document.getElementsByClassName('vue-simple-context-menu'));

const TABLE_DATE_FORMAT = 'DD.MM.YYYY, LTS';
const elementTableSortEnumToUBEnum = {
  descending: 'desc',
  ascending: 'asc',
};

const setCurrent = (row) => multipleTableRef.value?.setCurrentRow(row);

const tableOptionsList = (id, isEditableRow, scope) => {
  const allFunction = [
    {
      name: 'Clone',
      handler: () => onCopy(id),
    },
    {
      name: 'Download',
      handler: () => onDownload(id),
    },
    {
      name: 'Import',
      handler: () => {
        onModalClose();
        onCombineMissions(id);
      },
    },
    {
      name: 'Delete',
      handler: () => {
        setCurrent(tableData.value[scope.$index]);
        selectedId.value = id;
        isVisibleRemoveModal.value = true;
      },
    },
  ];
  if (isEditableRow) {
    if (multipleSelection.value?.length) {
      if (multipleSelection.value?.length === 1) {
        return [
          {
            name: 'Open',
            handler: () => openAndCloseModal(id),
          },
          ...allFunction,
        ];
      } else {
        return allFunction;
      }
    } else {
      return [
        {
          name: 'Open',
          handler: () => openAndCloseModal(id),
        },
        ...allFunction,
      ];
    }
  } else {
    return [];
  }
};

const isActionDisabled = computed(() => !multipleSelection.value?.length);
const handleSelectionChange = (val) => {
  element.value &&
    Array.from(element.value).forEach((e) => e.classList.remove('vue-simple-context-menu--active'));
  multipleSelection.value = val?.map((e) => e.ID);
};
const onDeleteManyClick = () => onDelete(...multipleSelection.value);
const onDownloadManyClick = () => onDownload(...multipleSelection.value);
const onCopyManyClick = () => {
  onCopy(...multipleSelection.value);
  multipleSelection.value = [];
};
const onCombineManyClick = () => onCombineMissions(...multipleSelection.value);
const onSortChange = ({ prop, order }) => {
  sortBy.value = Object.assign({},{ [prop]: elementTableSortEnumToUBEnum[order] });
  if(!Object.values(sortBy.value).filter(e => e).length) {
    sortBy.value = { mi_modifyDate: 'desc'};
  }
};
const isVisible = computed(() => store.state.missions.isModalVisible);
const {
  files,
  totalCount,
  isEditable,
  isMissionsLoading,
  events: {
    onCopy,
    onDelete,
    onDownload,
    onOpen,
    onModalClose,
    onFavoriteToggle,
    onMissionUpdateById,
    onCombineMissions,
  },
  models: { sortBy, filterByName, withFavorite, paginationOptions, rowSelected },
} = useMissionHierarchy();

const { draftMarkDialogBeforeAction, onImportClick } = useGlobalEvents({ useOptimizeMode: true });
const openAndCloseModal = (id) => {
  draftMarkDialogBeforeAction(() => {
    onOpen(id);
    multipleSelection.value = [];
    onModalClose();
    store.commit('resetDraftMark');
  });
};

const onOpenMissionsCombiner = () => {
  const missionIDs = multipleSelection.value;
  if (missionIDs.length) {
    onCombineManyClick();
  }
};

const resetAndCloseModal = () => {
  multipleSelection.value = [];
  onModalClose();
};

const tableData = computed(() =>
  (files.value || [])
    .filter((e) => !!e.ID)
    .map((record) => ({
      name: record.name,
      description: record.description,
      previewUrl: record.previewUrl,
      isFavorite: !!record.isFavorite,
      mi_modifyDate: new Date(record.mi_modifyDate), //moment(record.mi_modifyDate).format('DD.MM.YYYY, hh:mm:ss'),
      mi_createDate: new Date(record.mi_createDate), //moment(record.mi_createDate).format('DD.MM.YYYY, hh:mm:ss'),
      ID: record.ID,
      uniqTableKey: record.ID + record.name + record.mi_modifyDate + record.mi_createDate,
    }))
);
const selectedMission = computed(() => tableData.value.find((e) => e.ID === rowSelected.value));
//ToDO: refactor is required! move styles from here to "styles" dir;
</script>

<style lang="scss" >
.titleRemoveMission {
  color: #dadae8;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 12px 0 16px 8px;
}
.open-existing-mission-tooltip-btn-param {
  width: 100%;
  display: inline-block;
  text-align: left;
}
.modalFiles {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 80vh;
  .clearIcon {
    position: absolute;
    right: 0;
    top: 5px;
  }
  &__leftPart {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #2d2c2c;
    .el-table .el-table__cell {
      position: static;
      padding: 0;
      height: 50px;
      .cell {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        .list-group {
          height: 100%;
          width: 100%;
          .list-group-item {
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .headerFiles {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;
      height: 24px;
      justify-content: space-between;
      &__left {
        color: #dadae8;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }
      &__right {
        width: 260px;
      }
    }
    .navContainer {
      display: flex;
      flex-direction: row;
      height: calc(100% - 32px);
      position: relative;
      &__left {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 64px;
        border-right: 1px solid #dadae8;
        .disabled {
          cursor: not-allowed;
        }
        img {
          &:first-child {
            margin-top: 12px;
          }
          cursor: pointer;
          margin-bottom: 18px;
        }
        img:hover {
          opacity: 0.7;
        }
      }
      &__right {
        //height: 100%;
        //overflow: auto;
      }
    }

    .pagination {
      position: absolute;
      bottom: 5px;
      left: 32px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }

    .tableContainerFindMission {
      position: absolute;
      left: 65px;
      right: 0;
      top: 0;
      bottom: 30px;
      .tableName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      tbody {
        tr {
          height: 30px;
          td {
            height: 30px !important;
            border-bottom: 0;
          }
        }
        tr:hover {
          cursor: pointer;
        }
      }
      .favorite {
        border-radius: 50%;
        display: flex;
        align-items: center;
      }
      --el-fill-color-blank: #2d2c2c;
      --el-table-header-bg-color: #363636;
      --el-table-row-hover-bg-color: rgba(126, 113, 113, 20%);
      --el-table-current-row-bg-color: rgba(126, 113, 113, 20%);
      --el-table-tr-bg-color: #2d2c2c;
      --el-table-border-color: rgba(126, 113, 113, 20%);
      --el-table-text-color: #dadae8;
      --el-table-header-text-color: #dadae8;
      .el-table__body {
        border-spacing: 0 5px;
      }
      .el-table__body-wrapper {
        overflow-y: auto !important;
      }
    }
  }
  &__rightPart {
    display: flex;
    flex-direction: column;
    background: #2d2c2c;
    .notDocumentContainer {
      display: flex;
      flex-direction: column;
      padding: 16px 30px;
      width: 320px;
      height: 457px;
      justify-content: center;
      text-align: center;
      color: #fff;
    }
    .multipleSelectionContainer {
      display: flex;
      flex-direction: column;
      padding: 16px 30px;
      width: 320px;
      height: 457px;
      justify-content: center;
      text-align: center;
      color: #fff;
      align-items: center;
      img {
        width: 230px;
      }
    }
  }
}
</style>
