import exportTools from './lib/exportTools';
import importTools from './lib/importTools';

export const KmzBuilder = {
  kmzToGeoJSONs: importTools.kmzToGeoJSONs,
  geoJSONsToKMLs: exportTools.geoJSONsToKMLs,
  downloadKMLsAsKMZ: exportTools.downloadKMLsAsKMZ,

  tools: {
    _exports: exportTools,
    _imports: importTools,
  },
};
