import { threatZone } from './threatZone'

const threatKindOrder = ['RADAR', 'MISSILE', 'CANNON', 'OBJECT']

export class ThreatZoneManager {
  constructor({ map }) {
    this.threats = []
    this.map = map
    this.threatLayer = L.layerGroup()
    this.threatLayer.addTo(map)
  }

  updateThreats(threats = []) {
    //threats to add
    //threats to delete
    //threats to update
  }

  addThreats(threats = []) {
    this.removeThreats()

    this.threats = threats.map(threat => threatZone(threat))
    const zonesLayersGroupedByThreatKind = Object.groupBy(this.threats, x => x.zoneData.threatKind)

    threatKindOrder.forEach(type => {
      if (!zonesLayersGroupedByThreatKind[type]) return
      const typeLayer = L.layerGroup()
      zonesLayersGroupedByThreatKind[type].forEach(item => {
        item.addTo(typeLayer)
      })
      typeLayer.addTo(this.threatLayer)
    })

    this.threatLayer.addTo(this.map)

    return this
  }

  removeThreats() {
    this.threatLayer?.clearLayers()
  }

  setVisibility({ ID, category, threatKind, isVisible = true }) {
    if (ID) {
      if (threatKind) {
        return this.setVisibilityByThreatKindAndID({ ID, threatKind, isVisible })
      }
      return this.setVisibilityByID({ ID, threatKind, isVisible })
    }

    if (category) {
      if (threatKind) {
        return this.setVisibilityByThreatKindAndCategory({ category, threatKind, isVisible })
      }
      return this.setVisibilityByUnitCategory({ category, isVisible })
    }

    if (threatKind) {
      return this.setVisibilityByThreadKind({ threatKind, isVisible })
    }
  }

  setVisibilityByThreatKindAndCategory({ category, threatKind, isVisible }) {
    for (let threat of this.threats) {
      if (threat.zoneData.unitCategory === category && threat.zoneData.threatKind === threatKind) {
        threat.setVisibility(isVisible)
      }
    }
  }

  setVisibilityByThreatKindAndID({ ID, threatKind, isVisible }) {
    for (let threat of this.threats) {
      if (threat.zoneData.ID === ID && threat.zoneData.threatKind === threatKind) {
        threat.setVisibility(isVisible)
      }
    }
  }

  setVisibilityByID({ ID, isVisible }) {
    this.threats.forEach((threat) => {
      threat.setVisibilityByOption({ option: 'ID', optionValue: ID, isVisible })
    })
  }

  setVisibilityByThreadKind({ threatKind, isVisible = true }) {
    this.threats.forEach((threat) => {
      threat.setVisibilityByOption({ option: 'threatKind', optionValue: threatKind, isVisible })
    })
  }

  setVisibilityByUnitCategory({ category, isVisible = true }) {
    this.threats.forEach((threat) => {
      threat.setVisibilityByOption({ option: 'unitCategory', optionValue: category, isVisible })
    })
  }

  setHeight(height) {
    this.threats.forEach((threat) => {
      threat.setHeight(height)
    })
  }
  getTheadByID(threatID) {
   return this.threats.find(({ zoneData }) => zoneData.ID === threatID);
  }
  focusOnThreat(threatID) {
    const threats = this.threats.filter(({ zoneData }) => zoneData.ID === threatID)

    if(threats.length) return 

    const accBounds = new L.LatLngBounds()
    threats.forEach(threat => {
      const bounds = threat.layer?.getBounds?.()
      if(bounds?.isValid?.()) {
        accBounds.extend(bounds)
      }
    })

    if(accBounds.isValid()) {
      this.map.fitBounds(accBounds)
    }
  }
}