const REQUESTED_FIELDS = {
  hourly: [
    'winddirection_900hPa',
    'winddirection_975hPa',
    'winddirection_950hPa',
    'winddirection_925hPa',

    'windspeed_975hPa',
    'windspeed_950hPa',
    'windspeed_925hPa',
    'windspeed_900hPa',
  ].join(','),

  current: [
    'wind_direction_10m',
    'wind_direction_80m',
    'wind_direction_120m',
    'wind_direction_180m',

    'wind_speed_10m',
    'wind_speed_80m',
    'wind_speed_120m',
    'wind_speed_180m',
  ].join(','),
};

const REQUESTED_UNITS_TYPE = {
  wind_speed_unit: 'ms',
};

export const defaultWeatherAPIQueryParams = {
  ...REQUESTED_FIELDS,
  ...REQUESTED_UNITS_TYPE,
};
