import { computed, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
import L from 'leaflet';
import { POLYGON_SELECTED_STYLES } from '@/config/map-config';
import { get } from 'lodash';
import { useStore } from 'vuex';

const centerCircleOnPointMarker = (feature) => {
  const isStartOrLanding = feature?.properties?.isTakeoff || feature?.properties?.isLanding;
  const isExcluded = feature?.properties?.isExcluded;
  if (isStartOrLanding)
    return [
      parseFloat(feature?.geometry?.coordinates[1]) + 0.00005,
      feature?.geometry?.coordinates[0],
    ];
  if (isExcluded)
    return [
      parseFloat(feature?.geometry?.coordinates[1]) + 0.00003,
      feature?.geometry?.coordinates[0],
    ];
  else
    return [
      parseFloat(feature?.geometry?.coordinates[1]) + 0.00007,
      feature?.geometry?.coordinates[0],
    ];
};
const getIDorCreateOne = (_) =>
  get(_, 'uuid') || get(_, 'geometry.coordinates', []).flat(2).join('');

/**
 * onClearShowBar - remove layer & clear the map visualization
 * showViewBar LayerGroup | null
 * onShow ([feature, feature, ...], { onChange: function | null, onHide: function | null })
 * @returns {{onShow: ((function(*, {onChange?: *, onHide?: *}=): (null|undefined|undefined))|*), showViewBar: WritableComputedRef<*>, onClearShowBar: ((function(): (null|undefined))|*)}}
 */
export default function ({onClearCustom, onEscapeClickCustom, onSelectCustom } = {}) {
  const store = useStore();
  const showViewBar = computed({
    get: () => window?.showViewBarLayerGroup,
    set: (val) => (window.showViewBarLayerGroup = val),
  });
  const lastItemIDs = computed({
    get: () => store.state.map.showEventsLastIDs,
    set: (val) => store.commit('map/setLastShownIDs', val),
  });

  const convertToGeoJSON = (feature) => {
    const isPoint = feature?.geometry?.type === 'Point';
    const isCircle = isPoint && feature?.properties?.radius;
    if(isCircle) {
      return L.circle(feature?.geometry?.coordinates.map(e => e).reverse(), {
        ...POLYGON_SELECTED_STYLES,
        radius: feature?.properties?.radius,
      })
    }
    if (!isPoint)
      return L.geoJson(feature, {
        style: { ...POLYGON_SELECTED_STYLES, weight: 10, uuid: get(feature, 'uuid') },
      });
    return L.circle(centerCircleOnPointMarker(feature), {
      radius: 12,
      ...POLYGON_SELECTED_STYLES,
      weight: 5,
      fillOpacity: 0,
    });
  };

  const onShow = (features, { onChange = null, onHide = null } = {}) => {
    const layerGroup = L.layerGroup([]);
    const newIDs = [];
    const isEveryInShowBar = features.every((e) => lastItemIDs.value.includes(getIDorCreateOne(e)));
    if (isEveryInShowBar) {
      return onClearShowBar();
    }
    features.map((_) => {
      const itemUUID = getIDorCreateOne(_);
      if (_?.properties?.isHidden && onHide) onHide(itemUUID);
      if (onChange) onChange();

      const geoJson = convertToGeoJSON(_);
      if (!lastItemIDs.value.includes(itemUUID)) layerGroup.addLayer(geoJson);
      newIDs.push(itemUUID);
    });

    try {
      const bounds = L.geoJSON(layerGroup.toGeoJSON()).getBounds();
      window.map.fitBounds(bounds);
      onClearShowBar();
      layerGroup.addTo(window.map);
      showViewBar.value = layerGroup;
    } catch (e) {
      onClearShowBar();
    }
    lastItemIDs.value = newIDs;
  };

  const onClearShowBar = () => {
    if (!showViewBar.value && !window?.showViewBarLayerGroup) return null;
    lastItemIDs.value = [];
    const showBar = window?.showViewBarLayerGroup || showViewBar.value;
    showBar.clearLayers();
    showBar.removeFrom(window.map);
    showViewBar.value = null;
    if(!!onClearCustom) {
      onClearCustom();
    }
  };

  const onEscapeClick = ({ code }) => {
    if(code === 'Escape') {
      onClearShowBar()
      if(!!onEscapeClickCustom) {
        onEscapeClickCustom()
      }
    }
  };
  onMounted(() => window.addEventListener('keydown', onEscapeClick));
  onUnmounted(() => window.removeEventListener('keydown', onEscapeClick));
  onBeforeUnmount(() => onClearShowBar());
  return {
    showViewBar,
    onClearShowBar,
    onShow,
    lastItemIDs
  };
}
