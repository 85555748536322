import L from 'leaflet';

const _animateZoom = L.Popup.prototype._animateZoom;
L.Popup.prototype._animateZoom = function (...opt) {
  if (!this._map) return;
  _animateZoom.call(this, ...opt);
};

L.popup = function (latlng, options) {
  return new L.Popup(latlng, options);
};
