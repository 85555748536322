<template>
  <div v-loading="isFetching" :class="isFetching ? 'loader-mask' : ''">
    <VHeader />
    <VMenu />
    <div class="content">
      <VRootMap />
      <!-- <VMapToolBar /> -->
      <div id="handler" class="iconRes">
        <img :src="IconRes" alt="resibale" />
      </div>
      <vue-resizable
        class="vResizable"
        :height="385"
        :min-height="385"
        drag-selector=".handler"
        :disable-attributes="['l', 't', 'w']"
      >
        <VMissionTabs />
      </vue-resizable>
      <VParams />
    </div>
    <VModals />
  </div>
</template>

<script>
import VHeader from '@/components/layout/VHeader';
import VMenu from '@/components/layout/VMenu';
import VRootMap from '@/components/FlightPlannerMap';
import { mapState } from 'vuex';
import VMissionTabs from '@/components/tabs/VMissionTabs';
import VParams from '@/components/params/VParams';
import { IconRes } from '@/assets/svgs';
import VueResizable from 'vue-resizable';
import VModals from '@/components/modals/VModalsGlobal';

export default {
  name: 'VLayout',
  components: { VMissionTabs, VMenu, VHeader, VRootMap, VueResizable, VParams, VModals },
  computed: {
    IconRes() {
      return IconRes;
    },
    ...mapState(['isFetching']),
  },
};
</script>

<style lang="scss" src="@/styles/components/layout/layout.scss" scoped></style>