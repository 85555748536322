const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0.01,
      hoverRadius: 0.01,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      animation: {
        delay: 2000
      }
    }
  },
  scales: {
    y: {
      min: 0,
      border: { dash: [2, 2] },
      grid: {
        color: '#7D7D91',
      },
      ticks: {
        fontSize: 12,
        color: '#DADAE8',
      },
    },
    x: {
      type: 'linear',
      border: { dash: [2, 2] },
      grid: {
        color: '#7D7D91',
      },
      ticks: {
        maxTicksLimit: 20,
        fontSize: 12,
        color: '#DADAE8',
      },
    },
  },
};

const DATA_PRESETS = {
  EVALUATION: 'E',
  ALTITUDE: 'A',
  ANGLE: 'AN',
  AIRSPEED: 'AI',
  //ToDo: Add more here...
};

const DATASETS_OPTIONS = {
  HEIGHTS_OPTIONS: {
    borderColor: '#54B44B',
    backgroundColor: '#54B44B',
    borderWidth: 1,
    showWith: [DATA_PRESETS.ALTITUDE],
  },
  PATHS_OPTIONS: {
    borderColor: '#FF6444',
    backgroundColor: '#FF6444',
    borderWidth: 2,
    showWith: [DATA_PRESETS.ALTITUDE, DATA_PRESETS.EVALUATION],
  },
};

module.exports = {
  CHART_OPTIONS,
  DATASETS_OPTIONS,
  DATA_PRESETS,
};
