<template>
  <div :class="{ tab: true, selectedTab: isSelected, unselectedTab: !isSelected }">
    <div v-if="icon" class="tab-icon">
      <img :src="icon" :alt="title" />
    </div>
    <div class="tab-title">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VTab',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: Element,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 4px;
  &:hover {
    background: #0670eb;
    .tab-title {
      color: #fff;
    }
  }
  .tab-icon {
  }
  .tab-title {
    color: #dadae8;
    font-size: 14px;
    font-weight: bold;
    line-height: 16.41px;
    margin-left: 14px;
  }
}
.selectedTab {
  background: #0670eb;
  .tab-title {
    color: #fff;
  }
}
</style>