

const SPAAGIcon = L.icon({
  iconUrl: 'map/icons/spaag.png',
  iconSize: [10, 10],
  iconAnchor: [5, 5],
})

const SAMIcon = L.icon({
  iconUrl: 'map/icons/sam.png',
  iconSize: [10, 10],
  iconAnchor: [5, 5],
})

const RADARIcon = L.icon({
  iconUrl: 'map/icons/radar.png',
  iconSize: [10, 10],
  iconAnchor: [5, 5],
})

export default {
  SPAAG: SPAAGIcon,
  SAM: SAMIcon,
  RADAR: RADARIcon,
}