import { useStore } from 'vuex';
import {computed, watch} from 'vue';
import { GEO_JSON_IDS as IDS_CONST } from '@/config/map-config';
import {
  FILENAMES_BY_ID as titlesByID,
  LOOK_FOR_GEO_JSONs_GROUP_IDS as initValues,
} from '@/config/export-config';
import { get } from 'lodash';
import { utils, computeTools } from './components';
const { attachStartFinishAsGeoJson, startFinishToAccordionFormat, buildActionLineZone } = utils;

const EXPORT_MISSION_ITEMS_TITLE = 'Mission';
const EXPORT_CUSTOM_ITEMS_TITLE = 'User Items';
const EXPORT_PATH_TITLE = 'Trajectory';
const EXPORT_ACTION_LINES_TITLE = 'Action Lines';

/***
 * @for optimization
 * @param fields ['rows'] or ['rows', 'list'];
 * @param fields
 * @param uuidModel
 * @param idModel
 * @param removeExcluded flag works only  when field = ['rows'], use it for removing features & featuresCollection that are blacklisted
 * @param isExportModalVisible
 * @param setVisibleExportModal
 * @returns {{rows: ComputedRef<*[]>, list: ComputedRef<[...*[], {features: FlatArray<*[], number>[], id: (string), type: string}]|*[]>}}
 */
// ToDO: refactor INPUT PARAMS!!!
//       remove trash
export default function useFileList(
  fields = ['rows'],
  {
    data,
    blackListConfiguration: { uuidModel, idModel },
    isImport = false,
    removeExcluded = true,
    isExportModalVisible,
    setVisibleExportModal,
    OPEN_BY_DEFAULT = true,
  }
) {
  const store = useStore();
  const excludeIDS =
    idModel ||
    computed({
      get: () => store.state.exports.geoJsonGroupIDsBlackList,
      set: (value) => store.commit('exports/setGeoJsonGroupBlackList', value),
    });

  const excludedUUIDs =
    uuidModel ||
    computed({
      get: () => store.state.exports.featureUUIDsBlackList,
      set: (value) => store.commit('exports/setUUIDsBlackList', value),
    });

  const defaultGeoJSON = computed(() =>
    isImport
      ? (data && data.value) || store.state.exports.importsData._default
      : store.getters['map/getCalculationGeoJSON']
  );

  const customItems = computed(() =>
    isImport ? store.state.exports.importsData._custom : store.getters['map/getUserItems']
  );
  const cppCalculation = computed(
    () => (isImport ? store.state.exports.importsData._cpp : store.state.map.cpp_geoJson) || []
  );

  const list = computed(() => {
    if (!fields.includes('list')) return [];

    const getAll = [
      (defaultGeoJSON.value?.features || []).map((e) => ({ ...e, id: e.id || IDS_CONST.default })),
      ...cppCalculation.value.map((e) =>
        e?.features.map((_) => ({
          ..._,
          properties: Object.assign({}, _?.properties || {}, e?.properties || e?.style),
        }))
      ),
      customItems.value?.features,
    ]
      .flat(2)
      .filter((e) => e);

    const startFinishPoints = attachStartFinishAsGeoJson(
      startFinishToAccordionFormat(
        {
          startCoords: store.getters['params/getStartPointCoordinates'],
          finishCoords: store.getters['params/getEndPointCoordinates'],
          id: IDS_CONST.default,
        },
        excludedUUIDs.value,
        (uuid) => computeTools.toggleChecked(uuid, excludedUUIDs)
      )
    );
    if (!isImport && removeExcluded) {
      const cppData = cppCalculation.value.find((e) => e.id === IDS_CONST.path);
      const lineActions = cppData?.features ? buildActionLineZone(cppData?.features[0]) : null;
      if (lineActions) {
        lineActions?.features?.forEach((feature) => getAll.push(feature));
      }
      startFinishPoints.forEach((point) => getAll.push(point));
    }

    const featuresAll = removeExcluded
      ? getAll.filter(
          (e) => !(excludeIDS.value?.includes(e.id) || excludedUUIDs.value?.includes(e.uuid))
        ) //.concat(startFinishPoints)
      : getAll;

    return initValues.reduce((acc, currentGroupId) => {
      const features = featuresAll.filter((e) => e.id === currentGroupId);
      return features.length
        ? [
            ...acc,
            {
              id: currentGroupId,
              type: 'FeatureCollection',
              title: titlesByID[currentGroupId],
              features,
            },
          ]
        : acc;
    }, []);
  });

  const computeImportRows = (values) => {
    const prepareRows = [];
    const children = values.find((e) => e.id === IDS_CONST.default)?.features || [];
    const computeChild = computeTools.computeChild(excludedUUIDs, store);

    const separatedGyFiles = children.reduce((acc, item) => {
      const sourceFile = item?.properties?.sourceFile;
      if (acc[sourceFile]) acc[sourceFile] = acc[sourceFile].concat([item]);
      else acc[sourceFile] = [item];
      return acc;
    }, {});

    Object.entries(separatedGyFiles).forEach(([fileName, children]) => {
      prepareRows.push({
        term: fileName,
        children: children.map(computeChild),
        open: true,
      });
    });
    return prepareRows;
  };
  const computeExportRows = (values, excluded, excludedByUUID) => {
    const prepareRows = [];
    const startFinishPoints = startFinishToAccordionFormat(
      {
        startCoords: store.getters['params/getStartPointCoordinates'],
        finishCoords: store.getters['params/getEndPointCoordinates'],
      },
      excludedByUUID,
      (uuid) => computeTools.toggleChecked(uuid, excludedUUIDs)
    );
    const pathGroup = values.find((e) => e.id === IDS_CONST.path);
    const missionGroup = values.find((e) => e.id === IDS_CONST.default);
    const customGroup = values.find((e) => e.id === IDS_CONST.custom);

    const computeChild = (config) => computeTools.computeChild(excludedUUIDs, store, config);
    const actionLines =
      get(pathGroup, 'features.0', false) && buildActionLineZone(get(pathGroup, 'features.0'));
    const hasCppCalculations = !!get(
      values.find((e) => e.id === IDS_CONST.path),
      'features.length',
      false
    );
    const hasCustomItems = !!get(
      values.find((e) => e.id === IDS_CONST.custom),
      'features.length',
      false
    );
    const hasMissionItems = !!get(
      values.find((e) => e.id === IDS_CONST.default),
      'features.length',
      false
    );
    const hasTakeoffOrLanding = !!startFinishPoints.length;

    if (hasCppCalculations) {
      prepareRows.push({
        term: EXPORT_PATH_TITLE,
        children: [
          {
            isChecked: !excluded.includes(IDS_CONST.path),
            title: 'Trajectory path',
            value: get(pathGroup, 'features.0.properties.name', 'Path'),
            id: IDS_CONST.path,
            onExcludeToggle: () => computeTools.toggleCppChecked(IDS_CONST.path, excludeIDS),
          },
        ],
        open: OPEN_BY_DEFAULT,
      });

      const children = actionLines?.features?.map(
        computeChild({ useCustomItemsNaming: true, disableValue: true })
      );
      prepareRows.push({
        term: EXPORT_ACTION_LINES_TITLE,
        children,
        open: OPEN_BY_DEFAULT,
      });
    }

    if (hasMissionItems || hasTakeoffOrLanding) {
      const missionItems = (missionGroup?.features || []).map(computeChild());
      prepareRows.push({
        term: EXPORT_MISSION_ITEMS_TITLE,
        children: startFinishPoints.concat(missionItems),
        open: OPEN_BY_DEFAULT,
      });
    }
    if (hasCustomItems) {
      const children = customGroup?.features.map(computeChild({ useCustomItemsNaming: true }));
      prepareRows.push({
        term: EXPORT_CUSTOM_ITEMS_TITLE,
        children,
        open: OPEN_BY_DEFAULT,
      });
    }
    return prepareRows;
  };
  const rows = computed(() => {
    if (!(fields.includes('rows') && fields.includes('list'))) return [];

    const values = list.value || [];
    const excluded = excludeIDS.value || [];
    const excludedByUUID = excludedUUIDs.value || [];
    const accordionGroups = isImport
      ? computeImportRows(values, excluded, excludedByUUID)
      : computeExportRows(values, excluded, excludedByUUID);

    return accordionGroups.map((group) => ({
      ...group,
      children: (group?.children || [])?.map(
        computeTools.attachOnNameChangeToChildren(store, isImport)
      ),
      onExcludeToggle: () =>
        computeTools.handleParentExcludeToggle(
          group,
          group?.children.every((groupChild) => groupChild.isChecked)
        ),
      isChecked: (group?.children || []).every((groupChild) => groupChild.isChecked),
    }));
  });

  return {
    rows,
    list,
    isExportModalVisible,
    setVisibleExportModal,
  };
}
