<script setup>
import { computed, ref } from 'vue';
import { v4 } from 'uuid';

const { data, options, groupId } = defineProps(['data', 'options', 'groupId']);

const vueSimpleContextMenuRef = ref(null);

const handleClick = (event, item) => {
  vueSimpleContextMenuRef.value.showMenu(event, item);
};

const element = computed(() => document.getElementsByClassName('vue-simple-context-menu'));

const handleOptionClick = (event) => {
  event.option.handler();
};
const elementId = computed(() => {
  return v4();
});
</script>

<template>
  <div class="list-group">
    <div
      class="list-group-item list-group-item-action"
      @contextmenu.prevent.stop="
        (event) => {
          element &&
            Array.from(element).forEach((e) =>
              e.classList.remove('vue-simple-context-menu--active')
            );
          handleClick(event, data);
        }
      "
    >
      <slot name="content" />
    </div>
    <div class="popMenu">
      <vue-simple-context-menu
        ref="vueSimpleContextMenuRef"
        :element-id="groupId || elementId"
        :options="options"
        @option-clicked="handleOptionClick"
      >
      </vue-simple-context-menu>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popMenu {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  :deep(.vue-simple-context-menu) {
    width: 120px;
  }
}
</style>