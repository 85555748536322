import UB from '@unitybase/ub-pub';
import errorHandler, {ClientError} from "@/components/error-handler/components/error-handler";

const authParams = { authSchema: 'UB' };

const ubHost = process.env.VUE_APP_UB_HOST;
let isRepeat = false;

// UB-PUB is overwriting window.onerror method to spawn alerts for all console errors (even these from 3-d part libs)
// so make sense to use custom event handler;
window.onerror = errorHandler;

export async function getUbConnection({ login, password }) {
  try {
    const connection = await UB.connect({
      host: ubHost,
      onCredentialRequired() {
        isRepeat = true;
        const auth = UB.apply({ login, password }, authParams);
        return Promise.resolve(auth);
      },
      onAuthorizationFail(reason, conn) {
        if (isRepeat) {
          throw new Error(reason);
        }
        return conn;
      },
    });

    /** @type {UBSession} session */
    const session = await connection.authorize();
    console.warn(session.authHeader());

    window.conn = connection;
    window.session = session;

    return connection;
  } catch (error) {
    throw new ClientError('Error', 'Server connection lost! Please reload the page!');
  }
}
