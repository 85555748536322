<script setup>
import { VInputNumber, VReloadIcon, VSelect } from '@/components/ui';
import { ReloadResult, VectorMap } from '@/assets/svgs';
import { computed, onMounted, ref, watch } from 'vue';
import { useMappingStoreParams } from '@/utils/helpers';
import { conditions } from './mock-weather.json';
import { useStore } from 'vuex';
import { convertDegree } from '@/utils/mapUtils';

const isFetching = ref(false);
const store = useStore();

const text = {
  surface: 'surface',
  m100: '1000 [m]',
  speed: 'Speed [m/s]',
  gsd: 'Direction [deg]',
};

const {
  airSpeed: airSpeedModel,
  GSD: gsdModel,
  airSpeed1000: airSpeed1000Model,
} = useMappingStoreParams(['airSpeed', 'GSD', 'airVector', 'airSpeed1000', 'airVector1000']);

const airVectorModel = computed({
  get() {
    return convertDegree(store.state.params.airVector);
  },
  set(value) {
    store.commit('params/setFormValue', {
      key: 'airVector',
      value,
    });
  },
});

const airVector1000Model = computed({
  get() {
    return convertDegree(store.state.params.airVector1000);
  },
  set(value) {
    store.commit('params/setFormValue', {
      key: 'airVector1000',
      value,
    });
  },
});

const selectValues = computed(() =>
  conditions.map((e) => ({
    label: e.title,
    value: e.title,
  }))
);

const currentTemplateSelected = ref(null);
const startCoords = computed(() => store.getters['params/getStartPointCoordinates']);

const getWeatherFromAPI = async () => {
  if (!startCoords.value) return;
  await store.dispatch('weather/getWeather', startCoords.value);
  const { wind_speed_1000m, wind_direction_1000m, wind_speed_10m, wind_direction_10m } =
    store.state.weather;
  airVectorModel.value = wind_direction_10m;
  airSpeedModel.value = wind_speed_10m;
  airVector1000Model.value = wind_direction_1000m;
  airSpeed1000Model.value = wind_speed_1000m;
};

watch(startCoords, getWeatherFromAPI);

watch(
  currentTemplateSelected,
  (value) => {
    const { speed, direction, speed_1000, direction_1000 } =
      conditions.find((e) => e.title === currentTemplateSelected.value) ?? {};
    airSpeedModel.value = speed ?? 0;
    airVectorModel.value = direction ?? 0;
    airSpeed1000Model.value = speed_1000 ?? 0;
    airVector1000Model.value = direction_1000 ?? 0;
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <div class="weather">
      <div class="weather__item">
        <div class="weather__item__title">{{ `@ ${text.surface}` }}</div>
        <div class="weather__item__form">
          <VInputNumber
            v-model="airSpeedModel"
            class="weather__item__form__field"
            :min="0"
            :max="99"
            :precision="1"
            :label-text="text.speed"
          />
          <VInputNumber
            v-model="airVectorModel"
            class="weather__item__form__field"
            :label-text="text.gsd"
          />
          <div class="mock">
            <img
              :src="VectorMap"
              :style="`transform: rotate(${airVectorModel}deg)`"
              alt="vectorMap"
            />
          </div>
        </div>
      </div>
      <div class="weather__item">
        <div class="weather__item__title">{{ `@ ${text.m100}` }}</div>
        <div class="weather__item__form">
          <VInputNumber
            v-model="airSpeed1000Model"
            class="weather__item__form__field"
            :min="0"
            :max="99"
            :precision="1"
            :label-text="text.speed"
          />
          <VInputNumber
            v-model="airVector1000Model"
            class="weather__item__form__field"
            :label-text="text.gsd"
          />
          <div class="mock">
            <img
              :src="VectorMap"
              :style="`transform: rotate(${airVector1000Model}deg)`"
              alt="vectorMap"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="weather-footer">
      <VSelect v-model="currentTemplateSelected" :options="selectValues" class="result-field" />
      <div class="reload">
        <VReloadIcon
          :refresh="
            async () => {
              try {
                await getWeatherFromAPI();
              } catch (e) {
                currentTemplateSelected.value = null;
              }
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="@/styles/components/params/weather-params.scss" scoped></style>