<script setup>
import { VSideBar, VTabs } from '@/components/ui';
import {computed, defineAsyncComponent, ref, watch} from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const tabList = computed(() => store.getters['sections/getSectionsList']);
const activeTab = computed(() => store.state.sections.activeSection);
const componentAsync = computed(() => tabList.value.find(({ id }) => id === activeTab.value).componentAsync);
const VSection = computed(() => defineAsyncComponent({
  loader: componentAsync.value
}));

const onTabChange = (newValue) => store.commit('sections/openSection', newValue);

</script>
<template>
  <VSideBar class="vMissionTabs">
    <template #header>
      <VTabs :values="tabList" :active-tab="activeTab" @change="onTabChange" />
    </template>
    <template #content>
      <VSection/>
    </template>
  </VSideBar>
</template>

<style lang="scss" scoped>
.kml {
  display: none;
}
.visibleKml {
  display: block;
}
.vMissionTabs {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin-top: 0;
  border-top: 0;
}
</style>