<template>
  <div id="map" class="map"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-easybutton';
import drawLocale from 'leaflet-draw-locales';
import { rootMap } from '@/mixins';
import Vue, { nextTick } from 'vue';
import * as config from '@/config/map-config';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { polygonOrderController } from '@/mixins';
import { v4 as uuidv4 } from 'uuid';
import { get, debounce, cloneDeep } from 'lodash';
import { MAP_SHAPES } from '@/config/map-config';
import { getIconWithText } from '@/utils/mapUtils';
import { ThreatZoneManager } from '@/lib/threatZone/threatZoneManager';
import useShowEvent from '@/hooks/useShowEvent';
import { SECTIONS_IDs } from '@/config/sections';
import { routerMerge } from '@/router/utils';

export default {
  name: 'RootMap',

  mixins: [rootMap, polygonOrderController],

  props: {
    showToggleBtns: {
      type: Boolean,
      default: true,
    },
    onFinishVectorDrawing: Function,
    chartData: Object,
    chartOptions: Object,
    setMarker: Function,
    geoJson: Object,
    getUsersLocations: Function,
    toggleHidden: Function,
    expanded: Boolean,
    toggleExpand: Function,
    hidden: Boolean,
    customLayerName: String,
    useEventsLayer: {
      type: Boolean,
      default: false,
    },
    useUsersLocationsLayer: {
      type: Boolean,
      default: false,
    },
    mapEditable: Boolean,
    isDrawing: Boolean,
    updateBaseLayerID: Function,
    setMap: Function,
    setBaseLayerBounds: Function,
    updateLayersID: Function,
    fmEntity: Boolean,
    drawPolylineControl: Boolean,
    drawPolygonControl: Boolean,
    drawRectangleControl: Boolean,
    drawTextControl: Boolean,
    deleteLayerControl: Boolean,
  },

  data() {
    return {
      inDrawingMode: false,
      constLayers: [],
      editableLayer: null,
      isOpenedChart: false,
      isChangeModeOn: false,
      tooltipOptions: {
        permanent: true,
        direction: 'top',
        offset: { x: 0, y: 3 },
      },
      tooltipContents: {},
      areEventNumbers: true,
      threatLayer: null,
    };
  },

  watch: {
    '$route.query'(newVal, oldVal) {
      if (newVal.lat !== oldVal.lat || newVal.lng !== oldVal.lng || newVal.zoom !== oldVal.zoom)
        this.setMapView();
    },
    isChangeModeOn(newVal) {
      this.addGeoJson(this.geoJson);
    },
    'importedItemsData.features'() {
      this.addImportedLayer();
    },

    accGeoJson() {
      this.addImportedLayer();
    },

    flightAltitudeAGL(newVal) {
      this.threatLayer.setHeight(newVal);
    },

    threats(newVal) {
      this.threatLayer.addThreats(newVal);
    },

    isVectorDrawing(newValue) {
      const vector = this.$store.getters['map/currentVector'];
      if (newValue && vector) {
        if (this.selectedVector) {
          this.selectedVector.remove(this.map);
          this.selectedVector = null;
        }
        this.selectedVector = L.geoJson(vector, {
          style: { ...vector?.style, uuid: get(vector, 'uuid') },
        });
        this.selectedVector.addTo(this.map);
      } else if (this.selectedVector) {
        this.selectedVector.remove(this.map);
        this.selectedVector = null;
      }
    },

    currentVector(newValue) {
      if (this.selectedVector && newValue) {
        this.selectedVector.remove(this.map);
        this.selectedVector = L.geoJson(newValue, {
          style: { ...newValue?.style, uuid: get(newValue, 'uuid') },
        });
        this.selectedVector.addTo(this.map);
      } else if (this.selectedVector && !newValue) {
        this.selectedVector.remove(this.map);
        this.selectedVector = null;
      } else if (!this.selectedVector && !!newValue) {
        this.selectedVector = L.geoJson(newValue, {
          style: { ...newValue?.style, uuid: get(newValue, 'uuid') },
        });
        this.selectedVector.addTo(this.map);
      }
    },

    isCustomDrawingEnabled(newVal) {
      this.map.pm.disableDraw();
      if (newVal) this.map.pm.enableDraw(...this.customDrawShape);
    },

    coordsStart() {
      this.drawStartEndIcons();
    },
    coordsEnd() {
      console.log('Render');
      this.drawStartEndIcons();
    },
    isStartHidden() {
      this.drawStartEndIcons();
    },
    isEndHidden() {
      this.drawStartEndIcons();
    },
    map(newVal) {
      if (this.setMap) this.setMap(newVal);
      if (newVal) {
        this.eventsLayer2 = null;
        this.editableLayer = null;
        this.addToMap();
        this.setMapView();
        window.threatLayer = this.threatLayer = new ThreatZoneManager({ map: newVal });
      }
    },

    showPolygonsByID(newVal) {
      this.addGeoJson(this.geoJson);
    },

    geoJson: {
      handler(newVal, oldVal) {
        console.log('here', newVal);
        if (newVal) {
          this.addGeoJson(newVal);
          !oldVal?.length && this.focusOnLayer(this.editableLayer);
        }
      },
      deep: true,
    },
    // geoJson(newVal) {
    //   if (newVal && this.layerManager) this.addGeoJson(newVal);
    // },

    async isDrawing(newVal) {
      await this.drawMarker_(newVal);
    },

    expanded(newVal) {
      if (newVal) Vue.nextTick(() => this.map.invalidateSize());
    },

    areaSelectionMode(enabled) {
      this.$emit('print-mode-enabled', enabled);
    },
  },

  mounted() {
    window.togglePriority = this.onMarkerControlClick;
    window.addEventListener('keydown', this.handleKeyDown);
  },

  beforeDestroy() {
    window.togglePriority = undefined;
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    setMapView() {
      const { lat, lng, zoom } = this.$route.query;

      if (lat && lng && zoom) {
        this.map?.setView?.(new L.LatLng(lat, lng), zoom);
      }
    },
    handleChangeModeOn(e) {
      if (this.isCustomDrawingEnabled && this.isChangeModeOn) {
        this.$store.commit('map/drawCustomShape', null);
      }
      if (this.isVectorDrawing && this.isChangeModeOn) {
        this.$store.commit('map/stopVectorDrawing');
      }
      this.isChangeModeOn = !this.isChangeModeOn;
      this.inDrawingMode = !this.inDrawingMode;
      //this.$store.commit('toggleEditingMode', this.isChangeModeOn);
    },

    processInputGeoJSON(features) {
      if (!Array.isArray(features)) return [];

      const cpp_data = features.filter((feature) => config.CPP_IDs.includes(feature.id));
      const defaultGeoJSON = features.filter((feature) => !config.CPP_IDs.includes(feature.id));

      this.cpp_data = cpp_data;

      this.defaultGeoJSON = this.processInputMapGeoJSON(defaultGeoJSON[0]);

      return [this.cpp_data]
        .filter((e) => e)
        .flat(1)
        .filter(
          (e) =>
            (this.showPolygonsByID.includes(e.id) || !config.VIEW_BAR_IDS.includes(e.id)) &&
            !(this.isChangeModeOn && config.READ_ONLY_GEO_JSON_IDs.includes(e.id)) &&
            (!this.isCustomDrawingEnabled || e.id !== config.GEO_JSON_IDS.default)
        );
    },

    customEventHandler(e) {
      const TOGGLE_EVENTS = Object.keys(config.MAP_EVENTS.TOGGLES).map(
        (event) => config.MAP_EVENTS.TOGGLES[event]
      );
      const CHANGE_EVENTS = Object.keys(config.MAP_EVENTS.ACTIONS).map(
        (event) => config.MAP_EVENTS.ACTIONS[event]
      );
      if (TOGGLE_EVENTS.includes(e.type)) this.handleChangeModeOn();

      if (CHANGE_EVENTS.includes(e.type)) {
        if (this.isVectorDrawing && !e.layer.options.isTakeoff && !e.layer.options.isLanding)
          return this.handleVectorDrawing(e);

        if (this.isCustomDrawingEnabled && e.shape !== 'Marker') return this.handleCustomDrawing(e);
        else return this.handleSaveChanges(e);
      }
    },

    handleCustomDrawing(e) {
      const { shape, type, target, layer, sourceTarget } = e;
      const jsonToSave = this.editableLayer.toGeoJSON();

      this.$store.commit('map/saveCustomShape', {
        ...jsonToSave,
        features: jsonToSave.features
          .filter((e) => !config.READ_ONLY_GEO_JSON_IDs.includes(e.id))
          .map((e) => ({
            ...e,
            id: config.GEO_JSON_IDS.custom,
          })),
      });
    },

    handleSaveChanges(e) {
      const { shape, type, target, layer, sourceTarget } = e;

      if (type === 'CircleMarker') {
        e.target.removeLayer(layer);
        this.$emit('add-fm-start-point', {
          latitude: layer._latlng.lat,
          longitude: layer._latlng.lng,
        });
        Vue.nextTick(() => this.map.pm.disableDraw());
        return;
      }

      if (
        shape === 'Marker' &&
        type === config.MAP_EVENTS.ACTIONS.CREATE &&
        layer.options.isTargetPoint
      ) {
        const markerCoordinates = e.layer.getLatLng();
        const coordinates = [markerCoordinates.lat, markerCoordinates.lng].reverse();
        this.$store.commit('targetPoints/setTargetPointCoordinates', coordinates);

        e?.layer?.removeFrom(this.map);
        e.target.removeLayer(e.layer);
        this?.map?.pm?.disableDraw();
      }

      if (
        shape === 'Marker' &&
        type === config.MAP_EVENTS.ACTIONS.CREATE &&
        layer.options.isThreat
      ) {
        const markerCoordinates = e.layer.getLatLng();
        const coordinates = [markerCoordinates.lat, markerCoordinates.lng];
        this.$store.commit('threat/setCurrentThreatCoordinates', coordinates);
        e?.layer?.removeFrom(this.map);
        e.target.removeLayer(e.layer);
        this?.map?.pm?.disableDraw();
      }

      if (
        shape === 'Marker' &&
        type === config.MAP_EVENTS.ACTIONS.CREATE &&
        layer.options.isDangerSector
      ) {
        const markerCoordinates = e.layer.getLatLng();
        const coordinates = [markerCoordinates.lat, markerCoordinates.lng];
        this.$store.commit('threat/setCurrentThreatDangerSectorCoordinates', coordinates);
        e?.layer?.removeFrom(this.map);
        e.target.removeLayer(e.layer);
        this?.map?.pm?.disableDraw();
      }

      if (
        shape === 'Marker' &&
        type === config.MAP_EVENTS.ACTIONS.CREATE &&
        (layer.options.isTakeoff || layer.options.isLanding)
      ) {
        if (this.pointsLayer) {
          this.pointsLayer.clearLayers();
          this.map.removeLayer(this.pointsLayer);
        }
        const isStart = layer.options.isTakeoff;
        const markerCoordinates = e.layer.getLatLng();
        const coordinates = [markerCoordinates.lat, markerCoordinates.lng];
        if (isStart) this.$store.commit('params/setStart', coordinates);
        else this.$store.commit('params/setFinish', coordinates);

        e?.layer?.removeFrom(this.map);
        e.target.removeLayer(e.layer);

        this?.map?.pm?.disableDraw();
        return console.log(this.$store.state, coordinates);
      }
      if (shape === 'Marker' && type === config.MAP_EVENTS.ACTIONS.REMOVE) {
        this.$store.commit('map/stopVectorDrawing');
        if (layer.options.isTakeoff) {
          return this.$store.commit('params/setStart', [null, null]);
        } else if (layer.options.isLanding) {
          return this.$store.commit('params/setFinish', [null, null]);
        }

        return this.$store.commit('map/removeFeatureByUUID', layer?.feature?.uuid);
      }

      if (shape === 'Marker' && type === config.MAP_EVENTS.ACTIONS.CREATE) return;

      if (shape === 'Cut') {
        this.editableLayer.remove(target);
        this.editableLayer.addLayer(layer);
      }
      const jsonToSave = this.editableLayer.toGeoJSON();
      if (
        ['Polygon', 'FeatureCollection', 'Line', 'Rectangle'].includes(shape) &&
        Array.isArray(this.geoJson) &&
        this.geoJson.length > 0 &&
        ['Edit', 'Draw'].includes(e?.source)
      )
        this.onGeoJSOMSave(e);
      else
        this.$store.commit('map/setGeoJson', {
          ...jsonToSave,
          features: jsonToSave.features.map((e) => ({ ...e, uuid: e.uuid || uuidv4() })),
        });
    },

    async addToMap() {
      const controlsConfig = {
        position: 'topleft',
        drawMarker: false,
        drawCircleMarker: false,
        drawCircle: false,
        customControls: false,
        drawPolyline: this.drawPolylineControl,
        drawPolygon: this.drawPolygonControl,
        drawRectangle: this.drawRectangleControl,
        drawText: this.drawTextControl,
        deleteLayer: this.deleteLayerControl,
        cutPolygon: true,
      };

      this.map.pm.addControls(controlsConfig);

      this.addEditableLayer();
      this.map.pm.setGlobalOptions({ layerGroup: this.editableLayer });

      if (this.geoJson) this.addGeoJson(this.geoJson);

      Object.keys(config.MAP_EVENTS.TOGGLES).map((event) =>
        this.map.on(config.MAP_EVENTS.TOGGLES[event], this.customEventHandler)
      );
      Object.keys(config.MAP_EVENTS.ACTIONS).map((event) =>
        this.map.on(config.MAP_EVENTS.ACTIONS[event], this.customEventHandler)
      );

      this.focusOnLayer(this.editableLayer);

      this.drawStartEndIcons();

      this.map.on('zoomend', this.handlePositionChange).on('moveend', this.handlePositionChange);

      this.$emit('mapMounted', this.map);
    },

    handlePositionChange(e) {
      const zoom = e.target.getZoom();
      const { lat, lng } = e.target.getCenter();
      routerMerge({ query: { zoom, lat, lng } });
    },

    drawStartEndIcons() {
      if (this.pointsLayer) this.map.removeLayer(this.pointsLayer);

      this.pointsLayer = L.layerGroup([]);
      //console.log(this.pointsLayer)
      this.pointsLayer.clearLayers();

      console.log(this.coordsStart);
      if (this.coordsStart?.every((e) => !!e) && this.map && !this.isStartHidden) {
        const icon = L.icon({
          iconSize: [25, 32],
          iconAnchor: [13, 28],
          tooltip: '',
          iconUrl: `/map/icons/start.png`,
        });
        const startPoint = L.marker(
          this.coordsStart.map((e) => parseFloat(e)),
          { icon, isTakeoff: true }
        )
          .on('click', (e) => {
            if (this.inDrawingMode) return;
            this.$store.commit('map/setSelectedFeatureUUID', 'start');
            this.$store.commit('map/setShowPolygonID', 'start');
            nextTick(() => {
              Object.values(this.pointsLayer?._layers || {}).forEach((l) =>
                l.options.isTakeoff ? l.pm.enable() : l.pm.disable()
              );
            });
          })
          .on('pm:disable', ({ target, layer }) => {
            const { lat, lng } = target.getLatLng();
            this.$store.commit('params/setStart', [lat, lng]);
            localStorage.setItem('lastZoomValue', JSON.stringify(this.map.getZoom()));
            localStorage.setItem(
              'lastCoordinatesViewValue',
              JSON.stringify(this.map.getBounds().getCenter())
            );
          });
        this.pointsLayer.addLayer(startPoint);
      }
      if (this.coordsEnd?.every((e) => !!e) && this.map && !this.isEndHidden) {
        const icon = L.icon({
          iconSize: [25, 32],
          iconAnchor: [13, 28],
          iconUrl: `map/icons/finish.png`,
        });
        const endPoint = L.marker(
          this.coordsEnd.map((e) => parseFloat(e)),
          { icon, isLanding: true }
        )
          .on('click', (e) => {
            if (this.inDrawingMode) return;
            this.$store.commit('map/setSelectedFeatureUUID', 'finish');
            this.$store.commit('map/setShowPolygonID', 'finish');
            nextTick(() => {
              Object.values(this.pointsLayer?._layers || {}).forEach((l) =>
                l.options.isLanding ? l.pm.enable() : l.pm.disable()
              );
            });
          })
          .on('pm:disable', ({ target }) => {
            const { lat, lng } = target.getLatLng();
            this.$store.commit('params/setFinish', [lat, lng]);
            localStorage.setItem('lastZoomValue', JSON.stringify(this.map.getZoom()));
            localStorage.setItem(
              'lastCoordinatesViewValue',
              JSON.stringify(this.map.getBounds().getCenter())
            );
          });
        this.pointsLayer.addLayer(endPoint);
      }

      this.map.addLayer(this.pointsLayer);
    },

    focusOnLayer(layer) {
      //console.log((L.featureGroup().addTo(this.editableLayer)).getBounds())
      const { lat, lng, zoom } = this.$route.query;
      if (!layer || (lat && lng && zoom)) return false;
      //console.log(L.featureGroup([layer]).getBounds())

      try {
        const bounds = L.geoJSON(layer.toGeoJSON()).getBounds();
        this.map.fitBounds(bounds);
        window.lastTrackedBounds = bounds;
      } catch (e) {}

      // const bounds = L.geoJSON(layer.toGeoJSON()).getBounds()//(L.featureGroup(this.editableLayer)).getBounds()
      // console.log(Object.keys(bounds), { topRight: bounds._northEast, bottomLeft: bounds._southWest })
      // if (this.setBaseLayerBounds)
      //   this.setBaseLayerBounds({ topRight: bounds._northEast, bottomLeft: bounds._southWest });
      //
      // if (Object.keys(bounds).length < 2) {
      //   return false;
      // } else {
      //   setTimeout(() => {
      //     this.map.setZoom(13);
      //     const center = bounds.getCenter();
      //     this.map.fitBounds(bounds).panTo(center, 13);
      //   }, 1000);
      //   return true;
      // }
    },

    removeEditedGeoJSON(uuid) {
      const { features, ...geoJson } = this.$store.state.map.geoJson || {};
      const updatedFeatures = features.filter((feature) => feature.uuid !== uuid);

      this.$store.commit('map/modifyFeatures', updatedFeatures);
    },

    processEditedGeoJSON(updatedGeoData, uuid) {
      const { features, ...geoJson } = this.$store.state.map.geoJson || {};
      const updatedFeatures = features.map((feature) =>
        feature.uuid === uuid
          ? {
              ...feature,
              geometry: updatedGeoData.geometry,
            }
          : feature
      );

      this.$store.commit('map/modifyFeatures', updatedFeatures);
    },
    addImportedLayer() {
      if (this.importedLayer) {
        this.map.removeLayer(this.importedLayer);
        this.importedLayer = null;
      }
      this.importedLayer = !this.importedLayer
        ? L.layerGroup([])
        : this.importedLayer.clearLayers();

      const importedLayerFeatures = get(this.$store.state.map.importedInfoLayer, 'features', []);
      const accLayerFeatures = get(this.$store.state.map.accGeoJson, 'features', []);

      const fullGeoJson = [...importedLayerFeatures, ...accLayerFeatures];

      fullGeoJson.map((_) => {
        if (!!_?.properties?.isHidden) return;
        const style = cloneDeep({ ...(_?.properties || _?.style), uuid: get(_, 'uuid') });
        const geoJson = L.geoJson(_, {
          style: !!_?.properties?.isShow ? config.POLYGON_SELECTED_STYLES : style,
        });
        geoJson.on('click', () => {
          this.showController.onShow([_]);
          const isActiveTabAlreadyKml =
            this.$store.state.sections.activeSection === SECTIONS_IDs.KML;
          if (!isActiveTabAlreadyKml) {
            this.$store.commit('sections/openSection', SECTIONS_IDs.KML);
          }
        });
        this.importedLayer.addLayer(geoJson);
      });
      this.map.addLayer(this.importedLayer);
    },
    addGeoJson(feature) {
      if (this.editableLayer) this.map.removeLayer(this.editableLayer);
      this.addEditableLayer();
      this.editableLayer?.clearLayers();
      let features = this.processInputGeoJSON(feature);
      this.addImportedLayer();
      if (this.defaultGeoJSON && this.showPolygonsByID.includes(config.GEO_JSON_IDS.default)) {
        const defaultFeatures = this.defaultGeoJSON?.features?.map((feature) => ({
          ...this.defaultGeoJSON,
          features: [feature],
          ...feature,
        }));
        features = (defaultFeatures || []).concat(features);
      }

      features.map((_) => {
        if (_?.style?.isCustomIcon && _?.style?.isHidden) return;
        if (_?.style?.isTargetPoint && _?.style?.isHidden) return;
        const geoJson = L.geoJson(_, {
          style: { ...(_?.style || _?.styles), opacity: 1, uuid: get(_, 'uuid') },
          pointToLayer: function (feature, latlng) {
            if (feature.id === 'Custom') {
              const index = feature.properties.originalPosition;
              return L.marker(latlng, {
                icon: getIconWithText(index, '/map/icons/yellow-marker.svg'),
              });
            } else if (feature?.properties?.isTargetPoint) {
              const index = feature.properties.originalPosition;
              return L.marker(latlng, {
                icon: getIconWithText(
                  index,
                  `/map/icons/orange-marker.svg`,
                  'is-target-point-marker'
                ),
              });
            }
          },
        });
        if (!_.id || [config.GEO_JSON_IDS.default, config.GEO_JSON_IDS.custom].includes(_.id))
          geoJson
            .on('click', (e) => {
              if (this.inDrawingMode) return;
              this.$store.commit('map/setSelectedFeatureUUID', get(_, 'uuid'));
              this.$store.commit('map/setShowPolygonID', get(_, 'uuid'));
              nextTick(() => {
                Object.values(this.editableLayer?._layers || {}).forEach((l) =>
                  get(l, 'options.style.uuid') === get(_, 'uuid') ? l.pm.enable() : l.pm.disable()
                );
              });
            })
            .on('pm:disable', (e) => {
              if (e.layer.pm.enabled() && e.shape !== MAP_SHAPES.MARKER) return;
              const uuid = get(_, 'uuid');
              const geoJson = e.layer.toGeoJSON();
              this.processEditedGeoJSON(geoJson, uuid);
              this.editableLayer.clearLayers();
              this.$store.commit('map/setShowPolygonID', null);
            })
            .on('pm:remove', (e) => {
              const uuid = get(_, 'uuid');
              this.removeEditedGeoJSON(uuid);
              this.editableLayer.clearLayers();
              this.$store.commit('map/setShowPolygonID', null);
            });
        this.map.pm.setPathOptions(_?.style);
        geoJson.properties = { ...geoJson?.properties, ..._?.style };
        this.editableLayer.addLayer(geoJson);
      });

      this.map.addLayer(this.editableLayer);

      this.addPolygonsOrder(this.defaultGeoJSON);
    },
  },
  computed: {
    ...mapMutations(['map/setGeoJson']),
    ...mapGetters({
      'map/currentVector': 'map/currentVector',
      threats: 'threat/threatZones',
    }),
    ...mapState({
      flightAltitudeAGL: (state) => state.params.H_AGL_Flight,
      threatUnitZones: (state) => state.threat.threatUnitZones,
      isVectorDrawing: (state) => state.map.selectedPolygon,
      isEndHidden: (state) => state.map.hideMissionPoints.find((e) => e === 'end'),
      isStartHidden: (state) => state.map.hideMissionPoints.find((e) => e === 'start'),
      coordsStart: (state) => [state.params.latitude_start, state.params.longitude_start],
      coordsEnd: (state) => [state.params.latitude_end, state.params.longitude_end],
      isCustomDrawingEnabled: (state) => !!state.map.drawCustomShape,
      customDrawShape: (state) => state.map.drawCustomShape,
      showPolygonsByID: (state) => state.map.showPolygonIds,
      importedItemsData: (state) => state.map.importedInfoLayer,
      accGeoJson: (state) => state.map.accGeoJson,
    }),
    currentVector() {
      return this.$store.getters['map/currentVector'];
    },
  },
  setup: () => {
    const showController = useShowEvent();
    return {
      showController,
    };
  },
};
</script>

<style scoped></style>