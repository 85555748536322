/**** @Configurations:
 @IDS_CONST {{ string }} All IDS that map operating;
 @READ_ONLY_GEO_JSON_IDs: Read only Items;
 @CPP_IDs: All items inside will be saved to CPP_geoJson file
 @POLYGON_LIMITS: Limit of polygons in default GeoJSON on MAP
 @VIEW_BAR_DEFAULT_TURN_IDS: Default visible layers
 @MAP_EVENTS: All events that are listening
 @EXPORTS_BlACK_LIST Array<ID> - GEO_JSONs Without possibility to export them
 */

/*** @GEO_JSON_IDS {object} all IDs using in MAP */
const GEO_JSON_IDS = {
  default: 'default',
  footprint: 'Footprint',
  path: 'Path',
  vector: 'Vector',
  multi_footprint: 'Multi_footprint',
  custom: 'Custom',
  action_lines: 'ACTION_LINES',
};

/***@VIEW_BAR_IDS {array} All IDs that will be searching in ViewBar  */
const VIEW_BAR_IDS = [
  GEO_JSON_IDS.default,
  GEO_JSON_IDS.multi_footprint,
  GEO_JSON_IDS.path,
  GEO_JSON_IDS.footprint,
];

/***@VIEW_BAR_DEFAULT_TURN_IDS {array} IDs turned by default */
const VIEW_BAR_DEFAULT_TURN_IDS = [
  GEO_JSON_IDS.default,
  GEO_JSON_IDS.multi_footprint,
  GEO_JSON_IDS.path,
];

/*** @CPP_IDs {array} - IDs stored after parsed from CPP response */
const CPP_IDs = [
  GEO_JSON_IDS.path,
  GEO_JSON_IDS.vector,
  GEO_JSON_IDS.footprint,
  GEO_JSON_IDS.multi_footprint,
];

/**** @MAP_EVENTS {object} Map is listening such events, you can add more in ${ACTIONS} that you want to process in Map*/
const MAP_EVENTS = {
  ACTIONS: {
    CUT: 'pm:cut',
    REMOVE: 'pm:remove',
    CHANGE: 'pm:change',
    UPDATE: 'pm:update',
    DRAGEND: 'pm:dragend',
    CREATE: 'pm:create',
  },
  TOGGLES: {
    EDIT: 'pm:globaleditmodetoggled',
    DRAW: 'pm:globaldrawmodetoggled',
    CUT: 'pm:globalcutmodetoggled',
    ROTATE: 'pm:globalrotatemodetoggled',
    SPLIT: 'pm:globalsplitmodetoggled',
    SCALE: 'pm:globalscalemodetoggled',
    REMOVE: 'pm:globalremovalmodetoggled',
    DRAG: 'pm:globaldragmodetoggled',
  },
};
/*** @{READ_ONLY_GEO_JSON_IDs} Array<ID> - GeoJSONs without possibility to edit them by default map tools */
const READ_ONLY_GEO_JSON_IDs = [
  GEO_JSON_IDS.path,
  GEO_JSON_IDS.footprint,
  GEO_JSON_IDS.multi_footprint,
  GEO_JSON_IDS.vector,
  GEO_JSON_IDS.custom,
];

/*** @{POLYGON_LIMITS} {number} - validation rule means max. possible polygons count */
const POLYGON_LIMITS = null;

/*** @SAVE_ORIGIN_FOOTPRINT_BY_DEFAULT {1/0}; 1 - true; 0 - false; */
const SAVE_ORIGIN_FOOTPRINT_BY_DEFAULT = 0;

/*** @EXPORTS_BlACK_LIST Array<ID> - GEO_JSONs Without possibility to export them */
const EXPORTS_BlACK_LIST = [GEO_JSON_IDS.vector];

const MAP_SHAPES = {
  POLYGON: 'Polygon',
  MARKER: 'Marker',
  CIRCLE_MARKER: 'CircleMarker',
  CIRCLE: 'Circle',
  LINE: 'Line',
  RECTANGLE: 'Rectangle',
  TEXT: 'Text',
};

const PATH_STYLES = { color: '#32A727', fillOpacity: 0 };

const FOOTPRINT_STYLES = {
  color: '#0670EB',
  weight: 2,
  fillOpacity: 0.1,
};

const DEFAULT_POLYGON_STYLES = {
  color: '#FFD400',
  weight: 5,
  fillOpacity: 0.4,
  opacity: 1,
};

const DEFAULT_CUSTOM_POLYGONS_STYLES = {
  color: '#CD5915',
  weight: 10,
  fillOpacity: 0.3,
  opacity: 0.4,
};

const POLYGON_SELECTED_STYLES = {
  weigh: 25,
  dashArray: '15',
  opacity: 1,
  fillOpacity: 0.6,
  color: '#CD5915',
};

const VECTOR_STYLES = {
  dashArray: '15',
  opacity: 1,
  weigh: 25,
};

const BASE_LAYERS = [
  {
    ID: 0,
    url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
    label: 'Google Maps',
  },
  {
    ID: 1,
    label: 'Google Satellite',
    url: 'https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  },
  {
    ID: 2,
    url: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
    label: 'Google Satellite Hybrid',
    isDefault: true,
  },
  {
    ID: 3,
    url: 'https://mt0.google.com/vt/lyrs=h&hl=en&x={x}&y={y}&z={z}',
    label: 'Google Roads',
  },
  {
    ID: 4,
    url: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
    label: 'Google Terrain',
  },
];
const START_POINT_NAME = 'TakeOff';
const END_POINT_NAME = 'Landing';
const ACTION_LINE_STYLES = {
  color: '#c736e0',
  weight: 15,
  fillOpacity: 0,
};
module.exports = {
  GEO_JSON_IDS,
  SAVE_ORIGIN_FOOTPRINT_BY_DEFAULT,
  VIEW_BAR_DEFAULT_TURN_IDS,
  CPP_IDs,
  MAP_EVENTS,
  POLYGON_LIMITS,
  READ_ONLY_GEO_JSON_IDs,
  VIEW_BAR_IDS,
  EXPORTS_BlACK_LIST,
  MAP_SHAPES,
  PATH_STYLES,
  FOOTPRINT_STYLES,
  DEFAULT_POLYGON_STYLES,
  DEFAULT_CUSTOM_POLYGONS_STYLES,
  POLYGON_SELECTED_STYLES,
  VECTOR_STYLES,
  BASE_LAYERS,
  START_POINT_NAME,
  END_POINT_NAME,
  ACTION_LINE_STYLES,
};
