import { getCurrentInstance } from 'vue'
import { ElNotification } from 'element-plus'

/**
 * @class ClientError<title,description>
 * use: "throw new ClientError(title, description)" to show error notification;
 */
export class ClientError  {
    constructor(title, description) {
        this.title = title;
        this.description = description
    }
    showNotification() {
        const ctx = this;
        const { appContext } = getCurrentInstance()

        ElNotification({
            title: ctx.title,
            message: ctx.description,
            type: 'error',
            position: 'bottom-right',
        }, appContext)
    }
}

const handleMeasureLibErrors = (error) => {
    try {
        window.polylineMeasureControl._dragCircleMouseup()
    } catch (e) {
        console.log('Unhandled measurement lib error, ', error);
    }
}
export default function errorHandler (error)  {
    const isMeasureLibInternalError = window?.polylineMeasureControl?._measuring;
    const isClientCustomError = error instanceof ClientError;
    if(isClientCustomError)
        return error.showNotification();
    if(isMeasureLibInternalError)
        return handleMeasureLibErrors(error);

    console.error('Unexpected error: ', error)
};