const WAITING_FOR_MUTATIONS = [
  'params/setStart',
  'params/setFinish',
  'map/editCustomPointSuccess',
  'map/updateCppItemName',
  'map/updateSelectedGeoJsonItemProperties',
  'map/updateGeoJsonFeatureByUUID',
  'map/setImportedLayer',
  'map/changeNameOfImportedItem',
  'map/removeImportedItem',
  'map/removeImportedByGroup',
  'map/updateGeoJsonFeatureByUUID',
  'map/modifyFeatures',
];
const isDraftChecker = (store) => {
  store.subscribe((mutation, state) => {
    const isDraftMutation = WAITING_FOR_MUTATIONS.includes(mutation.type);
    if (isDraftMutation) {
      console.log(mutation);
      store.commit('markAsDraft');
    }
  });
};
export default isDraftChecker;
