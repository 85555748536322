import { GEO_JSON_IDS as IDS_CONST } from '@/config/map-config';
function initialState() {
  return {
    featureUUIDsBlackList: [],
    geoJsonGroupIDsBlackList: [IDS_CONST.multi_footprint],
    isExportsModalVisible: false,
    importsData: {
      _default: null,
      _cpp: null,
      _custom: null,
    },
  };
}

const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setImportsDefault(state, value) {
    state.importsData._default = value;
  },
  setImportsCustom(state, value) {
    state.importsData._custom = value;
  },
  setImportsCpp(state, value) {
    state.importsData._cpp = value;
  },
  resetImports(state, value) {
    state.importsData._cpp = [];
    state.importsData._custom = null;
    state.importsData._default = null;
  },
  setUUIDsBlackList(state, newValue) {
    state.featureUUIDsBlackList = newValue;
  },
  setGeoJsonGroupBlackList(state, newValue) {
    state.geoJsonGroupIDsBlackList = newValue;
  },
  changeNameOfImportedItem(state, { uuid, name }) {
    state.importsData._default.features = state.importsData._default.features.map((item) =>
      item.uuid === uuid
        ? {
            ...item,
            properties: {
              ...item.properties,
              name,
            },
          }
        : item
    );
  },
  setExportsModalVisible(state, isVisible) {
    state.isExportsModalVisible = isVisible;
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters: {},
  actions: {},
  mutations,
};
