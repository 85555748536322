<template>
  <ErrorHandler>
    <AppComponent/>
  </ErrorHandler>
</template>

<script>
import ErrorHandler from "@/components/error-handler/ErrorHandler";
import AppComponent from '@/./App.vue';
export default {
  name: "WrappedApp",
  components: {ErrorHandler, AppComponent}
}
</script>

<style scoped>

</style>