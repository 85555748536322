<template>
  <VModal
    :is-visible="isVisible"
    title="Export to KMZ"
    size="mlarge"
    :visible-save-btn="!isActionButtonHide"
    close-text="Cancel"
    ok-text="Export"
    @close="close"
    @success="success"
  >
    <template #body>
      <div class="exportModal">
        <VAccordion :data="data" />
      </div>
    </template>
  </VModal>
</template>

<script>
import { defineComponent } from 'vue';
import { VModal } from '@/components/ui';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import VImportItem from '@/components/tabs/components/VImportItem';
import VAccordion from '@/components/ui/VAccordion.vue';

export default defineComponent({
  components: { VAccordion, VModal },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    close: {
      type: Function,
      default: null,
    },
    success: {
      type: Function,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActionButtonHide() {
      return this.data.every((e) => e.children?.every((child) => !child.isChecked));
    },
  },
});
</script>

<style lang="scss" scoped>
.exportModal {
  height: 340px;
  overflow: auto;
  margin: 4px 0;
}
</style>