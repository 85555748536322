const { GEO_JSON_IDS: IDS_CONST } = require('./map-config');

const LOOK_FOR_GEO_JSONs_GROUP_IDS = [
  IDS_CONST.default,
  IDS_CONST.custom,
  IDS_CONST.path,
  IDS_CONST.multi_footprint,
  IDS_CONST.action_lines,
];

const titlesByID = {
  [IDS_CONST.default]: 'Mission',
  [IDS_CONST.custom]: 'Custom',
  [IDS_CONST.path]: 'Trajectory',
  [IDS_CONST.multi_footprint]: 'Footprint',
  [IDS_CONST.action_lines]: 'Action Lines'
};

const DEFAULT_OPTIONS = {
  simplestyle: true,
};

const ROOT_KMZ_FILENAME = 'doc.kml';

const EXPORT_LOOK_FOR_SHAPES = {
  POLYGON: 'Polygon',
  MARKER: 'Marker',
  CIRCLE_MARKER: 'CircleMarker',
  LINE: 'LineSting',
  RECTANGLE: 'Rectangle',
};

module.exports = {
  FILENAMES_BY_ID: titlesByID,
  LOOK_FOR_GEO_JSONs_GROUP_IDS,
  DEFAULT_OPTIONS,
  ROOT_KMZ_FILENAME,
  EXPORT_LOOK_FOR_SHAPES,
};
