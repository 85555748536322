import { polygon as toTuftPolygon, lineStrings as toTuftLineSting } from '@turf/helpers';

import {
  getGeoJsonMeasurements,
  getCenterOfPolygon as getCenter,
  getFormattedFeatureType,
  getFormattedFeatureTypeExport,
} from '@/utils/mapUtils';
import { mapState } from 'vuex';
import { toRaw, nextTick } from 'vue';
import {
  POLYGON_SELECTED_STYLES,
  DEFAULT_CUSTOM_POLYGONS_STYLES,
  DEFAULT_POLYGON_STYLES,
} from '@/config/map-config';
// const { getGeoJsonMeasurements } = utils;

export default {
  data: () => ({
    polygonOrderShowAllDescriptions: false,
  }),
  methods: {
    onMarkerControlClick(uuid, toggleUp = false) {
      const geoJson = this.defaultGeoJSON || this.parsedGeoJson;
      const targetIndex = (geoJson?.features || []).findIndex((e) => e.uuid === uuid);

      const indexOfReplacement = toggleUp ? Number(targetIndex) - 1 : Number(targetIndex) + 1;

      if (!geoJson.features[indexOfReplacement]) return;
      this.clearAllOrderMarkers();

      const newArray = geoJson.features.map((e) => e);
      const currentElement = newArray[Number(targetIndex)];

      newArray[targetIndex] = newArray[indexOfReplacement];
      newArray[indexOfReplacement] = currentElement;
      console.log(newArray, targetIndex, indexOfReplacement);

      this.$store.commit('map/setGeoJson', {
        ...geoJson,
        features: newArray,
      });
    },
    // toDO: refactor change place for storing ref to markers window -> vuex || this.${...}
    clearAllOrderMarkers() {
      const markerList = Object.keys(window).filter(
        (e) => e.startsWith('fp_title_') && !!window[e]
      );
      markerList.map((makerKey) => {
        toRaw(window[makerKey]).remove();
        window[makerKey] = null;
      });
    },
    addPolygonsOrder(geoJsonCollection) {
      this.clearAllOrderMarkers();

      if (!(geoJsonCollection?.features && geoJsonCollection?.features?.length > 0)) return;

      geoJsonCollection?.features
        .filter(({ geometry: { type } }) => ['Polygon', 'LineString'].includes(type))
        .filter(({ properties }) => !properties.isExcluded)
        .map(({ geometry, properties }, index) => {
          if (!geometry || !geometry?.coordinates?.length) return;
          index = properties.originalPosition ?? index + 1;
          if (properties.isHidden) return;

          const { lat, lng } = getCenter(geometry);

          const makerKey = `fp_title_${index}`;

          const polygonName = properties?.name || 'Untitled';
          const marker = L.marker(
            { lat, lng },
            {
              textMarker: true,
              text: ` ${index} `,
            }
          );
          marker.bindPopup(`<b>${polygonName}</b> <br/> Flight priority: ${index}`, {
            autoClose: false,
          });
          marker.pm.textArea.className += ' iterative-measurements-marker';
          window[makerKey] = toRaw(marker);

          window[makerKey].addTo(toRaw(this.map));
        });
    },
    onShowPolygon(feature, index) {
      const bounds = L.geoJson(feature).getBounds();
      const { lat, lng } = bounds.getCenter();
      window.map.panTo({ lat: lat, lng: lng });
      this.toggleOnSelect(index);
    },
    onNameChange(currentIndex, name) {
      console.log(name);
      this.mergeFeaturePropertiesByIndex(currentIndex, { name });
    },

    onVectorChange(currentIndex) {
      this.$store.commit('map/setSelectedFeatureUUID', currentIndex.toString());
    },
    onVectorClose() {
      this.$store.commit('map/setSelectedFeatureUUID', null);
    },

    mergeFeaturePropertiesByIndex(index, data) {
      this.clearAllOrderMarkers();
      const { uuid, properties } = this.parsedGeoJson?.features[index];
      this.$store.commit('map/updateGeoJsonFeatureByUUID', {
        uuid,
        properties: { ...properties, ...data },
      });
    },
    toggleShowDescription() {
      this.polygonOrderShowAllDescriptions = !this.polygonOrderShowAllDescriptions;
      const markerList = Object.keys(window).filter(
        (key) => key.startsWith('fp_title_') && !!window[key]
      );

      markerList.map((markerKey) => {
        if (this.polygonOrderShowAllDescriptions) window[markerKey]?.openPopup();
        else window[markerKey]?.closePopup();
      });
    },
    toggleOnHide(uuid) {
      const featuresTotal = this.parsedGeoJson?.features || [];
      const currentIndex = featuresTotal.findIndex((e) => e.uuid === uuid);
      const isHidden = featuresTotal[currentIndex]?.properties?.isHidden;
      this.mergeFeaturePropertiesByIndex(currentIndex, { isHidden: !isHidden });
    },
    toggleOnExclude(currentIndex) {
      const featuresTotal = this.parsedGeoJson?.features || [];
      const isExcluded = featuresTotal[currentIndex]?.properties?.isExcluded;
      this.mergeFeaturePropertiesByIndex(currentIndex, { isExcluded: !isExcluded });
    },
    toggleOnSelect(currentIndex) {
      const featuresTotal = this.parsedGeoJson?.features || [];
      const uuid = featuresTotal[currentIndex].uuid;
      this.$store.commit('map/showItem', uuid);
    },

    onDeleteClick(uuid) {
      this.clearAllOrderMarkers();
      this.$store.commit('map/removeFeatureByUUID', uuid);
    },

    processInputMapGeoJSON(geoJsonCollection) {
      const features = (geoJsonCollection?.features || []).map(({ properties, style, ..._ }) => {
        const newStyle = Object.assign({}, { ...(style || {}) }, { ...(properties || {}) });
        const isHidden = properties?.isHidden;
        const isCustom = style?.isExcluded || properties?.isExcluded;
        const isNotCustom = _?.id !== 'Custom';
        const isSelected = style?.isSelected || properties?.isSelected;
        const defaultStyles = isNotCustom ? DEFAULT_CUSTOM_POLYGONS_STYLES : DEFAULT_POLYGON_STYLES;

        const isMarker = _.geometry?.type === 'Point';
        newStyle.isCustomIcon = isCustom && isMarker;
        newStyle.isMarker = isMarker;
        newStyle.weigh = isSelected ? POLYGON_SELECTED_STYLES.weigh : defaultStyles.weight;
        newStyle.dashArray = isSelected
          ? POLYGON_SELECTED_STYLES.dashArray
          : defaultStyles.dashArray;
        newStyle.opacity = isSelected ? POLYGON_SELECTED_STYLES.opacity : defaultStyles.opacity;
        newStyle.fillOpacity = isSelected
          ? POLYGON_SELECTED_STYLES.fillOpacity
          : defaultStyles.fillOpacity;
        newStyle.color = isHidden ? 'none' : defaultStyles.color;

        newStyle.opacity = isHidden ? 0 : defaultStyles.opacity || 0.3;
        newStyle.fillOpacity = isHidden ? 0 : defaultStyles.fillOpacity || 0.3;

        return {
          ..._,
          properties: { ...properties, style: newStyle, ...newStyle },
          style: newStyle,
        };
      });
      return {
        ...geoJsonCollection,
        features,
      };
    },

    processInputCalculatorGeoJSON(geoJsonCollection) {
      const features = (geoJsonCollection?.features || []).filter(
        ({ properties }) => !properties?.isExcluded
      );
      return {
        ...geoJsonCollection,
        features,
      };
    },

    formatAreaType(area, { geometry: { type, coordinates } }) {
      return type === 'Point'
        ? `${coordinates
            .map((e) => `${Number(e).toFixed(6)}°`)
            .reverse()
            .join(', ')}`
        : area;
    },

    setFirstLast(uuid, isFirst) {
      const geoJson = this.defaultGeoJSON || this.parsedGeoJson;
      const target = (geoJson?.features || []).find((e) => e.uuid === uuid);
      const otherFeatures = (geoJson?.features || []).filter((e) => e.uuid !== uuid);

      const sortedArray = isFirst ? [target, ...otherFeatures] : [...otherFeatures, target];

      this.$store.commit('map/setGeoJson', {
        ...geoJson,
        features: sortedArray,
      });
    },
    customPointEditClick(event) {
      return (
        event.isPoint &&
        (event.isExcluded || event.isTargetPoint) &&
        this.$store.commit('map/toggleModalEditCustomPoint', event.uuid)
      );
    },

    showPolygonParams(uuid) {
      this.$store.commit('map/toggleSetSelectedPolygon', uuid);
    },
    startVectorDraw(uuid) {
      this.$store.commit('map/startVectorDrawing', uuid);
      return nextTick(() => {
        window.map.pm.enableDraw('Line', {
          snappable: true,
          finishOn: 'click',
          snapDistance: 2,
          autoTracing: true,
        });
      });
    },
  },
  computed: {
    sortedDefaultGeoJsonFeatures: {
      get: function () {
        return (this.parsedGeoJson?.features || []).map((feature, index) => {
          const { area, length } = getGeoJsonMeasurements({
            ...this.parsedGeoJson,
            features: [feature],
          });
          const { isHidden, isExcluded, isSelected, name: originName } = feature?.properties;
          const name = originName || 'Untitled';
          const isPoint = feature?.geometry?.type === 'Point';
          const isLine = feature?.geometry?.type === 'LineString';
          const isTargetPoint = feature?.properties?.isTargetPoint;

          return {
            area: this.formatAreaType(area || length, feature),
            currentOrder: feature?.properties?.originalPosition || index + 1,
            currentIndex: index,
            name,
            feature,
            isTargetPoint,
            uuid: feature.uuid,
            type: isExcluded
              ? getFormattedFeatureTypeExport(feature)
              : getFormattedFeatureType(feature),
            isHidden,
            isExcluded,
            isSelected,
            typeId: feature.id,
            isPoint,
            isLine,
            setVectorEvent: () => this.startVectorDraw(feature.uuid),
            deleteEvent: () => this.onDeleteClick(feature.uuid),
            hideEvent: () => this.toggleOnHide(feature.uuid),
            excludeEvent: () => this.toggleOnExclude(index),
            showDescriptionEvent: () => this.toggleShowDescription(),
            changeNameEvent: (name) => this.onNameChange(index, name),
            showEvent: () => {
              if (isHidden) {
                this.toggleOnHide(feature.uuid);
              }
              this.onShowPolygon(feature, index);
            },
            moveEvent: (toggleMoveTop) => this.onMarkerControlClick(feature.uuid, toggleMoveTop),
            setLastFirstEvent: (toggleMoveTop) => this.setFirstLast(feature.uuid, toggleMoveTop),
            showParamsEvent: () => this.showPolygonParams(feature.uuid),
            onCustomPointEdit: () =>
              this.customPointEditClick({ uuid: feature.uuid, isPoint, isExcluded, isTargetPoint }),
          };
        });
      },
      set: function (sortedArray) {
        if (sortedArray.some((e) => !e.uuid) || sortedArray.some((e) => e.isExcluded)) return null;

        this.clearAllOrderMarkers();
        const geoJson = this.defaultGeoJSON || this.parsedGeoJson;
        const newArraySorted = sortedArray.map((sortedEl) =>
          geoJson.features.find((defaultEl) => defaultEl.uuid === sortedEl.uuid)
        );
        this.$store.commit('map/setGeoJson', {
          ...geoJson,
          features: newArraySorted,
        });
      },
    },
  },
};
