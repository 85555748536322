import service from '@/services/service';
import moment from 'moment';

export const errorLevels = {
  info: 'info',
  error: 'error',
  warning: 'warning',
};
const initState = {
  // will display in warning tab;
  errors: [],

  // will display in modal
  extraErrors: [],
};
function initialState() {
  return {
    ...initState,
  };
}
// Getter functions
const getters = {
  getErrors(state) {
    return state.errors;
  },
  getErrorsGroupedByLevel: (state) => ({
    ...Object.values(errorLevels).reduce((acc, level) => ({ ...acc, [level]: state.errors.filter(e => e.level === level) }), {})
  })
};
// Actions
const actions = {};
// Mutations
const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  addLog(state, { message, details = '', level = errorLevels.info, coordinates = null }) {
    const date = moment().format('YYYY-DD-MM hh:mm:ss');
    state.errors.push({ message, level, details, date, coordinates });
  },
  clearErrorState(state = 0) {
    state.errors = [];
  },

  clearExtraErrors(state) {
    state.extraErrors = [];
  },

  addExtraError(state, extraError) {
    state.extraErrors = state.extraErrors.concat(extraError);
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
