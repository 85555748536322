import * as toGeoJSON from '@tmcw/togeojson';
import JSZip from 'jszip';
import { DOMParser } from 'xmldom';
import { get } from 'lodash';
import { v4 } from 'uuid';

import {
  FILENAMES_BY_ID as FILES_NAMESPACE,
  ROOT_KMZ_FILENAME as rootKmzFile,
} from '@/config/export-config';

import { GEO_JSON_IDS as IDS_CONST } from '@/config/map-config';

import { stylesConfig, styleNamespace } from './constants';

const reversedNamespace = Object.entries(styleNamespace).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  { name: 'name' }
);
function kmlToClassicFormat(kmlFile) {
  let kml = kmlFile;
  if (/<altitudeMode>absolute/.test(kmlFile)) {
    kml = kml.replace(/<altitudeMode>absolute<\/altitudeMode><coordinates>/g, '<coordinates>');
    const coordinatesInResult = [...kml.matchAll(/<coordinates>[0-9 ,.]+<\/coordinates>/g)].map(
      (e) => e[0]
    );
    const replaceTo = coordinatesInResult.map((e) =>
      e
        .replace('<coordinates>', '')
        .replace('</coordinates>', '')
        .split(' ')
        .map((_) => [_.split(',')[0], _.split(',')[1]].join(','))
        .join(' ')
    );
    for (let i = 0; i <= coordinatesInResult.length; i++)
      kml = kml.replace(coordinatesInResult[i], `<coordinates>${replaceTo[i]}</coordinates>`);

    return kml;
  } else return kmlFile;
}

function formatStyleWithDefault(feature, defaultStyles = {}) {
  const styles =
    (feature.properties && Object.keys(feature.properties).length && feature.properties) ||
    defaultStyles;
  return Object.keys(styles).reduce(
    (acc, key) => ({
      ...acc,
      [reversedNamespace[key]]: styles[key],
    }),
    {}
  );
}
function toFlightPlannerFormat({ features, id, description, ...other }) {
  const styles = {
    ...(stylesConfig[id] || {}),
    name: description,
  };

  return {
    ...other,
    id,
    features: features.map((e) => ({
      id,
      uuid: v4(),
      ...e,
      properties: e?.properties?.folderId
        ? Object.assign(formatStyleWithDefault(e, styles), { folderId: e?.properties?.folderId })
        : formatStyleWithDefault(e, styles),
      styles: formatStyleWithDefault(e, styles),
    })),
    properties: {},
    styles: {},
  };
}

const lookForReferences = (kmxRoot) => {
  const domElements = new DOMParser().parseFromString(kmxRoot, 'application/xml');
  const KMLsInside = domElements.getElementsByTagName('NetworkLink');
  const currentKmlNameSpaces = [];
  const currentKMLCustomDescriptions = [];

  for (let i = 0; i < KMLsInside.length; i++) {
    const nameContent = KMLsInside.item(i).getElementsByTagName('name').item(0);
    const customDescription = KMLsInside.item(i).getElementsByTagName('description').item(0);
    currentKMLCustomDescriptions.push(get(customDescription, 'textContent'));
    currentKmlNameSpaces.push(get(nameContent, 'textContent'));
  }
  return { descriptions: currentKMLCustomDescriptions, namespace: currentKmlNameSpaces };
};

function getInternalByFileName(name) {
  return Object.keys(FILES_NAMESPACE).find((key) => `${FILES_NAMESPACE[key]}.kml` === name);
}

async function kmlToGeoJSON({ file, id, description }) {
  const data =
    id !== IDS_CONST.path || id !== IDS_CONST.multi_footprint ? kmlToClassicFormat(file) : file;

  const kmlFileInDomFormat = new DOMParser().parseFromString(data, 'application/xml');
  const folders = Array.from(kmlFileInDomFormat.getElementsByTagName('Folder'));
  if (folders.length) {
    const geoJSON = folders.reduce((acc, folderGeoData) => {
      const folderName = folderGeoData.getElementsByTagName('name').item(0).textContent;
      const folderGeoJSON = toGeoJSON.kml(folderGeoData);
      return {
        ...folderGeoJSON,
        features: (acc?.features || []).concat(
          folderGeoJSON?.features.map((e) => ({
            ...e,
            properties: { ...(e?.properties || {}), folderId: `${folderName}`.trim() },
          }))
        ),
      };
    }, {});
    return toFlightPlannerFormat({ ...geoJSON, id, description });
  }
  const geoJSON = toGeoJSON.kml(kmlFileInDomFormat);
  return toFlightPlannerFormat({ ...geoJSON, id, description });
}

async function kmzToGeoJSONs(file) {
  const isFileCorrect = file.name.endsWith('.kml') || file.name.endsWith('.kmz');
  const isKML = file.name.endsWith('.kml');

  if (!isFileCorrect) return alert('Unsupported format, use ".kmz" or ".kml"');

  if (isKML) {
    console.info('Using KML parser, starting load the features...');
    return loadKMLAsDefaultGeoJSON(file);
  }

  const data = await JSZip.loadAsync(file);

  const isOurFormat = false; //await isOurFormatUsed(data);
  if (!isOurFormat) return loadAllAsDefaultGeoJSON(data, file.name);

  const { filesToProcessing: processedFiles } = isOurFormat;
  console.info(
    `Found groups: [${processedFiles.map((e) => e.id).join(',')}], starting importing...`
  );

  for (const fileObject of processedFiles) {
    fileObject.file = await fileObject.file.async('string');
  }
  return Promise.all(processedFiles.map((e) => kmlToGeoJSON(e))).then((geoJSONs) =>
    geoJSONs.map((item) => ({
      ...item,
      features: item.features.map((e) => ({
        ...e,
        properties: { ...e.properties, sourceFile: file.name },
      })),
    }))
  );
}

async function isOurFormatUsed(data) {
  console.info('Checking file structure format....');

  const fileList = Object.keys(data.files).filter((e) => e !== rootKmzFile);
  const rootFile = data.files[rootKmzFile];
  if (!rootFile) {
    console.info(
      `Impossible to group KMLs, please add rootFile: ${rootKmzFile}, with references to KMLs;`
    );
    return false;
  }

  const rootDom = await rootFile.async('string');
  const { namespace: filesIncludes, descriptions } = lookForReferences(rootDom);

  const filesToProcessing = filesIncludes
    .map((fileName, index) => ({
      id: getInternalByFileName(fileName),
      description: descriptions[index] || 'Untitled',
      file: data.files[fileList[index]],
    }))
    .filter((e) => !!e.id);

  if (!filesToProcessing.length) {
    console.info(
      `Impossible to group KMLs, no one group have found, please check references in ${rootKmzFile}...`
    );
    return false;
  } else {
    return {
      rootFile,
      filesToProcessing,
    };
  }
}
async function loadAllAsDefaultGeoJSON(data, sourceFileName = 'Untitled.kmz') {
  console.info(`Starting load to default GeoJSON group...`);

  console.log(data);
  const fileList = Object.keys(data.files); //.filter((e) => e !== rootKmzFile);
  const filesToProcessing = fileList
    .filter(fileName => fileName.endsWith('.kml'))
    .map((fileName, index) => ({
    id: IDS_CONST.default,
    description: 'Untitled',
    file: data.files[fileName],
  }));
  for (const fileObject of filesToProcessing) {
    fileObject.file = await fileObject.file.async('string');
  }

  const geoJsonList = await Promise.all(filesToProcessing.map((e) => kmlToGeoJSON(e)));
  console.info(`Import successfully done, found: ${geoJsonList.length} features.`);

  return [
    geoJsonList.reduce(
      (acc, { features, ...other }) => {
        acc.features = acc.features.concat(features.length ? features : []);
        return acc;
      },
      { ...(geoJsonList[0] || {}), features: [] }
    ),
  ].map((item) => ({
    ...item,
    features: item.features.map((e) => ({
      ...e,
      properties: { ...e.properties, sourceFile: sourceFileName },
    })),
  }));
}
async function loadKMLAsDefaultGeoJSON(file) {
  const loader = new FileReader();
  const kmlContent = await new Promise((res) => {
    loader.onload = function (loadEvent) {
      if (loadEvent.target.readyState != 2) return;
      if (loadEvent.target.error) {
        alert('Error while reading file ' + file.name + ': ' + loadEvent.target.error);
        return;
      }
      res(loadEvent.target.result); // Your text is in loadEvent.target.result
    };
    loader.readAsText(file);
  });
  const geoJson = await kmlToGeoJSON({
    id: IDS_CONST.default,
    description: 'Untitled',
    file: kmlContent,
  });
  return [geoJson].map((item) => ({
    ...item,
    features: item.features.map((e) => ({
      ...e,
      properties: { ...e.properties, sourceFile: file.name },
    })),
  }));
}
const importKMZTools = {
  kmzToGeoJSONs,
  kmlToGeoJSON,
  getInternalByFileName,
  lookForReferences,
  toFlightPlannerFormat,
  kmlToClassicFormat,
};
export default importKMZTools;
