<template>
  <VModal
    :is-visible="isVisible"
    :visibleBtns="false"
    :visibleCloseBtn="false"
    :visibleSaveBtn="false"
    :title="title"
    size="small"
    :title-icon="LocationIcon"
    close-text="Close"
    ok-text="Apply"
    @close="$emit('close', close)"
    @success="onSuccessClick()"
  >
    <template #body>
      <VSelect 
        v-model="threatUnitCode" 
        :options="options"
        :search-option="true"
        class="mb"
        placeholder="Select threat unit" 
      />
    </template>
    <template #footer>
      <button  class="modalFooter__regularBtn" @click="$emit('close')">
        Close
      </button>
      <button class="modalFooter__regularBtn" @click="handleSuccessAndNew" :disabled="!threatUnitCode">
        Save & new
      </button>
      <button  class="modalFooter__okBtn" @click="handleSuccess" :disabled="!threatUnitCode">
        Save
      </button>
    </template>
  </VModal>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex'
import { VModal } from '@/components/ui';
import VSelect from '@/components/ui/VSelect.vue';

export default defineComponent({
  components: { VSelect, VModal },
  props: {
    currentThreatCoordinates: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    initValues: {
      type: Array,
      default() {
        return null;
      },
    },
    title: {
      type: String,
      default: '',
    },
    close: {
      type: Function,
      default: null,
    },
    success: {
      type: Function,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      options: state => state.threat.threatUnits
        .reduce((acc, item) => {
          if(!item.ID) return acc
          return [...acc, { value: item.code, label: item.name }]
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label))
      })
  },
  methods: {
    handleSuccessAndNew(e) {
      this.handleSuccess(e, true)
    },
    handleSuccess(_, addNew = false) {
      const [lat, lon] = this.currentThreatCoordinates
      this.$emit('success', { createNew: addNew, threatUnitCode: this.threatUnitCode, coordinates: { lat, lon }})
      this.threatUnitCode = null
    },
    handleClose() {
      this.$emit('close')
      this.threatUnitCode = null
    }
  },
  data() {
    return {
      threatUnitCode: null,
    }
  }
})
</script>
<style scoped>
  .mb {
    margin-bottom: 12px;
  }

  .modalFooter__regularBtn {
    padding: 9px 16px;
    color: #dadae8;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    background: transparent;
    border: none;
    outline: none;
    text-align: center;
    cursor: pointer;
  }

  .modalFooter__okBtn {
    padding: 9px 16px;
    color: #dadae8;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    background: #0670eb;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
  }
</style>