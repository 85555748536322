import { useStore } from 'vuex';
import { exportMissionAs, importMission } from '@/lib/mission-exporter';
import { processMissionName } from '@/utils/helpers';
import { routerMerge } from '@/router/utils';

export default function useMissionExporter() {
  const store = useStore();

  function onDataExport() {
    const params = store.getters['params/getParamsAll'];
    const mapData = store.getters['map/getRawData'];
    exportMissionAs(params, mapData);
  }

  async function onDataImport(file) {
    const missionName = processMissionName(file?.name)
      .replace(/\.[^/.]+$/, '')
      ?.trim();
    const missionBlob = new window.Blob([file]);
    const response = await importMission(missionBlob);
    store.commit('map/setDefaultGeoData', null);
    store.commit('map/setCppGeoData', []);
    store.commit('params/initPramsState', { ...response, name: missionName });
    store.commit('map/initMapStore', response);
    const { ID, plain, ...rest } = response;
    store.commit('params/mergePlain', { ...rest, ID: plain });
    store.commit('debug/reset');
    routerMerge({ query: { mission: 'new' } });
  }

  return {
    onDataExport,
    onDataImport,
  };
}
