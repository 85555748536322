<script setup>
import { VSelect, VCheckBox } from '@/components/ui';
import { computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useMappingStoreParams, useProfilesSelect } from '@/utils/helpers';

const { fieldData: cameraData, fieldSelectedModel: cameraSelectedModel } = useProfilesSelect(
  'camera',
  'cameras',
  'params/setCamera'
);

const texts = {
  licenseCheckbox: 'Portrait',
};
</script>
<template>
  <div class="form">
    <div class="form__license">
      <VCheckBox :label="texts.licenseCheckbox" />
      <VSelect v-model="cameraSelectedModel" style="width: 152px" size="xs" :options="cameraData" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form__license {
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  :deep(.el-checkbox__label) {
    color: #dadae8;
    font-size: 12px;
    font-weight: 400;
    padding-left: 11px;
  }
  :deep(.el-checkbox__inner) {
    background: var(--side-bar-bg);
  }
}
</style>