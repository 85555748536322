<template>
  <VModal
    :is-visible="isVisible"
    title="Import from KML"
    size="mlarge"
    close-text="Cancel"
    ok-text="Import"
    @close="$emit('close', close)"
    @success="$emit('success', success)"
  >
    <template #body>
      <div class="container">
        <VAccordion is-new-checkboxes :data="accordionArray" />
      </div>
    </template>
  </VModal>
</template>

<script>
import { defineComponent } from 'vue';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import VImportItem from '@/components/tabs/components/VImportItem';
import { VAccordion, VModal } from '@/components/ui';
import {parseHierarchyAndGroupFolders} from "@/utils/build-import-hierarchy";

export default defineComponent({
  components: { VAccordion, VModal },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    close: {
      type: Function,
      default: null,
    },
    success: {
      type: Function,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    accordionArray() {
      const groupedHierarchy = parseHierarchyAndGroupFolders(this.data);
      return groupedHierarchy.map(({ fileName, children }) => ({
        term: fileName,
        children,
        isChecked: children.every(e => e.isChecked),
        onExcludeToggle: val => children.forEach(item => item.onExcludeToggle(val))
      }));
    },
  },
});
</script>

<style lang="scss">
.container {
  height: 340px;
  overflow: auto;
  margin: 4px 0;
  $this: &;
  &__item {
    $this: &;
    display: inline-block;
    margin: 20px 10px;
    padding-bottom: 25px;
    &__title {
      color: var(--black-400);
      font-size: 14px;
      width: 100%;
      display: inline-block;
      text-align: center;
      position: relative;
      top: -7px;
      padding-bottom: 4px;
      border-bottom: 1px solid var(--black-700);
    }
  }
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    --el-checkbox-height: 28px;
    .header {
      display: flex;
      align-items: center;
      color: #dadae8;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
    }
    .content {
      margin: 0 0 0 10px;
      padding: 0 0 0 20px;
      position: relative;
      //overflow: hidden;
      :deep(.v-input) {
        margin: 0 10px;
        height: 24px;
        width: 120px;
      }
      &:after {
        content: '';
        width: 1px;
        background-image: linear-gradient(
          0deg,
          #7d7d91,
          #7d7d91 75%,
          transparent 75%,
          transparent 100%
        );
        background-size: 1px 2px;
        display: block;
        position: absolute;
        left: -4px;
        top: -20px;
        bottom: 13px;
      }
      &__item {
        display: flex;
        align-items: center;
        color: #dadae8;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        &:after {
          content: '';
          height: 1px;
          background-image: linear-gradient(
            90deg,
            #7d7d91,
            #7d7d91 75%,
            transparent 75%,
            transparent 100%
          );
          background-size: 2px 1px;
          display: block;
          position: absolute;
          top: auto;
          width: 25px;
          left: -4px;
        }
      }
    }
  }
}
</style>