<script setup>
import { useMappingStoreMissionParams } from '@/utils/helpers';
import { computed } from 'vue';
import { VInputNumber, VCheckBox } from '@/components/ui';

const formValues = {
  H_AGL_Flight: {
    key: 'H_AGL_Flight',
    label: 'Flight altitude AGL [m]',
    propName: 'flightAltitudeAGL',
  },
  LineSpacing: {
    key: 'LineSpacing',
    label: 'Line spacing [m]',
    propName: 'lineSpacing',
  },
  L_OvershootLine_Zone: {
    key: 'L_OvershootLine_Zone',
    label: 'Overshoot zone [m]',
    propName: 'overshootLength',
    type: 'Polygon',
  },
  LevelingDistance: {
    key: 'LevelingDistance',
    label: 'Overshoot route [m]',
    propName: 'overshootLength',
    type: 'LineString',
  },
  ConstantAltitude: {
    key: 'ConstantAltitude',
    label: 'Constant altitude',
    propName: 'constantAltitude',
  },
};

const { H_AGL_Flight, LineSpacing, L_OvershootLine_Zone, LevelingDistance, ConstantAltitude } =
  useMappingStoreMissionParams(Object.values(formValues));
</script>
<template>
  <div class="form">
    <VInputNumber
      v-model="H_AGL_Flight"
      class="form__item-number"
      :label-text="formValues.H_AGL_Flight.label"
    />
    <VInputNumber
      v-model="LineSpacing"
      class="form__item-number"
      :min="1"
      :label-text="formValues.LineSpacing.label"
    />
    <VInputNumber
      v-model="L_OvershootLine_Zone"
      class="form__item-number"
      :min="10"
      :label-text="formValues.L_OvershootLine_Zone.label"
    />
    <VInputNumber
      v-model="LevelingDistance"
      class="form__item-number"
      :min="10"
      :label-text="formValues.LevelingDistance.label"
    />
    <div class="form__item-checkbox">
      <VCheckBox v-model="ConstantAltitude" :label="formValues.ConstantAltitude.label" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form {
  $this: &;
  &__item-number {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    :deep(.input-num) {
      margin-top: 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  &__item-checkbox {
    height: 25px;
    margin-top: 12px;
  }
}
</style>