import { v4 as uuid } from 'uuid';

// State object
function initialState() {
  return {
    isThreatByCoord: false,
    editingThreat: null,
    currentThreatCoordinates: null,
    currentDangerSectorCoordinates: null,
    threatUnits: [],
    threatUnitZones: [],
    selectedThreat: null,
    loading: false,
    threats: [],
    threatUnitZoneCurrentUav: [],
  };
}
const getters = {
  threatUnitsByCode(state) {
    return state.threatUnits.reduce((acc, item) => ({ ...acc, [item.code]: item }), {});
  },

  threatUnitZonesByCode(state) {
    return state.threatUnitZones.reduce((acc, item) => ({ ...acc, [item.unitCode]: item }), {});
  },

  threatUnitZoneByID(state) {
    return state.threatUnitZones.reduce((acc, item) => ({ ...acc, [item.ID]: item }), {});
  },
  threatUnitZoneCurrentUavByID(state) {
    return state.threatUnitZoneCurrentUav.reduce(
      (acc, item) => ({ ...acc, [item.threatID]: item }),
      {}
    );
  },

  threatUnitZonesMerged(state, getters) {
    if (!state.threatUnitZoneCurrentUav.length) {
      return Object.groupBy(state.threatUnitZones, ({ unitCode }) => unitCode);
    }

    const threatUnitZoneByID = getters.threatUnitZoneByID;
    const threatUnitZoneCurrentUavByID = getters.threatUnitZoneCurrentUavByID;

    const result = Object.entries(threatUnitZoneByID).reduce((acc, [key, value]) => {
      let newVal = value;
      if (threatUnitZoneCurrentUavByID[key]) {
        const currentUavThreat = threatUnitZoneCurrentUavByID[key];
        if (currentUavThreat.disabled) {
          return acc;
        }

        if (!currentUavThreat.inheritValues) {
          newVal = {
            ...newVal,
            baseMaxRange: value.maxRange,
            baseMinRange: value.minRange,
            inheritValues: currentUavThreat.inheritValues,
            maxRange: currentUavThreat.maxRange,
            minRange: currentUavThreat.minRange,
            attenuationKind: currentUavThreat.attenuationKind,
            attenuationParams: currentUavThreat.attenuationParams,
            actualID: currentUavThreat.ID,
          };
        }
      }
      return [...acc, newVal];
    }, []);

    return Object.groupBy(result, ({ unitCode }) => unitCode);
  },

  threatZones(state, getters) {
    const threatUnitsByCode = getters.threatUnitsByCode;
    return state.threats
      .map((threat) => {
        if (!threat.unitCode) {
          return null;
        }
        const threatUnit = threatUnitsByCode[threat.unitCode];
        const threatUnitZones = getters.threatUnitZonesMerged[threat.unitCode] || [];

        if (!threatUnit) {
          return {
            threatKind: 'OBJECT',
            iconDataUrl: 'map/icons/unknown.png',
            ...threat,
            unitCategory: 'UNKNOWN',
          };
        }

        return [
          ...threatUnitZones,
          {
            threatKind: 'OBJECT',
            unitCategory: threatUnit.categoryName,
            unitCode: threatUnit.code,
          },
        ].map((item) => {
          const {
            ID: zoneID,
            unitCategory,
            threatKind,
            threatCategory,
            caption,
            minRange,
            maxRange,
            threatWeight,
            attenuationKind,
            attenuationParams,
            minHeight,
            maxHeight,
          } = item;

          return {
            unitName: threatUnit?.name,
            zoneID,
            unitCategory,
            threatKind,
            threatCategory,
            caption,
            minRange,
            maxRange,
            threatWeight,
            attenuationKind,
            attenuationParams,
            minHeight,
            maxHeight,
            ...(threatKind === 'OBJECT' ? { iconDataUrl: threatUnit.iconDataUrl } : {}),
            ...threat,
          };
        });
      })
      .filter(Boolean)
      .flat();
  },

  getSelectedThreadData(state, getters) {
    const data = getters.threatZones;
    return data?.filter((e) => e.ID === state.selectedThreat);
  },

  threatsWithUnitInfo(state, getters) {
    const threatUnitsByCode = getters.threatUnitsByCode;
    return state.threats.map((item) => {
      const threatUnit = threatUnitsByCode[item.unitCode];
      return { ...item, unitCategoryName: threatUnit?.categoryName, unitName: threatUnit?.name };
    });
  },

  threatsGroupedByUnitCategory(state, getters) {
    const threatUnitsByCode = getters.threatUnitsByCode;
    let threats = [...state.threats];
    threats = threats.map((item) => {
      const threatUnit = threatUnitsByCode[item.unitCode];
      return { ...item, unitCategoryName: threatUnit?.categoryName || 'UNKNOWN' };
    });
    threats = Object.groupBy(threats, ({ unitCategoryName }) => unitCategoryName);

    return Object.entries(threats).reduce((acc, [category, items]) => {
      const newItems = items.map((item, index) => {
        const threatUnit = threatUnitsByCode[item.unitCode];
        const [lat, lng] = item.coordinates ?? [];
        return {
          ...item,
          name: `#${index + 1} ${threatUnit?.name || item.unitCode}: ${lat.toFixed(6)}°, ${lng.toFixed(6)}°`,
          unitName: threatUnit?.name || item.unitCode,
          object: true,
          missile: true,
          cannon: true,
          radars: true,
        };
      });
      return { ...acc, [category]: newItems };
    }, {});
  },
};

const actions = {
  processImportedThreats({ getters, commit }, data) {
    const threatUnitsByCode = getters.threatUnitsByCode;
    const features = data.reduce((acc, item) => [...acc, ...(item.features || [])], []);
    const threats = features.reduce((acc, threat) => {
      const threatUnit = threatUnitsByCode[threat.properties.name];
      const [lat, lng] = threat.geometry.coordinates || [];
      if (!lat || !lng) return acc;

      const unitCode = threat?.properties?.name;
      return [
        ...acc,
        {
          ID: threat.uuid,
          coordinates: [lng, lat],
          unitCode: threatUnit?.code || unitCode,
        },
      ];
    }, []);

    commit('setThreats', threats);
  },
  async getThreatsByUav({ commit }, uavID) {
    if (!this.state.isThreatModule) return;
    if (!uavID) {
      return commit('setThreatUnitZoneCurrentUav', []);
    }
    try {
      commit('setLoading', true);
      const threatUnitZoneCurrentUav = await window.conn
        .Repository('threat_actual')
        .attrs([
          'ID',
          'uavTypeID',
          'disabled',
          'threatID',
          'inheritValues',
          'threatID.unitID',
          'threatID.unitID.code',
          'threatID.unitID.categoryID.name',
          'threatID.threatKind',
          'threatID.threatCategory',
          'threatID.caption',
          'minRange',
          'maxRange',
          'threatWeight',
          'attenuationKind',
          'attenuationParams',
        ])
        .where('uavTypeID', '=', uavID)
        .selectAsObject({
          'threatID.unitID': 'unitID',
          'threatID.unitID.code': 'unitCode',
          'threatID.unitID.categoryID.name': 'unitCategory',
          'threatID.threatKind': 'threatKind',
          'threatID.threatCategory': 'threatCategory',
          'threatID.caption': 'caption',
        });

      commit('setThreatUnitZoneCurrentUav', threatUnitZoneCurrentUav);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getThreats({ commit }) {
    if (!this.state.isThreatModule) return;
    try {
      commit('setLoading', true);
      const threatUnits = await window.conn
        .Repository('threat_unit')
        .attrs(['ID', 'code', 'name', 'categoryID', 'categoryID.name', 'iconDataUrl'])
        .selectAsObject({
          'categoryID.name': 'categoryName',
        });

      commit('setThreatUnits', threatUnits);

      const threats = await window.conn
        .Repository('threat_base')
        .attrs([
          'ID',
          'unitID',
          'unitID.categoryID.name',
          'unitID.code',
          'threatKind',
          'threatCategory',
          'caption',
          'minRange',
          'maxRange',
          'threatWeight',
          'attenuationKind',
          'attenuationParams',
          'minHeight',
          'maxHeight',
          'mi_owner',
          'mi_createDate',
          'mi_createUser',
          'mi_modifyDate',
          'mi_modifyUser',
        ])
        .selectAsObject({
          'unitID.categoryID.name': 'unitCategory',
          'unitID.code': 'unitCode',
        });

      commit('setThreatUnitZones', threats);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setLoading', false);
    }
  },

  setThreadByCoordinates({ commit }, { ID, threatUnitCode, coordinates }) {
    if (!this.state.isThreatModule) return;
    if (!threatUnitCode || !coordinates) return;
    if (ID) {
      const threat = {
        ID,
        unitCode: threatUnitCode,
        coordinates: [coordinates['lat'], coordinates['lon']],
      };
      commit('updateThreat', threat);
    } else {
      const newThreat = {
        unitCode: threatUnitCode,
        coordinates: [coordinates['lat'], coordinates['lon']],
        ID: uuid(),
      };
      commit('addThreat', newThreat);
    }
    commit('setThreatByCoords', false);
    commit('setCurrentThreatCoordinates', null);
  },
};

const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setThreatByCoords(state, payload) {
    if (!this.state.isThreatModule) return;
    state.isThreatByCoord = payload;
    state.editingThreat = null;
  },
  setEditThreatByCoordinates(state, threatID) {
    if (!this.state.isThreatModule) return;
    const editingThreat = state.threats.find(({ ID }) => ID == threatID);
    state.editingThreat = editingThreat;
    state.isThreatByCoord = true;
  },
  setCurrentThreatCoordinates(state, payload) {
    if (!this.state.isThreatModule) return;
    state.currentThreatCoordinates = payload;
  },
  setCurrentThreatDangerSectorCoordinates(state, payload) {
    if (!this.state.isThreatModule) return;
    state.currentDangerSectorCoordinates = payload;
  },
  setThreatUnits(state, payload) {
    if (!this.state.isThreatModule) return;
    state.threatUnits = [...payload, { code: 'UNKNOWN', categoryName: 'UNKNOWN' }];
  },
  setThreatUnitZones(state, payload) {
    if (!this.state.isThreatModule) return;
    state.threatUnitZones = payload;
  },
  setThreatUnitZoneCurrentUav(state, payload) {
    if (!this.state.isThreatModule) return;
    state.threatUnitZoneCurrentUav = payload;
  },
  setLoading(state, payload) {
    if (!this.state.isThreatModule) return;
    state.loading = payload;
  },
  setSelectedThreat(state, uuid) {
    state.selectedThreat = state.selectedThreat !== uuid ? uuid : null;
  },
  addThreat(state, payload) {
    if (!this.state.isThreatModule) return;
    state.threats = [...state.threats, payload];
  },
  updateThreat(state, payload) {
    if (!this.state.isThreatModule) return;
    state.threats = state.threats.map((item) => (item.ID === payload.ID ? payload : item));
  },
  setThreats(state, threats) {
    if (!this.state.isThreatModule) return;
    state.threats = threats || [];
  },
  removeThreat(state, threatID) {
    if (!this.state.isThreatModule) return;
    state.threats = state.threats.filter(({ ID }) => ID !== threatID);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
