import L from 'leaflet';

const initState = {
  // 'start' | 'end' | null
  currentGeoLocationActionType: null,
  isSettingCurrentGeoLocation: false,
  currentGeoLocation: null,
};
const state = {
  ...initState,
};
// Getter functions
const getters = {};

// Actions
const actions = {
  async useGeoLocationAsStartEnd({ commit, state }, isStart = false) {
    let coords = null;
    if (!!(state.currentGeoLocation || localStorage.getItem('currentGeoLocation'))) {
      coords = state.currentGeoLocation || JSON.parse(localStorage.getItem('currentGeoLocation'));
    }
    if (!coords && navigator.geolocation) {
      coords = await new Promise((res) =>
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            console.log(`Current geoLocation ~= Latitude: ${latitude}, Longitude: ${longitude}`);
            res([latitude, longitude]);
          },
          () => res(null)
        )
      );
    }
    if (!Array.isArray(coords) || coords.some((e) => !Number(e)))
      return commit('setCurrentGeoLocationByCoords', isStart ? 'start' : 'end');
    else if (isStart) {
      commit('params/setStart', coords, { root: true });
    } else {
      commit('prams/setFinish', coords, { root: true });
    }
    if (window.currentGeoLocationMarker) {
      window.currentGeoLocationMarker.remove();
    }
    commit('settingCurrentGeoLocationByCoordsSuccess', coords);
  },
  async setGeoLocation({ commit, state, dispatch }, coords) {
    const isStartSettingAction = state.currentGeoLocationActionType === 'start';
    const isEndSettingAction = state.currentGeoLocationActionType === 'end';
    if (window.currentGeoLocationMarker) {
      window.currentGeoLocationMarker.remove();
    }
    const maker = L.marker(coords, {
      icon: L.icon({
        iconSize: [25, 32],
        iconAnchor: [13, 28],
        iconUrl: `/map/icons/home.png`,
      }),
    });
    maker.addTo(window.map).bindPopup(
      L.popup({
        offset: [0, -25],
      }).setContent('I am here')
    );
    window.currentGeoLocationMarker = maker;

    window.map.panTo({ lat: coords[0], lng: coords[1] });
    commit('settingCurrentGeoLocationByCoordsSuccess', coords);
    if (isStartSettingAction || isEndSettingAction)
      dispatch('useGeoLocationAsStartEnd', isStartSettingAction);
  },
};
// Mutations
const mutations = {
  setCurrentGeoLocationByCoords(state, type = null) {
    state.isSettingCurrentGeoLocation = true;
    state.currentGeoLocationActionType = ['start', 'end'].includes(type) ? type : null;
  },
  stopSettingCurrentGeoLocationByCoords(state) {
    state.isSettingCurrentGeoLocation = false;
    state.currentGeoLocationActionType = null;
  },
  settingCurrentGeoLocationByCoordsSuccess(state, coords) {
    state.isSettingCurrentGeoLocation = false;
    state.currentGeoLocation = coords;

    localStorage.setItem('currentGeoLocation', JSON.stringify(coords));
    state.currentGeoLocationActionType = null;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
