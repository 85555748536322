<template>
  <VModal
    :is-visible="isVisible"
    :title="`Set danger sector radius [m]`"
    size="small"
    :title-icon="LocationIcon"
    close-text="Close"
    ok-text="Apply"
    @close="onCloseClick()"
    @success="onSuccessClick()"
  >
    <template #body>
      <div class="dangerSector">
        <VInputNumber v-model="sectorRadius" :min="1" />
      </div>
    </template>
  </VModal>
</template>

<script>
import { defineComponent } from 'vue';
import { VModal } from '@/components/ui';
import VInputNumber from '@/components/ui/VInputNumber.vue';
import { LocationIcon } from '@/assets/svgs';
export default defineComponent({
  components: { VInputNumber, VModal },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LocationIcon,
      sectorRadius: 1,
    };
  },
  methods: {
    onSuccessClick() {
      if (this.sectorRadius) {
        // this.$store.dispatch('targetPoints/saveTargetPoint', { pointType: this.sectorRadius });
      }
    },
    onCloseClick() {
      this.$store.commit('threat/setCurrentThreatDangerSectorCoordinates', null);
    },
  },
});
</script>

<style lang="scss" scoped>
.dangerSector {
  margin: 12px 0 16px 0;
  display: flex;
  justify-content: center;
}
</style>