import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { debounce } from 'lodash';

export default function useMissionHierarchy() {
  const sortBy = ref( { mi_modifyDate: 'desc'});
  const searchFor = ref('');

  const router = useRouter();

  const filterByName = ref('');
  const withFavorite = ref(false);
  const paginationOptions = ref({
    currentPage: 0,
    limit: 50,
  });

  const debouncedUpdatedSearchField = debounce((value) => (searchFor.value = value), 400);
  watch(filterByName, () => debouncedUpdatedSearchField(filterByName.value));
  const store = useStore();

  const isVisible = computed(() => store.state.missions.isModalVisible);
  const isEditable = computed(() => store.state.missions.isEditable);
  const isMissionsLoading = computed(() => store.state.missions.isLoading);
  const rowSelected = computed({
    get: () => store.state.missions.selectedMissionItemId,
    set: (value) => store.dispatch('missions/getMissionByID', value),
  });

  const currentParent = computed({
    get: () => store.state.missions.parentSelected,
    set: (id) => store.commit('missions/setParent', id),
  });
  const files = computed(() => {
    return store.state.missions.missions.dataItems;
  });

  const totalCount = computed(() => {
    return store.state.missions.missions.totalCounts;
  });

  function getMissionList() {
    if (!isVisible.value) return;
    rowSelected.value = null;
    return store.dispatch('missions/getMissions', {
      sortBy: sortBy.value,
      filterByName: filterByName.value,
      searchCondition: withFavorite.value ? { isFavorite: true } : {},
      currentParent: currentParent.value,
      paginationOptions: paginationOptions.value,
    });
  }

  watch(isVisible, getMissionList);

  watch(withFavorite, getMissionList);

  watch(paginationOptions, getMissionList);

  // ToDO: use such logic to implement full file system here
  watch(currentParent, getMissionList);
  watch(sortBy, getMissionList);
  watch(searchFor, getMissionList);

  function onDownload(...args) {
    if (!args.length) return;
    if (args.length > 1) {
      return store.dispatch('missions/buildAndDownloadMissionsMany', [...args]);
    } else {
      return store.dispatch('missions/buildAndDownloadMission', args[0]);
    }
  }

  function onDelete(...args) {
    if (!args.length) return;
    if (args.length === 1) return store.dispatch('missions/removeMissionByID', args[0]);
    else return store.dispatch('missions/removeMissionByIDs', [...args]);
  }

  function onCombineMissions(...args) {
    return store.dispatch('missions/setCombinerMissionIDs', [...args]);
  }

  function onCopy(...args) {
    return store.dispatch('missions/copyMissionsMany', [...args]);
  }

  function onFavoriteToggle(id) {
    return store.dispatch('missions/toggleFavorite', id);
  }

  async function onOpen(id) {
    router.push({ path: '/', query: { mission: id } });
    store.commit('missions/setCurrentMission', Number(id));
  }

  function onModalClose() {
    store.dispatch('missions/getMissionByID', null);
    store.commit('missions/closeModal');
  }

  function onMissionUpdateById({ id, payload }) {
    return store.dispatch('missions/updateMissionDetailsByID', { id, payload });
  }

  return {
    files,
    totalCount,
    isVisible,
    isEditable,
    isMissionsLoading,
    events: {
      onOpen,
      onCopy,
      onDelete,
      onDownload,
      onModalClose,
      onFavoriteToggle,
      onMissionUpdateById,
      onCombineMissions,
    },
    models: {
      sortBy,
      filterByName,
      withFavorite,
      paginationOptions,
      currentParent,
      rowSelected,
    },
  };
}
