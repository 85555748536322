import {
  GEO_JSON_IDS as IDS_CONST,
  READ_ONLY_GEO_JSON_IDs,
  DEFAULT_CUSTOM_POLYGONS_STYLES,
  VECTOR_STYLES,
} from '@/config/map-config';
import { v4, v4 as uuid } from 'uuid';
import { get, set } from 'lodash';
import buildImportHierarchy from '@/utils/build-import-hierarchy';
import { assignProperties, featureUpdateByUUID, updateFeatureByIDGroup } from '@/utils/mapUtils';

// State object
function initialState() {
  return {
    geoJson: null,
    cpp_geoJson: [],
    additionalGeometry: null,
    selectedPolygon: null,
    showPolygonID: null,
    globalMap: null,
    drawCustomShape: null,
    isCoordinatesModalVisible: false,
    isTakeOffEditCoodrsModalVisible: false,
    isLandingEditCoodrsModalVisible: false,
    customPointToEdit: null,
    showEventsLastIDs: [],
    // ToDO: Костиль
    hideMissionPoints: [],
    showPolygonIds: [IDS_CONST.path, IDS_CONST.default],
    importedInfoLayer: null,
    accGeoJson: null,
    isDrawModeEnabled: false,
  };
}
// Getter functions
const getters = {
  missionFeatures(state) {
    const { geoJson } = state;
    return (geoJson?.features || []).filter((x) => x.id !== 'Custom');
  },
  nextIndex(state) {
    const regularFeatures = getters['missionFeatures'](state);
    const maxIndex = regularFeatures.reduce((acc, i) => {
      const currentIndex = i.properties.originalPosition ?? 0;
      return currentIndex > acc ? currentIndex : acc;
    }, 0);
    return maxIndex + 1;
  },
  customFeatures(state) {
    const { geoJson } = state;
    return (geoJson?.features || []).filter((x) => x.id === 'Custom');
  },
  nextCustomIndex(state) {
    const customFeatures = getters['customFeatures'](state);
    const maxIndex = customFeatures.reduce((acc, i) => {
      const currentIndex = i.properties.originalPosition ?? 0;
      return currentIndex > acc ? currentIndex : acc;
    }, 0);
    return maxIndex + 1;
  },
  isCustomPointCoordinatesEditVisible(state) {
    return !!state.customPointToEdit;
  },
  getCustomPointCoordinates(state) {
    return get(
      state?.geoJson?.features?.find?.((e) => e.uuid === state.customPointToEdit),
      'geometry.coordinates',
      [null, null]
    );
  },
  getNameOfEditingCustomPoint(state) {
    return (
      !!state.customPointToEdit &&
      get(
        state.geoJson.features.find((e) => e.uuid === state.customPointToEdit),
        'properties.name',
        'Untitled'
      )
    );
  },
  isStartHidden(state) {
    return state.hideMissionPoints.find((e) => e === 'start');
  },
  isEndHidden(state) {
    return state.hideMissionPoints.find((e) => e === 'end');
  },

  getCalculationGeoJSON(state) {
    const features = (state.geoJson?.features || []).filter((e) => !e?.properties?.isExcluded);
    return {
      ...state.geoJson,
      features,
    };
  },

  getUserItems(state) {
    const features = (state.geoJson?.features || []).filter((e) => !!e?.properties?.isExcluded);
    return {
      ...(state.geoJson || {}),
      features,
    };
  },
  getMissionItems(state) {
    const features = (state.geoJson?.features || []).filter((e) => !e?.properties?.isExcluded);
    return {
      ...(state.geoJson || {}),
      features,
    };
  },
  geoJson(state) {
    return state.geoJson;
  },
  getPathGeoJSON: (state) => {
    return (state.cpp_geoJson?.features || []).find((e) => e.id === IDS_CONST.path);
  },
  cpp_geoJson(state) {
    return state.cpp_geoJson;
  },
  additionalGeometry(state) {
    return state.additionalGeometry;
  },
  currentVector(state) {
    if (!(state.selectedPolygon && state.additionalGeometry)) return;
    const { features } = state.additionalGeometry;
    return features.find((e) => e.source === state.selectedPolygon);
  },
  getMap() {
    return state.globalMap;
  },
  getRawData(state) {
    return {
      geoJson: JSON.stringify(state.geoJson),
      cpp_geoJson:
        Array.isArray(state.cpp_geoJson) && !!state.cpp_geoJson.length ? state.cpp_geoJson : null,
      additionalGeometry: JSON.stringify(state.additionalGeometry),
      importedGeoJsonData: state.importedInfoLayer,
    };
  },
  getSelectedFeature(state) {
    return (state?.geoJson?.features || []).find((e) => e.uuid === state.selectedPolygon);
  },
  getCppAttitude(state) {
    const cpp_path = (state.cpp_geoJson || []).find((e) => e.id === IDS_CONST.path);
    const path_coords = get(cpp_path, 'features.0.geometry.coordinates', []);
    return path_coords.length && !!state.selectedPolygon
      ? Math.max(...path_coords.map(([x, y, z]) => z))
      : 0;
  },

  getHierarchy(state) {
    return buildImportHierarchy(state);
  },
};
// Actions
const actions = {
  setAdditionalGeometryFromBack(
    { state, rootState },
    { geoJsonFromResponse = [], properties: missionProps = {}, withUserSettings }
  ) {
    const geoJson = state.geoJson;
    const scanModeOptionsList = rootState.params.scanModeOptionsList.reduce((acc, item) => ({
      ...acc,
      [item.label]: item.value,
    }));
    const additionalGeometry = {
      type: 'FeatureCollection',
      id: IDS_CONST.vector,
      style: VECTOR_STYLES,
      features: [],
    };
    const dataDict = geoJsonFromResponse.reduce(
      (acc, item) => ({ ...acc, [item.properties.Order]: item }),
      {}
    );

    const newFeatures = geoJson.features.map((feature, index) => {
      const data = dataDict[index];
      if (data) {
        const { properties, ...rest } = data;
        feature.properties.scanMode = scanModeOptionsList[properties.ScanMode];
        if (!withUserSettings) {
          feature.properties.lineSpacing = missionProps.zoneParamsDef.lineSpacing;
          feature.properties.flightAltitudeAGL = missionProps.zoneParamsDef.flightAltitudeAGL;
          feature.properties.numberOfPasses = missionProps.zoneParamsDef.numberOfPasses;
          feature.properties.constantAltitude = missionProps.zoneParamsDef.constantAltitude;
          feature.properties.overshootLength =
            feature.geometry?.type === 'Polygon'
              ? missionProps.zoneParamsDef.overshootLength
              : missionProps.zoneParamsDef.LevelingDistance;
        }

        additionalGeometry.features.push({
          ...rest,
          id: IDS_CONST.vector,
          style: VECTOR_STYLES,
          source: feature?.uuid,
        });
      }
      return feature;
    });
    state.additionalGeometry = additionalGeometry;
    state.geoJson = { ...geoJson, features: newFeatures };
  },
};
// Mutations
const mutations = {
  setIsDrawModeEnabled(state, value) {
    state.isDrawModeEnabled = value;
  },
  setShowPolygonID(state, id) {
    state.showPolygonID = id;
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  toggleModalEditCustomPoint(state, uuid) {
    state.customPointToEdit = state.customPointToEdit === uuid ? null : uuid;
  },
  editCustomPointSuccess(state, newCoords) {
    const isValid = Array.isArray(newCoords) && newCoords.every((e) => Number(e));
    if (!isValid) alert('Invalid coordinates! changes reverted!');
    state.geoJson.features = state.geoJson.features.map((feature) =>
      feature.uuid === state.customPointToEdit
        ? { ...feature, geometry: { ...feature.geometry, coordinates: newCoords } }
        : feature
    );
    state.customPointToEdit = null;
  },
  updateCppItemName(state, { id, name }) {
    const toUpdate = { properties: { name } };
    state.cpp_geoJson = state.cpp_geoJson.map((e) => ({
      ...e,
      features: e.features.map(updateFeatureByIDGroup(id, toUpdate)),
    }));
  },
  updateSelectedGeoJsonItemProperties(state, { key, value }) {
    state.geoJson.features = state.geoJson.features.map((e) =>
      e.uuid === state.selectedPolygon
        ? {
            ...e,
            properties: {
              ...(e.properties || {}),
              [key]: value,
            },
          }
        : e
    );
  },
  updateMissionFeaturesProperties(state, { key, value, type }) {
    if (!state.geoJson?.features) return;
    state.geoJson.features = state.geoJson.features.map((e) => {
      if (type && type !== e?.geometry?.type) return e;
      return {
        ...e,
        properties: {
          ...(e.properties || {}),
          [key]: value,
        },
      };
    });
  },
  hideMissionPoint(state, { type, ...other }) {
    switch (type) {
      case 'end':
      case 'start':
        state.hideMissionPoints = state.hideMissionPoints.includes(type)
          ? state.hideMissionPoints.filter((e) => e !== type)
          : state.hideMissionPoints.concat([type]);
    }
  },
  updateGeoJsonFeatureByUUID(state, { uuid, ...data }) {
    if (!uuid || !state.geoJson) return;
    state.geoJson.features = state.geoJson?.features?.map(featureUpdateByUUID(uuid, data));
  },
  saveCustomShape(state, geoJson) {
    if (!geoJson) return;

    state.geoJson = {
      ...(geoJson || {
        type: 'FeatureCollection',
      }),
      features: (state?.geoJson?.features || []).concat(
        geoJson?.features
          ?.filter((e) => !e.uuid && e.id === IDS_CONST.custom)
          ?.map((e) => ({
            ...e,
            uuid: uuid(),
            properties: {
              isExcluded: true,
              originalPosition: getters.nextCustomIndex(state),
            },
          }))
      ),
    };
    state.drawCustomShape = null;
  },
  drawCustomShape(state, payload) {
    state.drawCustomShape = payload;
  },
  setDefaultGeoData(state, defaultGeoJSON) {
    state.geoJson = defaultGeoJSON;
  },
  setCppGeoData(state, cpp) {
    state.cpp_geoJson = cpp;
  },
  initMapStore(state, map) {
    if (!map) {
      state.geoJson = null;
      state.selectedPolygon = null;
      state.additionalGeometry = null;
      state.cpp_geoJson = [];
      state.accGeoJson = null
    } else {
      const {
        geoJson,
        cpp_geoJson,
        additionalGeometry,
        importedGeoJsonData: importedInfoLayer,
        accGeoJson
      } = map;
      state.cpp_geoJson = (Array.isArray(cpp_geoJson) && cpp_geoJson) || [];
      try {
        state.geoJson = JSON.parse(geoJson);
      } catch (e) {
        console.log('Unexpected JSON format!');
      }
      try {
        state.additionalGeometry =
          typeof additionalGeometry === 'string'
            ? JSON.parse(additionalGeometry)
            : additionalGeometry;
      } catch (e) {
        console.log('Unexpected JSON format!');
      }
      try {
        state.importedInfoLayer =
          typeof importedInfoLayer === 'string' ? JSON.parse(importedInfoLayer) : importedInfoLayer;
      } catch (e) {
        console.log('Unexpected JSON format!');
      }

      state.accGeoJson = accGeoJson
    }
  },
  removeFeatureByUUID(state, uuid) {
    state.geoJson.features = state.geoJson.features.filter((e) => e.uuid !== uuid);
  },
  showItem(state, uuid) {
    if (!state.geoJson) return;
    state.geoJson.features = state.geoJson.features.map((e) => ({
      ...e,
      properties: {
        ...(e.properties || {}),
        isSelected: e.uuid === uuid ? !e?.properties.isSelected : false,
      },
    }));
  },
  setPolygonsOrder(state, featuresToSet) {
    const customItems = state.geoJson.features.filter((e) => !!e?.properties.isExcluded);
    state.geoJson.features = featuresToSet.concat(customItems);
  },
  setGeoJson(state, data) {
    const features = (data?.features || [])
      .filter((e) => e.geometry.type !== 'Point' || !!e?.properties?.isTargetPoint)
      .map((e, i) => ({
        ...e,
        properties: {
          ...(e.properties || {}),
          originalPosition: e?.properties?.originalPosition || getters.nextIndex(state),
        },
      }));

    state.geoJson = data
      ? {
          ...data,
          features: features.concat(
            (state?.geoJson?.features || []).filter((e) => !!e?.properties?.isExcluded)
          ),
          style: DEFAULT_CUSTOM_POLYGONS_STYLES,
        }
      : data;
  },
  modifyFeatures(state, features) {
    features = features.filter((item) => {
      if (item?.geometry?.type === 'Polygon') {
        return item?.geometry?.coordinates?.[0]?.length;
      }
      if (item?.geometry?.type === 'LineString') {
        return item?.geometry?.coordinates?.length;
      }
      return true;
    });
    state.geoJson = {
      ...state.geoJson,
      features,
    };
  },
  setSelectedFeatureUUID(state, data) {
    state.selectedPolygon = data;
  },

  stopVectorDrawing(state) {
    state.selectedPolygon = null;
  },
  toggleSetSelectedPolygon(state, uuid) {
    state.selectedPolygon = state.selectedPolygon !== uuid ? uuid : null;
  },
  startVectorDrawing(state, uuid) {
    state.selectedPolygon = uuid;
    state.isVectorDrawind = true;
  },
  clearVectorIfExit(state, sourceId) {
    const newAdd = Object.assign({}, state.additionalGeometry);
    newAdd.features = (state?.additionalGeometry?.features || []).filter(
      (e) => e.source !== state.selectedPolygon
    );
    state.additionalGeometry = newAdd;
  },
  toggleShowPolygonGroup(state, group_id) {
    state.showPolygonIds = state.showPolygonIds.includes(group_id)
      ? state.showPolygonIds.filter((e) => e !== group_id)
      : state.showPolygonIds.concat(group_id);
  },
  setVector(state, data) {
    if (!state.selectedPolygon) return;
    const featuresToSet = (state?.additionalGeometry?.features || []).filter(
      (e) => e.source !== state.selectedPolygon
    );
    const basicFormat = {
      type: 'FeatureCollection',
      id: IDS_CONST.vector,
      style: VECTOR_STYLES,
      features: [],
    };
    state.additionalGeometry = {
      ...(state.additionalGeometry || basicFormat),
      features: featuresToSet.concat(
        data.features.map((e) => ({
          ...e,
          id: IDS_CONST.vector,
          style: basicFormat.style,
          source: state.selectedPolygon,
        }))
      ),
    };
  },
  setCoordinatesModalVisible(state, isVisible) {
    state.isCoordinatesModalVisible = isVisible;
  },
  setTakeOffEditCoordinatesModalVisible(state, isVisible) {
    state.isTakeOffEditCoodrsModalVisible = isVisible;
  },
  setLandingEditCoordinatesModalVisible(state, isVisible) {
    state.isLandingEditCoodrsModalVisible = isVisible;
  },
  setImportedLayer(state, { features, fileName, ...layerOtherData }) {
    const groupNameToDisplay = `${fileName}`;
    const groupUUID = v4();

    const newFeatures = (state.importedInfoLayer?.features || []).concat(
      features.map((new_features) => ({
        ...new_features,
        properties: {
          ...(new_features.properties || {}),
          group: {
            uuid: groupUUID,
            title: groupNameToDisplay,
          },
        },
      }))
    );
    state.importedInfoLayer = layerOtherData;
    state.importedInfoLayer.features = newFeatures.map(({ uuid, properties, ...feature }) => ({
      ...feature,
      uuid: uuid || v4(),
      properties: properties || {},
    }));
  },
  toggleHideGroup(state, { group_uuid, value }) {
    state.importedInfoLayer.features = state.importedInfoLayer.features.map((feature) =>
      get(feature, 'properties.group.uuid', null) === group_uuid
        ? {
            ...feature,
            properties: {
              ...(feature.properties || {}),
              isHidden: value,
            },
          }
        : feature
    );
  },
  removeImportedByGroup(state, group_uuid) {
    state.importedInfoLayer.features = state.importedInfoLayer.features.filter(
      (e) => get(e, 'properties.group.uuid', null) !== group_uuid
    );
  },
  removeImportedItem(state, uuid) {
    state.importedInfoLayer.features = state.importedInfoLayer.features.filter(
      (e) => e.uuid !== uuid
    );
  },
  toggleShowImportedItem(state, uuid) {
    state.importedInfoLayer.features = state.importedInfoLayer.features.map((e) =>
      e.uuid === uuid
        ? {
            ...e,
            properties: {
              ...(e?.properties || {}),
              isShow: !e?.properties?.isShow,
            },
          }
        : {
            ...e,
            properties: {
              ...(e?.properties || {}),
              isShow: false,
            },
          }
    );

    // const isShow = state.importedInfoLayer.features[targetIndex]?.properties?.isShow;
    // const importedInfoLayer = _.cloneDeep(state.importedInfoLayer);
    // importedInfoLayer.features[targetIndex].properties =
    //     Object.assign({},importedInfoLayer.features[targetIndex]?.properties || {}, { isHidden: false, isShow: !isShow });
    // state.importedInfoLayer = importedInfoLayer;
  },
  changeNameOfImportedItem(state, { uuid, name }) {
    state.importedInfoLayer.features = state.importedInfoLayer.features.map((item) =>
      item.uuid === uuid
        ? {
            ...item,
            properties: {
              ...item.properties,
              name,
            },
          }
        : item
    );
  },
  toggleHideImportedItem(state, uuid) {
    state.importedInfoLayer.features = state.importedInfoLayer.features.map((item) =>
      item.uuid === uuid
        ? {
            ...item,
            properties: {
              ...item.properties,
              isHidden: !item.properties.isHidden,
            },
          }
        : item
    );
  },

  addCombinedGeoItems(state, features) {
    if (!features.length) return;

    console.log(state.geoJson?.features || []);
    const positionsExcludedItems = (state.geoJson?.features || [])
      .filter((item) => item?.properties?.isExcluded)
      ?.map((item) => Number(item?.properties?.originalPosition) || 0);
    const positionMissionItems = (state.geoJson?.features || [])
      .filter((item) => !item?.properties?.isExcluded)
      ?.map((item) => Number(item?.properties?.originalPosition) || 0);
    let maxExcludedPositions = positionsExcludedItems?.length
      ? Math.max(...positionsExcludedItems)
      : 0;
    let maxMissionPositions = positionMissionItems?.length ? Math.max(...positionMissionItems) : 0;

    const recomputedFeatures = features?.map((item) => {
      const properties = Object.assign({}, item?.properties || {}, { isHidden: false });
      if (properties?.isExcluded) {
        maxExcludedPositions++;
        Object.assign(properties, { originalPosition: maxExcludedPositions });
      } else {
        maxMissionPositions++;
        Object.assign(properties, { originalPosition: maxMissionPositions });
      }
      return Object.assign(item, { uuid: v4() }, { properties });
    });
    if (state.geoJson) {
      state.geoJson.features = state.geoJson.features.concat(recomputedFeatures || []);
    } else {
      state.geoJson = {
        features: recomputedFeatures,
        type: 'FeaturesCollection',
        id: IDS_CONST.default,
      };
    }
  },
  setLastShownIDs(state, IDs) {
    state.showEventsLastIDs = IDs || [];
  },
  setAccGeoJson(state, payload) {
    state.accGeoJson = payload;
  },

  saveTargetPoint(state, payload) {
    state.geoJson = {
      ...(state.geoJson || {
        type: 'FeatureCollection',
      }),
      features: (state.geoJson?.features || []).concat(payload)
    }
  }
};
export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
