<template>
  <VModal
    :is-visible="isVisible"
    size="xl"
    close-text="Cancel"
    ok-text="Import"
    :title="
      dataGroupedByMissions?.length > 1 ? 'Import missions components' : 'Import mission component'
    "
    @close="onClose()"
    @success="handleImportAndReset()"
  >
    <template #body>
      <div class="import-combiner">
        <div v-if="!(groupedData || []).length" class="empty-placeholder">
          No one item detected in selected mission(s).
        </div>
        <div
          v-for="(missionItem, index) in dataGroupedByMissions"
          :key="missionItem.mission_id"
          :class="{
            'import-combiner__mission-sections': dataGroupedByMissions?.length > 1,
            'import-combiner__mission-section': dataGroupedByMissions?.length === 1,
          }"
        >
          <div class="import-combiner__mission-section__preview">
            <span class="import-combiner__mission-section__preview__title"
              >{{ index + 1 }}. "{{ missionItem.mission_name }}"</span
            >
            <img
              class="import-combiner__mission-section__preview"
              :src="missionItem.previewUrl || NotImage"
              :alt="missionItem.mission_id"
            />
          </div>
          <div class="import-combiner__mission-section__items">
            <VAccordion :data="missionItem.data" />
          </div>
        </div>
      </div>
    </template>
  </VModal>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, watch } from 'vue';
import useMissionBundle from '@/hooks/mission-manager/useMissionBundle';
import VAccordion from '@/components/ui/VAccordion';
import { VModal } from '@/components/ui';
import { NotImage } from '@/assets/svgs';

const store = useStore();
const missionIDs = computed(() => store.state.missions.combinerMissionIDs);
const isVisible = computed(() => store.getters['missions/combinerVisible']);
const onClose = () => store.commit('missions/setCombinerMissionsSuccess', []);
const { groupedData, onImportSelected } = useMissionBundle(missionIDs);

const dataGroupedByMissions = computed(() =>
  groupedData.value.reduce((acc, item) => {
    const indexOfMissionRecord = acc.findIndex((e) => e.mission_id === item.mission_id);
    if (indexOfMissionRecord !== -1) {
      acc[indexOfMissionRecord].data.push(item);
      return acc;
    } else
      return [
        ...acc,
        {
          mission_id: item.mission_id,
          mission_name: item.mission_name,
          previewUrl: item.previewUrl,
          data: [item],
        },
      ];
  }, [])
);

const handleImportAndReset = () => {
  const isSuccess = onImportSelected();
  if (isSuccess) onClose();
};
</script>

<style lang="scss" scoped>
.import-combiner {
  padding: 20px 25px;
  overflow-y: auto;
  max-height: 65vh;
  .empty-placeholder {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
    color: var(--black-400);
  }
  &__mission-sections {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--black-700);
    min-height: 256px;
    justify-content: space-around;
    align-items: stretch;

    $this: &;
    &__preview {
      height: 200px;
      width: 300px;
      $this: &;
      &__title {
        width: 100%;
        display: inline-block;
        text-align: center;
        position: relative;
        top: -5px;
        color: var(--black-400);
        font-size: 15px;
        white-space: nowrap;
      }
    }
    &__items {
      min-width: 400px;
    }
  }
  &__mission-section {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    min-height: 256px;
    justify-content: space-around;
    align-items: stretch;

    $this: &;
    &__preview {
      height: 200px;
      width: 300px;
      $this: &;
      &__title {
        width: 100%;
        display: inline-block;
        text-align: center;
        position: relative;
        top: -5px;
        color: var(--black-400);
        font-size: 15px;
        white-space: nowrap;
      }
    }
    &__items {
      min-width: 400px;
    }
  }
}
</style>