<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { VModal } from '@/components/ui';

const store = useStore();

const errorsList = computed(() => store.state.debug.extraErrors);
const isVisible = computed(() => !!errorsList.value.length);

const onClose = () => store.commit('debug/clearExtraErrors');
</script>

<template>
  <VModal
    :is-visible="isVisible"
    :title="`Invalid data!`"
    size="mlarge"
    :title-icon="LocationIcon"
    :visible-close-btn="false"
    ok-text="Ok"
    @close="onClose()"
    @success="onClose()"
  >
    <template #body>
      <div class="extra-error-block">
        <div class="extra-error-block__title">
          {{ `Impossible to calculate, please fix following errors: ` }}
        </div>
        <div v-for="(error, index) in errorsList" :key="error" class="extra-error-block__item">
          {{ `${index + 1}.${error}` }}
        </div>
      </div>
    </template>
  </VModal>
</template>

<style lang="scss" scoped>
.extra-error-block {
  width: 100%;
  color: var(--red-400);
  margin: 20px 0;
  &__title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  &__item {
    padding: 4px 15px;
    margin-top: 10px;
  }
}
</style>